import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/xmark.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { COLOR_GRAY_600 } from 'src/constants';
import { ModalType } from 'src/types';

import { IModal } from './type';

const Modal = ({
  show,
  type,
  title,
  content,
  closeIcon,
  cancelLabel,
  actionLabel,
  actionHandler,
  onCancel,
}: IModal) => {
  const props: any = {
    open: show,
    title: (
      <div className="confirm-modal__header">
        <h3
          className={classNames('heading heading--xs', {
            'modal--error': type === ModalType.Error,
          })}
        >
          {title}
        </h3>

        {closeIcon && (
          <>
            <CloseIcon
              width={24}
              height={24}
              fill={COLOR_GRAY_600}
              className="cursor--pointer"
              onClick={onCancel}
            />
          </>
        )}
      </div>
    ),
    closeIcon: false,
    className: 'modal',
    footer: null,
    width: 479,
  };

  if (!!cancelLabel || !!actionLabel) {
    props.footer = (
      <div className="confirm-modal__footer">
        {actionLabel && (
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.Gray}
            title={actionLabel}
            onClickHandled={actionHandler}
          />
        )}
        {cancelLabel && (
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.Red}
            title={cancelLabel}
            onClickHandled={onCancel}
          />
        )}
      </div>
    );
  }

  return (
    <AntdModal {...props}>
      <p className="paragraph paragraph--lg">{content}</p>
    </AntdModal>
  );
};

export default Modal;
