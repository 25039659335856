import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';
import { apiStrings, httpClient } from 'src/services';
import { ErrorCriteria } from 'src/types';
import { buildQueryString, downloadFile } from 'src/utils/helper';
import { useToast } from '../ui';

const useViewFile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { toastError } = useToast();

  const { mutate: viewFile } = useMutation<any, AxiosError<ErrorCriteria>, any>(
    {
      mutationFn: async (variables: { fileId: string; orderId: string }) => {
        dispatch(showLoading());

        const queryString = buildQueryString(variables);

        try {
          const response = await httpClient.get(
            apiStrings.file.viewFile + '?' + queryString
          );

          return response.data.data;
        } catch (error) {
          throw error;
        }
      },
      onSuccess: async response => {
        await downloadFile(response.url || '', 'google');
        dispatch(hideLoading());
      },
      onError: async err => {
        dispatch(hideLoading());
        toastError({
          message: err.response?.data?.message || t('error.systemError'),
        });
      },
    }
  );

  return { viewFile };
};

export default useViewFile;
