import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setTokenInfo } from 'src/redux/slices/authSlice';
import PermissionDenied from 'src/pages/PermissionDenied';
import useAuth from 'src/hooks/auth/useAuth';

type Props = {
  children: JSX.Element;
  role: string;
};

const RoleRoute = ({ children, role }: Props) => {
  const dispatch = useDispatch();
  const { getTokenInfo } = useAuth();
  const tokenInfo = getTokenInfo();

  useEffect(() => {
    if (!!tokenInfo) {
      dispatch(setTokenInfo(tokenInfo));
      if (window.oscWidget && window.oscWidget.user) {
        window.oscWidget.user.setName(tokenInfo.username);
        window.oscWidget.user.setPhone(tokenInfo.storeId);
        window.oscWidget.user.setEmail('john.doe@gmail.com');
        window.oscWidget.user.setAddress('Los Angeles');
        window.oscWidget.user.setProperties({
          plan: 'Estate',
          status: 'Active',
        });
      }
    }
  }, [tokenInfo]);

  return useMemo(() => {
    if (tokenInfo?.permissions.includes(role)) {
      return children;
    }
    return <PermissionDenied />;
  }, [tokenInfo]);
};

export default RoleRoute;
