import { Input, QRCode, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as QrCode } from 'src/assets/images/icons/qr-code.svg';
import { ReactComponent as Link } from 'src/assets/images/icons/link.svg';
import { ReactComponent as CircleSuccess } from 'src/assets/images/icons/circle-success.svg';
import { ReactComponent as Mobile } from 'src/assets/images/icons/mobile.svg';
import { ReactComponent as Copy } from 'src/assets/images/icons/copy.svg';
import { ReactComponent as CircleSuccessGray } from 'src/assets/images/icons/circle-success-gray.svg';

import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import withIcon from 'src/hocs/withIcon';
import { useCountDown } from 'src/hooks/common';
import routeStrings from 'src/routes/routeStrings';
import { diffInSeconds, numberFormatter } from 'src/utils/helper';

import { CreateOrderResponse, SmsForm } from 'src/types';
import { COLOR_PRIMARY_600 } from 'src/constants';
import { useSendSms } from 'src/hooks';
import { useEffect } from 'react';

const QrCodeIcon = withIcon(QrCode, {
  width: 16,
  height: 16,
});

const LinkIcon = withIcon(Link, {
  width: 16,
  height: 16,
});

const CircleSuccessIcon = withIcon(CircleSuccess, {
  width: 100,
  height: 100,
  color: COLOR_PRIMARY_600,
});

const MobileIcon = withIcon(Mobile, {
  width: 16,
  height: 16,
});

const CopyIcon = withIcon(Copy, {
  width: 16,
  height: 16,
});

const CircleSuccessGrayIcon = withIcon(CircleSuccessGray, {
  width: 16,
  height: 16,
});

interface PaymentProps {
  orderResponse: CreateOrderResponse;
  backToCreateOrder: () => void;
}

const Payment = ({ orderResponse, backToCreateOrder }: PaymentProps) => {
  const { t } = useTranslation();
  const numberCountDown = diffInSeconds(
    dayjs().toString(),
    orderResponse.expireTime
  );
  const { days, hours, minutes, seconds } = useCountDown(numberCountDown);
  const navigate = useNavigate();
  const { sendSms: sendSmsAction, isSmsSending } = useSendSms();

  const createNewOrder = () => {
    backToCreateOrder?.();
  };

  const orderDetail = () => {
    navigate(
      routeStrings.order + `/${encodeURIComponent(orderResponse.referenceId)}`
    );
  };

  const sendSms = () => {
    sendSmsAction({
      id: encodeURIComponent(orderResponse.referenceId),
    } as unknown as SmsForm);
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(orderResponse.paymentUrl);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      orderDetail();
    }, numberCountDown * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const tabs = [
    {
      icon: <QrCodeIcon />,
      label: t('orders:orderPayment.qrCode'),
      children: (
        <>
          <div className="d-flex items-center justìy-center flex-col px--24 py--12 gap--12 mb--12">
            <p className="w-300 pb--12 description description--sm text-center">
              {t('orders:orderPayment.qrCodeDescription')}
            </p>
            <QRCode
              value={orderResponse?.qrData || 'Nodata'}
              icon="/logo-short.svg"
            />
            <div className="d-flex items-center justify-between flex-row w-200">
              <p className="description description--sm text-gray-700">
                {t('orders:orderPayment.amount')}
              </p>
              <p className="label label--sm">
                {numberFormatter(orderResponse?.totalAmount)}
                {t('common:currency.vnd')}
              </p>
            </div>
            <p className="description description--sm text-gray-700">
              {t('orders:orderPayment.qrCodeFooter')}
            </p>
          </div>
        </>
      ),
      key: '1',
    },
    {
      icon: <LinkIcon />,
      label: t('orders:orderPayment.urlPayment'),
      children: (
        <div className="d-flex items-start justify-start flex-col px--24 py--12 gap--12 mb--12">
          <p className="description description--sm">
            {t('orders:orderPayment.urlPaymentDescription')}
          </p>
          <Input value={orderResponse.paymentUrl} />
          <div className="d-flex flex-row justify-between items-center gap--12 w--100">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('orders:orderPayment.copyUrl')}
              onClickHandled={copyUrl}
              icon={<CopyIcon />}
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:orderPayment.sendSms')}
              onClickHandled={sendSms}
              subIcon={!orderResponse.sendSms && <MobileIcon />}
              icon={orderResponse.sendSms && <CircleSuccessGrayIcon />}
              disabled={orderResponse.sendSms || isSmsSending}
              block
            />
          </div>
          {orderResponse.sendSms && (
            <p className="description description--sm text-gray-700">
              {t('orders:orderPayment.urlPaymentNote')}
            </p>
          )}
          <p className="description description--sm text-gray-700">
            {t('orders:orderPayment.urlPaymentFooter')}
          </p>
        </div>
      ),
      key: '2',
    },
  ];

  return (
    <div className="d-flex flex-row align-items-center justify-center">
      <div className="background-gray-100 w--500 br--8">
        <div className="d-flex flex-col justify-center items-center py--24 bg-image">
          <CircleSuccessIcon />
          <h6 className="label label--lg mt--24">
            {t('orders:createOrderPage.success')}
          </h6>
        </div>
        <hr />
        <Tabs
          className="tabs-full-width"
          tabBarStyle={{ width: '100%' }}
          defaultActiveKey="1"
          items={tabs.map(tab => {
            return {
              key: tab.key,
              label: tab.label,
              children: tab.children,
              icon: tab.icon,
            };
          })}
        />
        <div className="background-dust-red-100 d-flex flex-row items-center px--24 py--12 justify-between">
          <p className="text-gray-700">
            {t('orders:orderPayment.paymentTerm')}
          </p>
          <div className="d-flex flex-row items-center text-dust-red-600">
            <div className="d-flex items-center gap--4 label label--md">
              <span className="order-details__countdown">{days}</span>
              <span>{' : '}</span>
              <span className="order-details__countdown">{hours}</span>
              <span>{' : '}</span>
              <span className="order-details__countdown">{minutes}</span>
              <span>{' : '}</span>
              <span className="order-details__countdown">{seconds}</span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row px--24 py--12 justify-between items-center gap--12">
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.Gray}
            title={t('orders:orderPayment.createNewOrder')}
            onClickHandled={createNewOrder}
            block
          />
          <Button
            type={ButtonType.Default}
            colorType={ButtonColorType.Blue}
            title={t('orders:orderPayment.detailOrder')}
            onClickHandled={orderDetail}
            block
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
