import { ReactComponent as Clock } from 'src/assets/images/icons/clock.svg';
import { ReactComponent as CircleSuccess } from 'src/assets/images/icons/circle-success.svg';
import withIcon from 'src/hocs/withIcon';
import { COLOR_CALENDULA_GOLD_600, COLOR_PRIMARY_500 } from 'src/constants';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { useCountDown } from 'src/hooks/common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ClockIcon = withIcon(Clock, {
  width: 12,
  height: 12,
  color: COLOR_CALENDULA_GOLD_600,
});
const CircleSuccessIcon = withIcon(CircleSuccess, {
  width: 80,
  height: 80,
  color: COLOR_PRIMARY_500,
});

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { timeRemaining, minutes, seconds } = useCountDown(5);

  const redirectToSignIn = () => {
    navigate('/sign-in');
  };

  useEffect(() => {
    if (timeRemaining === 0) {
      redirectToSignIn();
    }
  }, [timeRemaining]);

  return (
    <div className="signin__container">
      <div className="d-flex justify-center items-center flex-col">
        <div className="mb--12">
          <CircleSuccessIcon />
        </div>
        <h3 className="heading heading--lg mb--12">
          {t('changePassword:titleSuccess')}
        </h3>
        <p className="paragraph paragraph--sm text-gray-700 mb--20">
          {t('changePassword:changePasswordSuccessDes')}
        </p>
        <Button
          type={ButtonType.Primary}
          colorType={ButtonColorType.Blue}
          title={t('signIn:signIn')}
          block
          size="large"
          className="label--md"
          onClickHandled={redirectToSignIn}
        />
        <div className="d-flex justify-center items-center mt--20 mb--28">
          <div className="mr--4">
            <ClockIcon />
          </div>
          <p className="paragraph paragraph--sm text-calendula-gold-600">
            {t('changePassword:countDown', { time: `${minutes}:${seconds}` })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
