import { DatePickerProps, TimePickerProps } from 'antd';
import dayjs from 'dayjs';
import { isArray, isNumber, isString, trim } from 'lodash';
import { TFunction } from 'i18next';

import { formatDateTimeToUtc } from 'src/utils/helper';

export const validateRequired = (
  t: TFunction,
  fieldName: string,
  value: any
) => {
  if (
    (isString(value) && !trim(value)) ||
    (isNumber(value) && !value) ||
    (isArray(value) && value.length < 1)
  ) {
    return t('validation.required', { fieldName });
  }
  return '';
};

export const validateDatePeriod = (
  t: TFunction,
  startDate: DatePickerProps['value'],
  endDate: DatePickerProps['value'],
  starTime?: TimePickerProps['value'],
  endTime?: TimePickerProps['value']
) => {
  const formattedStartDate = formatDateTimeToUtc(startDate, starTime);
  const formattedEndDate = formatDateTimeToUtc(endDate, endTime);

  if (
    dayjs(formattedEndDate).valueOf() - dayjs(formattedStartDate).valueOf() <=
    0
  ) {
    return t('validation.endDateGreaterThanStartDate');
  }

  return '';
};

export const validatePassword = (
  t: TFunction,
  fieldName: string,
  value: any
) => {
  const required = validateRequired(t, fieldName, value);
  if (required !== '') {
    return t('validation.required', { fieldName });
  }
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
  //must Uppercase, must lowercase, at least 8 charactor, must symbol

  if (!passwordRegex.test(value)) {
    return t('validation.password', { fieldName });
  }
  return '';
};

export const validateConfirmPassword = (
  t: TFunction,
  fieldName: string,
  password: any,
  confirmedPassword: any
) => {
  const required = validateRequired(t, fieldName, confirmedPassword);
  if (required !== '') {
    return required;
  }
  if (password !== confirmedPassword) {
    return t('validation.passwordNotMatch', { fieldName });
  }
  return '';
};

export const validatePhoneNumber = (
  t: TFunction,
  fieldName: string,
  value: any
) => {
  const required = validateRequired(t, fieldName, value);
  if (required !== '') {
    return t('validation.required', { fieldName });
  }
  const regexPhoneNumber =
    /^(\+[1-9]{1}[0-9]{0,2}[ -])?([0]{1})?[1-9]{1}[0-9]{2}[0-9]{1}[0-9]{2}[0-9]{3,4}$/;

  if (!regexPhoneNumber.test(value)) {
    return t('validation.phone', { fieldName });
  }
  return '';
};

export const validateUrl = (t: TFunction, fieldName: string, value: any) => {
  if (value === '') {
    return '';
  }
  const regexUrl =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

  if (!regexUrl.test(value)) {
    return t('validation.url', { fieldName });
  }
  return '';
};
