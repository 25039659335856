import { useState, useEffect } from 'react';

const useCountdown = (initialTime: number) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(prevTime => prevTime - 1);
    }, 1000);
    if (timeRemaining === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timeRemaining]);

  const days = Math.floor(timeRemaining / (24 * 60 * 60)) || 0;
  const hours = Math.floor((timeRemaining / (60 * 60)) % 24) || 0;
  const minutes = Math.floor((timeRemaining / 60) % 60) || 0;
  const seconds = timeRemaining % 60 || 0;

  return {
    timeRemaining,
    days: days < 10 ? '0' + days : days,
    hours: hours < 10 ? '0' + hours : hours,
    minutes: minutes < 10 ? '0' + minutes : minutes,
    seconds: seconds < 10 ? '0' + seconds : seconds,
  };
};

export default useCountdown;
