import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';
import { Field, useField } from 'formik';
import { ReactComponent as Plus } from 'src/assets/images/icons/plus-black.svg';
import { ReactComponent as Minus } from 'src/assets/images/icons/minus-black.svg';
import { COLOR_GRAY_900 } from 'src/constants';
import ValidatedError from 'src/components/ValidatedError';

import { QuantityInputProps } from 'src/components/Input';
import Button, { ButtonColorType, ButtonType } from '../Button';
import withIcon from 'src/hocs/withIcon';

const PlusIcon = withIcon(Plus, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});
const MinusIcon = withIcon(Minus, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});

const QuantityInput = ({
  name,
  placeholder,
  value,
  onValueChanged,
  validate,
  formatter,
  max,
  min = 1,
  addonAfter,
  addonBefore,
  prefix,
  suffix,
  label = '',
  required = false,
  className = '',
  rootClassName = '',
  increase,
  reduce,
}: QuantityInputProps) => {
  const [field, meta] = useField(name);

  return (
    <div
      className={classNames({
        'd-flex flex-col': true,
        [rootClassName]: !!rootClassName,
      })}
    >
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <div className={!!addonAfter || !!addonBefore ? 'input' : ''}>
        <div className="input__addon">{addonBefore && addonBefore}</div>

        <div>
          <div className="d-flex justify-start">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              icon={<MinusIcon />}
              rootClassName="mr--8 quantity-button"
              disabled={value <= min}
              onClickHandled={reduce}
            />
            <AntdInputNumber
              {...field}
              placeholder={placeholder}
              className={classNames({
                [className]: !!className,
                'border--error': meta.touched && meta.error,
              })}
              prefix={prefix && prefix}
              suffix={suffix && suffix}
              value={value}
              formatter={formatter && formatter}
              onChange={onValueChanged}
              min={min}
              max={max}
            />
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              icon={<PlusIcon />}
              rootClassName="ml--8 quantity-button"
              disabled={!!max && value >= max}
              onClickHandled={increase}
            />
          </div>

          {validate && (
            <>
              <Field
                {...field}
                validate={validate}
                hidden
                value={value}
                onChange={onValueChanged}
              />
              <ValidatedError touched={meta.touched} error={meta.error} />
            </>
          )}
        </div>

        <div className="input__addon">{addonAfter && addonAfter}</div>
      </div>
    </div>
  );
};

export default QuantityInput;
