import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  hideLoading,
  setTextLoading,
  showLoading,
} from 'src/redux/slices/uiSlice';
import { apiStrings, httpClient } from 'src/services';
import { ErrorCriteria } from 'src/types';
import { useToast } from '../ui';

const useCreateOrder = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { toastError } = useToast();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const { mutate: createOrder, isSuccess } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    any
  >({
    mutationFn: async variables => {
      dispatch(showLoading());
      dispatch(setTextLoading(t('orders:createOrderPage.title')));
      try {
        const response = await httpClient.post(
          apiStrings.order.createOrder,
          variables
        );
        return {
          ...response.data.data,
          merchantBillId: variables.referenceId,
        };
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async () => {
      dispatch(hideLoading());
      dispatch(setTextLoading(''));
      setOpen(false);
    },

    onError: async err => {
      dispatch(hideLoading());
      dispatch(setTextLoading(''));
      setOpen(false);
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    open,
    handleOpenModal,
    handleCloseModal,
    createOrder,
    isSuccess,
  };
};

export default useCreateOrder;
