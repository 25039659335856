import { RadioChangeEvent } from 'antd';
import { Form } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryEnum, LocationType } from 'src/types';
import Select from 'src/components/Select';
import { Input } from 'src/components/Input';
import RadioGroup from 'src/components/RadioGroup';
import useGetLocation from 'src/hooks/common/useGetLocation';
import {
  validatePhoneNumber,
  validateRequired,
} from 'src/utils/validators/common';

import { IRadioOption } from 'src/components/RadioGroup/RadioGroup.d';
import { CustomerFormProps } from 'src/components/CreateOrder/type';
import classNames from 'classnames';

const CustomerForm = ({
  values,
  setFieldValue,
  setFieldTouched,
}: CustomerFormProps) => {
  const { t } = useTranslation();

  const { locations: cities, refetch: refetchProvince } = useGetLocation(
    LocationType.Province
  );
  const { locations: districts } = useGetLocation(
    LocationType.District,
    values.address?.province.id
  );
  const { locations: wards } = useGetLocation(
    LocationType.Ward,
    values.address?.district.id
  );

  const resetProvince = () => {
    setFieldValue('address.province.id', '');
    setFieldValue('address.province.name', '');
  };

  const resetDistrict = () => {
    setFieldValue('address.district.id', '');
    setFieldValue('address.district.name', '');
  };

  const resetWard = () => {
    setFieldValue('address.ward.id', '');
    setFieldValue('address.ward.name', '');
  };

  const resetTouched = () => {
    setFieldTouched('address.province.id', false);
    setFieldTouched('address.province.name', false);
    setFieldTouched('address.district.id', false);
    setFieldTouched('address.district.name', false);
    setFieldTouched('address.ward.id', false);
    setFieldTouched('address.ward.name', false);
    setFieldTouched('address.street', false);
  };

  useEffect(() => {
    if (cities.length > 0) {
      return;
    }

    refetchProvince();
  }, []);

  const isPickupAtStore = useMemo(() => {
    return values.delivery === DeliveryEnum.PickUpAtStore;
  }, [values]);

  const deliveryOptions: IRadioOption[] = useMemo(
    () => [
      {
        label: t('orders:createOrderPage.pickUpInStore'),
        value: DeliveryEnum.PickUpAtStore,
      },
      {
        label: t('orders:createOrderPage.deliveryToAddress'),
        value: DeliveryEnum.Delivery,
      },
    ],
    []
  );

  const handleChangeCity = async (value: any) => {
    await setFieldValue('address.province.id', value);
    const city = cities.find(e => e.value === value);
    await setFieldValue('address.province.name', city?.label);
    resetDistrict();
    resetWard();
  };

  const handleChangeDistrict = async (value: any) => {
    await setFieldValue('address.district.id', value);
    const district = districts.find(e => e.value === value);
    await setFieldValue('address.district.name', district?.label);
    resetWard();
  };

  const handleChangeWard = async (value: any) => {
    await setFieldValue('address.ward.id', value);
    const ward = wards.find(e => e.value === value);
    await setFieldValue('address.ward.name', ward?.label);
  };

  const handleChangeDelivery = async (e: RadioChangeEvent) => {
    await setFieldValue('delivery', e.target.value);
    if (e.target.value === DeliveryEnum.PickUpAtStore) {
      resetProvince();
      resetDistrict();
      resetWard();
      await setFieldValue('address.street', '');
      resetTouched();
    }
  };

  return (
    <>
      <Form
        className={classNames({
          '-mb--28': !isPickupAtStore,
        })}
      >
        <div className="d-flex items-center justify-center w--100">
          <Input
            type="text"
            name="phoneNumber"
            label={t('orders:createOrderPage.phone')}
            required
            placeholder={t('orders:createOrderPage.phonePlaceHolder')}
            value={values.phoneNumber}
            onChange={e => setFieldValue('phoneNumber', e.target.value)}
            rootClassName="w--100 mr--8"
            validate={(value: string) =>
              validatePhoneNumber(t, 'phoneNumber', value)
            }
          />
          <Input
            type="text"
            name="fullName"
            label={t('orders:createOrderPage.nameCustomer')}
            placeholder={t('orders:createOrderPage.nameCustomerPlaceHolder')}
            value={values.fullName}
            onChange={e => setFieldValue('fullName', e.target.value)}
            rootClassName="w--100"
            validate={() => {
              return '';
            }}
          />
        </div>
        <label className="label label--md mb--12">
          {t('orders:createOrderPage.delivery')}
          <span className="color--error ml--8">*</span>
        </label>
        <RadioGroup
          options={deliveryOptions}
          value={values?.delivery || ''}
          onValueChanged={handleChangeDelivery}
          vertical
          rootClassName="mb--8"
        />
        <div className="d-flex items-center justify-center w--100 mb--12">
          <Select
            name="address.province.id"
            placeholder={t('common:address.city')}
            value={values.address?.province.id}
            options={cities}
            onValueChanged={handleChangeCity}
            rootClassName="w--100 mr--8"
            disabled={isPickupAtStore}
            showSearch
            required
            hideValidate={true}
            validate={
              isPickupAtStore
                ? undefined
                : (value: string) => validateRequired(t, 'cityId', value)
            }
          />
          <Select
            name="address.district.id"
            placeholder={t('common:address.district')}
            value={values.address?.district.id}
            options={districts}
            onValueChanged={handleChangeDistrict}
            rootClassName="w--100 mr--8"
            disabled={isPickupAtStore}
            showSearch
            required
            hideValidate={true}
            validate={
              isPickupAtStore
                ? undefined
                : (value: string) => validateRequired(t, 'districtId', value)
            }
          />
          <Select
            name="address.ward.id"
            placeholder={t('common:address.ward')}
            value={values.address?.ward.id}
            options={wards}
            onValueChanged={handleChangeWard}
            rootClassName="w--100"
            disabled={isPickupAtStore}
            showSearch
            required
            hideValidate={true}
            validate={
              isPickupAtStore
                ? undefined
                : (value: string) => validateRequired(t, 'wardId', value)
            }
          />
        </div>
        <Input
          type="text"
          name="address.street"
          placeholder={t('orders:createOrderPage.deliveryAddressPlaceHolder')}
          value={values.address?.street}
          onChange={e => setFieldValue('address.street', e.target.value)}
          disabled={isPickupAtStore}
          required
          validate={
            isPickupAtStore
              ? undefined
              : (value: string) => validateRequired(t, 'address', value)
          }
        />
      </Form>
    </>
  );
};

export default CustomerForm;
