import FormSignIn from 'src/components/FormSignIn';
import PublishLayout from 'src/components/Layout/PublishLayout';

const SignIn = () => {
  return (
    <PublishLayout hasImage>
      <FormSignIn />
    </PublishLayout>
  );
};

export default SignIn;
