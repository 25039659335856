import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect } from 'react';

import ValidatedError from 'src/components/ValidatedError';

import { NumberInputProps } from 'src/components/Input';

const NumberInput = ({
  name,
  placeholder,
  value,
  onValueChanged,
  validate,
  formatter,
  max,
  addonAfter,
  addonBefore,
  prefix,
  suffix,
  label = '',
  required = false,
  className = '',
  rootClassName = '',
  resetValueOnUnmount = false,
}: NumberInputProps) => {
  const [field, meta, helper] = useField({
    name,
    value,
    validate,
  });

  useEffect(() => {
    const cleanupField = async () => {
      helper.setError('');
      await helper.setTouched(false);

      if (resetValueOnUnmount) {
        await helper.setValue('');
      }
    };

    return () => {
      cleanupField();
    };
  }, []);

  return (
    <div
      className={classNames('d-flex flex-col', {
        [rootClassName]: !!rootClassName,
      })}
    >
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <div className={!!addonAfter || !!addonBefore ? 'input' : ''}>
        <div className="input__addon">{addonBefore && addonBefore}</div>

        <div>
          <AntdInputNumber
            {...field}
            placeholder={placeholder}
            className={classNames({
              [className]: !!className,
            })}
            status={meta.touched && meta.error ? 'error' : ''}
            prefix={prefix && prefix}
            suffix={suffix && suffix}
            formatter={formatter && formatter}
            onChange={onValueChanged}
            min={0}
            max={max}
          />

          {validate && (
            <ValidatedError touched={meta.touched} error={meta.error} />
          )}
        </div>

        <div className="input__addon">{addonAfter && addonAfter}</div>
      </div>
    </div>
  );
};

export default NumberInput;
