import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { CustomModal } from 'src/components/Modal';
import { roles } from 'src/constants';
import { useGetPaymentInfo, useGetPaymentInfoConfirm } from 'src/hooks';
import { ReduxState } from 'src/redux/store';
import { numberFormatter } from 'src/utils/helper';
import { ConfirmOrderProps } from 'src/components/OrderDetail/type';
import { OrderStatus } from 'src/types';

const ConfirmOrder = ({ order }: ConfirmOrderProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useGetPaymentInfo(encodeURIComponent(id || ''));
  const [isFirstClick, setIsFirstClick] = useState(false);
  const { confirm, isLoading } = useGetPaymentInfoConfirm();
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const [open, setOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    if (!isFirstClick) {
      confirm(data, {
        onSuccess: () => setOpen(false),
      });
      setIsFirstClick(true);
    }
  };

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 rounded-br-8 rounded-bl-8">
        <div className="d-flex flex-col justify-between py--16 px--24 gap-y--12">
          <div className="d-flex flex-col justify-start items-start br--8 border border-gray-400 py--16 px--24 gap-y--24">
            <div className="d-flex flex-row justify-between items-center w--100">
              <p className="text-gray-800 paragraph paragraph--sm mr--4">
                {t('orders:columns.channel')}
              </p>
              <p className="text-gray-800 paragraph paragraph--sm mr--4">
                {t('orders:orderDetail.atStore')}
              </p>
            </div>
            <div className="d-flex flex-row justify-between items-center w--100">
              <p className="text-gray-800 paragraph paragraph--sm mr--4">
                {t('orders:orderDetail.amountCollectedAtTheCounter')}
              </p>
              <p className="text-gray-800 paragraph paragraph--sm mr--4 font-weight-600">
                {`${numberFormatter(data?.downPaymentAmount)} ${t('common:currency.vnd')}`}
              </p>
            </div>
          </div>

          {tokenInfo?.permissions.includes(roles.writeOrders) &&
            OrderStatus.Waiting === order?.status?.toUpperCase() && (
              <div className="d-flex flex-row justify-end">
                <Button
                  type={ButtonType.Primary}
                  colorType={ButtonColorType.Blue}
                  title={t('orders:orderDetail.confirm')}
                  size="middle"
                  className="label--sm"
                  onClickHandled={() => {
                    setOpen(true);
                    setIsFirstClick(false);
                  }}
                  disabled={!data || isLoading}
                />
              </div>
            )}
        </div>
      </div>

      <CustomModal
        open={open}
        title={t('orders:orderDetail.confirm')}
        footer={
          <div className="d-flex flex-row">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('orders:orderDetail.cancelBtn')}
              size="middle"
              className="label--md"
              rootClassName="mr--8"
              onClickHandled={() => {
                setOpen(false);
              }}
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:orderDetail.confirmBtn')}
              size="middle"
              className="label--md"
              onClickHandled={handleConfirm}
              disabled={isLoading}
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={() => {
          setOpen(false);
        }}
        destroyOnClose
      >
        <div className="d-flex flex-col gap--16">
          <div className="d-flex flex-row justify-center items-center">
            <p className="text-gray-700 paragraph paragraph--sm mr--4">
              {t('orders:orderDetail.amountCollectedAtTheCounter1')}
            </p>
            <p className="text-gray-700 paragraph paragraph--sm mr--4">
              {numberFormatter(data?.downPaymentAmount) +
                ' ' +
                t('common:currency.vnd')}
            </p>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default memo(ConfirmOrder);
