import { useMutation } from '@tanstack/react-query';
import { apiStrings, httpClient } from 'src/services';
import { useToast } from '../ui';
import { AxiosError } from 'axios';
import { ErrorCriteria, SubmitFileForm } from 'src/types';
import { useTranslation } from 'react-i18next';

const useSubmitFile = () => {
  const { toastError } = useToast();
  const { t } = useTranslation();

  const { mutate: submitFile } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    SubmitFileForm
  >({
    mutationFn: async variables => {
      let payload;
      if (variables.fileName) {
        payload = {
          ...variables,
          fileName: `${variables.fileName}.${variables.mediaType}`,
          prefix: `${variables.orderId}/`,
        };
      } else {
        payload = {
          ...variables,
          prefix: `${variables.orderId}/`,
        };
      }

      try {
        const response = await httpClient.post(
          apiStrings.file.submitFile,
          payload
        );
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },
    // onSuccess: async response => {
    //   callback(response);
    // },
    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return { submitFile };
};

export default useSubmitFile;
