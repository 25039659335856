import {
  Dropdown as AntdDropdown,
  Layout,
  Space as AntdSpace,
  MenuProps,
  Avatar as AntdAvatar,
} from 'antd';

import { ReactComponent as SignOutIcon } from 'src/assets/images/icons/sign-out.svg';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/arrow-down.svg';

import { COLOR_GRAY_500 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { UserOutlined } from '@ant-design/icons';
import useLogout from 'src/hooks/auth/useLogout';
import { ReduxState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { lowerCase, upperFirst } from 'lodash';

const { Header: AntdHeader } = Layout;

const CustomSignOutIcon = withIcon(SignOutIcon, {
  width: 18,
  height: 18,
});

const ArrowDownIcon = withIcon(ArrowDown, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_500,
});

const Header = () => {
  const { t } = useTranslation();
  const { logOut } = useLogout();
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const handleLogout = () => {
    logOut();
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <div
          className="d-flex justify-start items-center w--175 color--error"
          onClick={handleLogout}
        >
          <CustomSignOutIcon className="header__button mr--8" />
          <p>{t('signIn:logout')}</p>
        </div>
      ),
      key: '0',
    },
  ];

  return (
    <AntdHeader className="header">
      <div></div>
      <div className="d-flex justify-center items-center">
        <AntdAvatar size={32} icon={<UserOutlined />} />
        <AntdDropdown
          menu={{ items }}
          trigger={['click']}
          className="line-height-none"
        >
          <div className="d-flex flex-col justify-center items-start ml--12 cursor-pointer">
            <div className="d-flex justify-center items-center">
              <p className="paragraph paragraph--sm mr--8">
                {tokenInfo?.username || t('signIn:header.username')}
              </p>

              <AntdSpace>
                <ArrowDownIcon />
              </AntdSpace>
            </div>
            <p className="paragraph paragraph--sm text-gray-600">
              {tokenInfo?.roleName
                ? upperFirst(lowerCase(tokenInfo?.roleName))
                : t('signIn:header.roleName')}
            </p>
          </div>
        </AntdDropdown>
      </div>
    </AntdHeader>
  );
};

export default Header;
