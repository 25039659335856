import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Order } from 'src/assets/images/icons/order-black.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/images/icons/arrow-left.svg';
import { COLOR_GRAY_700, COLOR_GRAY_900 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import routeStrings from 'src/routes/routeStrings';
import { OrderStatus } from 'src/types';
import classNames from 'classnames';
import Tag from 'src/components/Tag';
import { camelCase } from 'lodash';

const ArrowLeftIcon = withIcon(ArrowLeft, {
  width: 30,
  height: 30,
  color: COLOR_GRAY_900,
});

const OrderIcon = withIcon(Order, {
  width: 15,
  height: 15,
  color: COLOR_GRAY_700,
});

interface HeaderPaymentProps {
  isPayment?: boolean;
  id?: string;
  status: string;
  backToCreateOrder?: () => void;
  merchantBillId?: string;
}

const HeaderPayment = ({
  isPayment = false,
  id,
  backToCreateOrder,
  status,
  merchantBillId,
}: HeaderPaymentProps) => {
  const { t } = useTranslation();
  const { id: idParams } = useParams();

  const navigate = useNavigate();

  const handleBack = () => {
    if (isPayment) {
      backToCreateOrder?.();
      return;
    }
    navigate(routeStrings.order);
  };

  return (
    <header className="body-header-two">
      <Breadcrumb
        items={[
          {
            title: (
              <div className="d-flex flex-row justìy-start items-center">
                <OrderIcon className="mr--4" />
                <Link to={'/order'}>{t('common:menuString.order')}</Link>
              </div>
            ),
          },
          {
            title: (
              <p>
                {t('orders:orderDetail.title', {
                  id: merchantBillId || id || idParams,
                })}
              </p>
            ),
          },
        ]}
      />
      <div className="d-flex justify-between items-center w--100 mt--6">
        <div className="d-flex flex-row items-center justìy-start">
          <ArrowLeftIcon
            className="mr--12 cursor--pointer"
            onClick={handleBack}
          />
          <h3 className="heading heading--lg">
            {t('orders:orderDetail.title', {
              id: merchantBillId || id || idParams,
            })}
          </h3>
          <Tag
            label={t(`orders:orderStatus.${camelCase(status)}`)}
            customClassName={classNames('label label--xs ml--16', {
              'status-list-item--success':
                status?.toUpperCase() === OrderStatus.Success,
              'status-list-item--waiting':
                status?.toUpperCase() === OrderStatus.Waiting,
              'status-list-item--expired':
                status?.toUpperCase() === OrderStatus.Expired,
              'status-list-item--cancelled':
                status?.toUpperCase() === OrderStatus.Cancelled,
              'status-list-item--pending':
                status?.toUpperCase() === OrderStatus.Pending,
              'status-list-item--rejected':
                status?.toUpperCase() === OrderStatus.Rejected,
            })}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderPayment;
