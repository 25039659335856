import { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import store from 'src/redux/store';

const ReduxProviders = ({ children }: PropsWithChildren) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProviders;
