export enum EnvPaymentIntegration {
  Sandbox = 'QA',
  Production = 'PROD',
}

export interface PaymentIntegration {
  merchantId: string;
  clientId: string;
  secretKey: string;
  configs: Configs;
}

export interface Configs {
  ipnUrl: string;
  env: EnvPaymentIntegration;
}

export interface SendUrlNotification {
  ipnUrl: string;
  env: EnvPaymentIntegration;
}
