import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { apiStrings, httpClient } from 'src/services';
import { ISelectItem, LocationType } from 'src/types';

const useGetLocation = (type: LocationType, id: string = '') => {
  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: [`${type.toUpperCase()}-list`, id],
    queryFn: async () => {
      try {
        const queryById = !id ? '' : `&id=${id}`;
        const response = await httpClient.get(
          `${apiStrings.address}?type=${type}${queryById}`
        );
        return response.data.data.addressInfo;
      } catch (error) {
        throw error;
      }
    },
    select: response => {
      return response.map((locationItem: any) => ({
        value: locationItem.id,
        label: locationItem.name,
      })) as ISelectItem[];
    },
    enabled: false,
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    refetch();
  }, [id]);

  return { locations: data || [], isLoading, refetch, isSuccess };
};

export default useGetLocation;
