import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Headphone } from 'src/assets/images/icons/headphone.svg';
import BodyContent from 'src/components/BodyContent';
import { PaymentIntegrationItem } from 'src/components/PaymentIntegration';
import { COLOR_PRIMARY_600 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { usePaymentIntegrationQuery } from 'src/hooks';

import { EnvPaymentIntegration } from 'src/types';

const HeadphoneIcon = withIcon(Headphone, {
  width: 64,
  height: 64,
  color: COLOR_PRIMARY_600,
});

const PaymentIntegration = () => {
  const { t } = useTranslation(['common', 'paymentIntegration']);

  const { data } = usePaymentIntegrationQuery();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('paymentIntegration:sandbox'),
      children: (
        <PaymentIntegrationItem
          data={data?.qa}
          env={EnvPaymentIntegration.Sandbox}
        />
      ),
      disabled: !data?.qa,
    },
    {
      key: '2',
      label: t('paymentIntegration:production'),
      children: (
        <PaymentIntegrationItem
          data={data?.prod}
          env={EnvPaymentIntegration.Production}
        />
      ),
      disabled: !data?.prod,
    },
  ];

  return (
    <BodyContent pageTitle={t('paymentIntegration:title')}>
      {data ? (
        <Tabs
          defaultActiveKey="1"
          items={items}
          className="-mt--24 -mx--24 header-tabs-color"
        />
      ) : (
        <div className="d-flex flex-col w--100 items-center justify-center h-full">
          <HeadphoneIcon />
          <p className="label label--lg mt--12">
            {t('paymentIntegration:error')}
          </p>
        </div>
      )}
    </BodyContent>
  );
};

export default PaymentIntegration;
