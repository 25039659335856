import { useTranslation } from 'react-i18next';
import BannerImage from 'src/assets/images/regular/banner.jpg';

const Banner = () => {
  const { t } = useTranslation();
  const handleClick = () => {
    window.open(process.env.REACT_APP_BANNER_URL, '_blank');
  };

  return (
    <div onClick={handleClick} className="cursor-pointer mt--24 br--8">
      <img
        src={BannerImage}
        alt={t('common:orders.banner')}
        width={'100%'}
        className="br--8"
      />
    </div>
  );
};

export default Banner;
