import { useTranslation } from 'react-i18next';
import BodyContent from 'src/components/BodyContent';

const PermissionDenied = () => {
  const { t } = useTranslation();

  return (
    <BodyContent
      pageTitle={t('menuString.permissionDenied')}
      className="permission-denied"
    >
      <div className="page-wrapper">
        <p>{t('permissionDenied')}</p>
      </div>
    </BodyContent>
  );
};

export default PermissionDenied;
