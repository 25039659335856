import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';

import { DATE_HOUR_MIN_FORMAT } from 'src/constants';
import { apiStrings, httpClient } from 'src/services';
import { buildQueryString } from 'src/utils/helper';

dayjs.extend(utc);

const useOrdersQuery = (page: number, size: number, queryStringObj: any) => {
  const queryString = useMemo(
    () => buildQueryString(queryStringObj),
    [queryStringObj]
  );

  const {
    data,
    isLoading: isOrdersLoading,
    refetch: ordersRefetch,
  } = useQuery({
    queryKey: ['orders', page, size, queryString],
    queryFn: async () => {
      try {
        const response = await httpClient.get(
          apiStrings.order.listOrder +
            `?offset=${page - 1}&limit=${size}${!!queryString ? `&${queryString}` : ''}`
        );

        return {
          orders: response.data ? response.data.data.orderInfo : [],
          totalOrders: response.data
            ? response.data.data.pageable.totalItems
            : 0,
        };
      } catch (error) {
        throw error;
      }
    },
    select: res => {
      const transformedOrder = res?.orders.map((order: any) => ({
        key: order.referenceId,
        merchantBillId: order.merchantBillId || null,
        fundiinOrderId: order.fundiinOrderId || '-',
        totalAmount: order.totalAmount,
        status: order.status,
        source: order.source || '-',
        paymentMethod: order.paymentMethod || '-',
        paymentTerm: order.paymentTerm || '-',
        channel: order.channel,
        createdDate: dayjs
          .utc(order?.createdDate || '')
          .local()
          .format(DATE_HOUR_MIN_FORMAT),
        phoneNumber: order.customerInfo.phoneNumber,
        fullName: order.customerInfo.fullName || '-',
        storeId: order.storeInfo.storeId,
        storeName: order.storeInfo.storeName,
        referenceId: order.referenceId,
      }));

      return {
        orders: transformedOrder,
        totalOrders: res.totalOrders,
      };
    },
    cacheTime: 0,
  });

  return {
    orders: data?.orders,
    isOrdersLoading,
    totalOrders: data?.totalOrders,
    ordersRefetch,
  };
};

export default useOrdersQuery;
