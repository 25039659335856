import { Pagination as AntdPagination, Table as AntdTable } from 'antd';
import { BaseSyntheticEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

import Filter from 'src/components/Filter';
import { SearchInput } from 'src/components/Input';

import { ITableProps } from 'src/components/Table/Table.d';
import { numberFormatter } from 'src/utils/helper';
import { useTranslation } from 'react-i18next';
import Reload from 'src/components/Reload';

export const DEFAULT_INITIAL_PAGE = 1;
export const DEFAULT_SIZE = 10;
export const DEFAULT_SIZE_OPTIONS = [5, 10, 20, 50, 100];

const Table = ({
  columns,
  dataSource,
  totalItems,
  showTotal,
  filterCriteria,
  setFilterCriteria,
  onFilterCleared,
  onFiltered,
  filterComponent,
  rowSelection,
  searchPlaceholder = '',
  scroll = { x: `calc(100vw - 32px)`, y: `calc(100vh - 30rem)` },
  loading = false,
  isDirty = false,
  rowKey,
  onRefresh,
  toolExtra,
}: ITableProps) => {
  const { t } = useTranslation(['common']);
  const [searchParams, setSearchParams] = useSearchParams();

  const onPaginationChanged = (currentPage: number, pageSize: number) => {
    setFilterCriteria({
      ...filterCriteria,
      paging: {
        page: currentPage,
        pageSize: pageSize,
      },
    });

    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: currentPage.toString(),
      pageSize: pageSize.toString(),
    });
  };

  const onSearchChanged = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setFilterCriteria({
      ...filterCriteria,
      filters: {
        ...filterCriteria.filters,
        searchText: event.target.value,
      },
    });
  };

  return (
    <>
      {(searchPlaceholder || filterComponent || !!showTotal) && (
        <section className="filter">
          {!!showTotal && (
            <p className="paragraph paragraph--sm">
              {t(`common:total`)}: {numberFormatter(totalItems)} {showTotal}
            </p>
          )}

          <div className="filter__search">
            {toolExtra}

            {searchPlaceholder && onFiltered && (
              <SearchInput
                label=""
                name="searchText"
                placeholder={searchPlaceholder || ''}
                className="paragraph paragraph--sm filter__search-input"
                value={filterCriteria.filters.searchText || ''}
                onValueChanged={onSearchChanged}
                onValueSearched={onFiltered}
              />
            )}

            {filterComponent && onFiltered && onFilterCleared && (
              <Filter
                isDirty={isDirty}
                onFiltered={onFiltered}
                onFilterCleared={onFilterCleared}
              >
                {filterComponent}
              </Filter>
            )}

            {onRefresh && <Reload onClick={onRefresh} />}
          </div>
        </section>
      )}

      <AntdTable
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        loading={loading}
        pagination={false}
        scroll={scroll}
        rowKey={rowKey}
      />

      <div className="pagination">
        <AntdPagination
          current={filterCriteria.paging.page || DEFAULT_INITIAL_PAGE}
          pageSize={filterCriteria.paging.pageSize || DEFAULT_SIZE}
          total={totalItems}
          pageSizeOptions={DEFAULT_SIZE_OPTIONS}
          showSizeChanger
          onChange={onPaginationChanged}
        />
      </div>
    </>
  );
};

export default Table;
