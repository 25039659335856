import classNames from 'classnames';

import Breadcrumb from 'src/components/Breadcrumb';
import ButtonWrapper from 'src/components/BodyContent/ButtonWrapper';

import { IBodyContentProps } from 'src/components/BodyContent/BodyContent.d';

const BodyContent = ({
  pageTitle,
  nextTitle,
  nextIcon,
  onNext,
  isNextSubmit,
  backTitle,
  backIcon,
  onBack,
  isBackSubmit,
  children,
  className = '',
  disableNextBtn = false,
}: IBodyContentProps) => {
  return (
    <div
      className={classNames('body', {
        [className]: !!className,
      })}
    >
      <header className="body-header">
        <Breadcrumb items={pageTitle} />

        <ButtonWrapper
          nextTitle={nextTitle}
          onNext={onNext}
          nextIcon={nextIcon && nextIcon}
          isNextSubmit={isNextSubmit}
          backTitle={backTitle}
          onBack={onBack && onBack}
          backIcon={backIcon && backIcon}
          isBackSubmit={isBackSubmit}
          disableNextBtn={disableNextBtn}
        />
      </header>

      <main className="body__content">{children}</main>
    </div>
  );
};

export default BodyContent;
