import { Button as AntdButton, ConfigProvider } from 'antd';
import classNames from 'classnames';

import {
  COLOR_DUST_RED_400,
  COLOR_DUST_RED_600,
  COLOR_GRAY_100,
  COLOR_GRAY_500,
  COLOR_GRAY_900,
  COLOR_PRIMARY_500,
  COLOR_PRIMARY_600,
} from 'src/constants';

import { ButtonColorType, ButtonType, IButtonProps } from './type';

const Button = ({
  type,
  colorType,
  title,
  onClickHandled,
  className = '',
  disabled = false,
  icon = undefined,
  subIcon = undefined,
  size = undefined,
  isSubmit = false,
  danger = false,
  block = false,
  rootClassName = '',
}: IButtonProps) => {
  const getColorText = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_GRAY_900;
      case ButtonColorType.Blue:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Red:
        if (type !== ButtonType.Default) {
          return COLOR_GRAY_100;
        }
        return COLOR_DUST_RED_600;
      default:
        return COLOR_GRAY_100;
    }
  };

  const getColorBg = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
      case ButtonColorType.Blue:
      case ButtonColorType.White:
        return COLOR_GRAY_100;
      case ButtonColorType.Red:
        if (type === ButtonType.Default) {
          return COLOR_GRAY_100;
        }
        return COLOR_DUST_RED_600;
      default:
        return COLOR_PRIMARY_600;
    }
  };

  const getColorBorder = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_GRAY_500;
      case ButtonColorType.Blue:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Red:
        return COLOR_DUST_RED_600;
      case ButtonColorType.White:
        return COLOR_GRAY_500;
      default:
        return '';
    }
  };

  const getColorHover = () => {
    switch (colorType) {
      case ButtonColorType.Gray:
        return COLOR_PRIMARY_600;
      case ButtonColorType.Blue:
        return COLOR_PRIMARY_500;
      case ButtonColorType.Red:
        return COLOR_DUST_RED_400;
      default:
        return '';
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBorder: !disabled ? getColorBorder() : 'none',
            colorBgContainer: getColorBg(),
            colorText: getColorText(),
            colorPrimaryHover: getColorHover(),
          },
        },
      }}
    >
      <AntdButton
        size={size}
        htmlType={isSubmit ? 'submit' : 'button'}
        type={type}
        className={classNames(
          title ? 'btn' : 'btn-icon',
          'label label--sm d-flex justify-center',
          {
            [rootClassName]: !!rootClassName,
          },
          !disabled &&
            type === ButtonType.Default && {
              'btn-gray': colorType === ButtonColorType.Gray,
              'btn-blue': colorType === ButtonColorType.Blue,
              'btn-red': colorType === ButtonColorType.Red,
            },
          subIcon && { 'flex-direction': 'row' }
        )}
        disabled={disabled}
        icon={icon && icon}
        onClick={!isSubmit && onClickHandled ? onClickHandled : undefined}
        danger={danger}
        block={block}
      >
        {title && (
          <span
            className={classNames('label label-sm', {
              'mr--12': subIcon,
              [className]: !!className,
            })}
          >
            {title}
          </span>
        )}
        {subIcon && subIcon}
      </AntdButton>
    </ConfigProvider>
  );
};

export { Button as default, ButtonType, ButtonColorType };
