import { DatePickerProps, TimePickerProps } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { camelCase, capitalize, chain, isObject, split } from 'lodash';
import { TFunction } from 'i18next';

import {
  DATE_FORMAT_SUBMITTING,
  DATE_HOUR_MIN_FORMAT,
  TIME_FORMAT_SUBMITTING,
} from 'src/constants';

import { ISelectItem } from 'src/types';

dayjs.extend(utc);

export const buildQueryString = (queryStringObj: any) => {
  for (const key in queryStringObj) {
    if (
      queryStringObj[key] === null ||
      queryStringObj[key] === undefined ||
      queryStringObj[key] === ''
    )
      delete queryStringObj[key];
  }

  return new URLSearchParams(queryStringObj).toString();
};

export const convertToSelectItem = (
  target: object,
  t: TFunction,
  key?: string,
  defaultOption?: ISelectItem
): ISelectItem[] => {
  if (!isObject(target)) {
    return [];
  }

  const convertedValue = Object.values(target).map(item => {
    return {
      value: item,
      label: key ? t(`${key}.${camelCase(item)}`) : t(camelCase(item)),
    };
  });

  if (defaultOption) {
    return [defaultOption, ...convertedValue];
  }

  return [...convertedValue];
};

export const convertToSelectItemNumber = (
  target: object,
  defaultOption?: ISelectItem
): ISelectItem[] => {
  if (!isObject(target)) return [];

  const filteredValues = Object.values(target).filter(
    value => typeof value === 'number'
  );

  const convertedValue = filteredValues.map(item => {
    return { value: item, label: `${item}` };
  });

  if (defaultOption) return [defaultOption, ...convertedValue];

  return [...convertedValue];
};

export const numberFormatter = (value: number | undefined) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numberParser = (value: string | undefined) =>
  +value!.replace(/\s?|(,*)/g, '');

export const percentageFormatter = (value: number | undefined) => `${value}`;

export const percentageParser = (value: string | undefined) =>
  +value!.replace('', '');

export const formatDateTimeToUtc = (
  date: DatePickerProps['value'],
  time: TimePickerProps['value']
) => {
  const formattedDate = dayjs(date).format(DATE_FORMAT_SUBMITTING);
  const formattedTime = dayjs(time).format(TIME_FORMAT_SUBMITTING);
  return dayjs(`${formattedDate} ${formattedTime}`).toISOString();
};

export const getFileNameFromUrl = (url: string) => {
  const fileString = split(url, '/').pop()!;
  const extension = fileString.split('.').pop();
  const name = fileString.substring(0, fileString.lastIndexOf('.'));
  try {
    return `${decodeURI(atob(decodeURIComponent(name)))}.${extension}`;
  } catch {
    return `${decodeURIComponent(name)}.${extension}`;
  }
};

export const getFileNameFromGoogle = (string: string) => string.split('?')[0];

export const downloadFile = async (downloadUrl: string, type?: string) => {
  if (!downloadUrl) {
    return;
  }

  const response = await axios.get(downloadUrl, {
    responseType: 'blob',
  });

  if (!response?.data) return;

  const blobObj = new Blob([response.data]);
  const url = window.URL.createObjectURL(blobObj);
  const linkElement = document.createElement('a');

  linkElement.href = url;
  linkElement.setAttribute(
    'download',
    type === 'google'
      ? getFileNameFromGoogle(getFileNameFromUrl(downloadUrl))
      : getFileNameFromUrl(downloadUrl)
  );
  document.body.appendChild(linkElement);
  linkElement.click();

  const timerId = setTimeout(() => {
    document.body.removeChild(linkElement);
    window.URL.revokeObjectURL(url);
    clearTimeout(timerId);
  }, 100);
};

export const formatDateToLocalUtc = (
  date: string,
  formatType = DATE_HOUR_MIN_FORMAT
) => {
  return dayjs.utc(date).local().format(formatType);
};

export const convertSnakeToUpperFirst = (str: string) =>
  chain(str).split('_').map(capitalize).join(' ').value();

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const snackCaseToCamelCase = (str: string): string => {
  return str.replace(/([-_]\w)/g, match => {
    return match.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const countDaysBetween = (
  timestamp1: dayjs.ConfigType,
  timestamp2: dayjs.ConfigType
): number => {
  // Convert timestamps to dayjs objects
  const date1 = dayjs(timestamp1);
  const date2 = dayjs(timestamp2);

  // Calculate the difference in days
  return date1.diff(date2, 'day');
};

export const diffInSeconds = (date1: string, date2: string): number => {
  const dayjsDate1 = dayjs.utc(date1).local();
  const dayjsDate2 = dayjs.utc(date2).local();

  return dayjsDate2.diff(dayjsDate1, 'second');
};
