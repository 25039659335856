import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Header,
  TimeLine,
  ProofOfDelivery,
  PaymentCountDown,
  ConfirmOrder,
} from 'src/components/OrderDetail';
import { Payment, Product, Customer, Store } from 'src/components/CreateOrder';
import { OTHER_REASON_ID, roles } from 'src/constants';
import { useCancelOrder, useOrderDetail } from 'src/hooks';
import { ReduxState } from 'src/redux/store';
import { Store as StoreType, OrderStatus, CancelOrderForm } from 'src/types';

const OrderDetail = () => {
  const { id } = useParams();
  const { order, isLoading, refetchOrderDetail } = useOrderDetail(
    encodeURIComponent(id || ''),
    false
  );
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const isSuccessOrder = useMemo(
    () => order?.status === OrderStatus.Success,
    [order]
  );

  const isShowProofs = useMemo(
    () =>
      order?.status === OrderStatus.Success &&
      tokenInfo?.permissions.includes(roles.createProofs),
    [order]
  );

  const { cancelOrder } = useCancelOrder();

  const handleCancel = (value: CancelOrderForm) => {
    const payload = {
      referenceId: order?.referenceId,
      reason: value.reasonId === OTHER_REASON_ID ? value.other : value.reason,
      amount: {
        value: order?.amount.value,
        currency: order?.amount.currency,
      },
    };
    cancelOrder(payload, {
      onSuccess: refetchOrderDetail,
    });
  };

  const showConfirmButton = useMemo(() => {
    return (
      [
        OrderStatus.Waiting,
        OrderStatus.Success,
        OrderStatus.Pending,
        OrderStatus.Rejected,
      ].includes(order?.status?.toUpperCase()) &&
      !isLoading &&
      order?.inStorePayment
    );
  }, [order, isLoading]);

  const showPaymentCountDown = useMemo(() => {
    return (
      order?.status?.toUpperCase() === OrderStatus.Waiting &&
      !isLoading &&
      !order?.inStorePayment &&
      !!order?.sessionPayment
    );
  }, [order, isLoading]);

  return (
    <div className="body">
      <Header
        status={order?.status || ''}
        products={order?.items}
        discountValue={order?.amount.discount}
        cancelOrder={handleCancel}
        isLoading={isLoading}
        merchantBillId={order?.merchantBillId}
        reason={order?.reason}
        order={order}
      />
      <main className="body__content">
        <Row gutter={24}>
          <Col flex={7} className="col-product">
            <Product
              products={order?.items}
              isDetail
              referenceId={order?.merchantBillId}
              isSuccessOrder={isSuccessOrder}
              updatedMerchantBill={refetchOrderDetail}
              reasonCancel={
                order?.status === OrderStatus.Cancelled && order?.reason
              }
            />
            <Payment
              products={order?.items}
              discountAmount={order?.amount.discount}
              totalAmount={order?.amount.value}
              isDetail
            />
            {showConfirmButton && <ConfirmOrder order={order} />}
            {showPaymentCountDown && (
              <PaymentCountDown order={order} isLoading={isLoading} />
            )}
            {isShowProofs && (
              <ProofOfDelivery order={order} submitProof={refetchOrderDetail} />
            )}
            {isSuccessOrder && !!order?.refundInfo && (
              <TimeLine order={order} />
            )}
          </Col>
          <Col flex={3} className="col-info">
            <Customer customer={order?.customerInfo} isDetail />
            <Store
              isDetail
              store={order?.storeInfo || ({} as StoreType)}
              staff={order?.sellerInfo?.phoneNumber || ''}
            />
          </Col>
        </Row>
      </main>
    </div>
  );
};

export default OrderDetail;
