import {
  Checkbox,
  Dropdown,
  MenuProps,
  Button as AntdButton,
  DropdownProps,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Formik } from 'formik';
import { camelCase, map, split } from 'lodash';
import { Key, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as Plus } from 'src/assets/images/icons/plus.svg';
import { ReactComponent as More } from 'src/assets/images/icons/more.svg';
import { ReactComponent as Download } from 'src/assets/images/icons/download-black.svg';
import { ReactComponent as ArrowDown } from 'src/assets/images/icons/chevron-down.svg';
import BodyContent from 'src/components/BodyContent';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import DateRange from 'src/components/DateTimePicker/DateRange';
import Select from 'src/components/Select';
import Table, {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_SIZE,
} from 'src/components/Table';
import Tag from 'src/components/Tag';
import { COLOR_GRAY_100, COLOR_GRAY_900, roles } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import {
  useExportOrder,
  useOrdersQuery,
  useStatusQuery,
  useStoresQuery,
} from 'src/hooks';
import { routeStrings } from 'src/routes';
import { numberFormatter } from 'src/utils/helper';

import { SelectMode } from 'src/components/Select/Select.d';
import {
  FilterCriteria,
  FilterOrder,
  OrdersColumnType,
  OrderStatus,
} from 'src/types';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/store';

const PlusIcon = withIcon(Plus, {
  width: 18,
  height: 18,
  color: COLOR_GRAY_100,
});
const DownloadIcon = withIcon(Download, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});
const ArrowDownIcon = withIcon(ArrowDown, {
  width: 20,
  height: 20,
});
const MoreIcon = withIcon(More, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});

const hideColumns = ['channel', 'source'];

dayjs.extend(utc);

const Orders = () => {
  const { t } = useTranslation(['common', 'orders']);
  const navigate = useNavigate();
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedKeyRows, setSelectedKeyRows] = useState<{ id: Key }[]>([]);
  const [filterCriteria, setFilterCriteria] = useState<
    FilterCriteria<FilterOrder>
  >({
    filters: {},
    paging: {
      page: DEFAULT_INITIAL_PAGE,
      pageSize: DEFAULT_SIZE,
    },
    sorts: [],
  });
  const [queryString, setQueryString] = useState<
    FilterCriteria<FilterOrder>['filters']
  >({});

  const { stores, isStoreLoading } = useStoresQuery({
    showId: false,
    defaultOption: {
      label: t('all'),
      value: '',
    },
  });

  useEffect(() => {
    if (searchParams.size < 1) {
      return;
    }

    const querystringObj = Object.fromEntries(searchParams);
    const transformToFilterCriteria = {
      searchText: querystringObj?.searchText || '',
      storeId: querystringObj?.storeId || '',
      startDate: querystringObj?.startDate || null,
      endDate: querystringObj?.endDate || null,
      statuses: querystringObj?.statuses
        ? split(querystringObj?.statuses, ',')
        : [],
    };

    setQueryString({
      ...queryString,
      ...transformToFilterCriteria,
    });

    setFilterCriteria({
      ...filterCriteria,
      filters: {
        ...filterCriteria.filters,
        ...transformToFilterCriteria,
        startDate: querystringObj?.startDate
          ? dayjs(dayjs.utc(querystringObj?.startDate).local())
          : null,
        endDate: querystringObj?.endDate
          ? dayjs(dayjs.utc(querystringObj?.endDate).local())
          : null,
      },
      paging: {
        page: +querystringObj?.page || DEFAULT_INITIAL_PAGE,
        pageSize: +querystringObj?.pageSize || DEFAULT_SIZE,
      },
    });
  }, []);

  const { statuses, isStatusLoading } = useStatusQuery();

  const { orders, isOrdersLoading, totalOrders } = useOrdersQuery(
    filterCriteria.paging.page,
    filterCriteria.paging.pageSize,
    queryString
  );
  const openDetailPage = (id: string) => {
    navigate(`${routeStrings.order}/${id}`);
  };

  const columns: ColumnsType<OrdersColumnType> = useMemo(() => {
    return [
      {
        title: t('orders:columns.fundiinOrderId'),
        dataIndex: 'fundiinOrderId',
        key: 'fundiinOrderId',
        width: 150,
        fixed: 'left' as const,
        render: (_, record) => <p>{record.fundiinOrderId}</p>,
      },
      {
        title: t('orders:columns.paymentFee'),
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        fixed: 'left' as const,
        width: 160,
        align: 'right' as const,
        render: (value: number) => (
          <p>
            {numberFormatter(value)} {t('common:currency.vnd')}
          </p>
        ),
      },
      {
        title: t('orders:columns.status'),
        dataIndex: 'status',
        key: 'status',
        fixed: 'left' as const,
        width: 200,
        render: (_: any, record: { status: string }) => (
          <Tag
            label={t(`orders:orderStatus.${camelCase(record.status)}`)}
            customClassName={classNames('label label--xs mr--8', {
              'status-list-item--success':
                record.status.toUpperCase() === OrderStatus.Success,
              'status-list-item--waiting':
                record.status.toUpperCase() === OrderStatus.Waiting,
              'status-list-item--expired':
                record.status.toUpperCase() === OrderStatus.Expired,
              'status-list-item--cancelled':
                record.status.toUpperCase() === OrderStatus.Cancelled,
              'status-list-item--pending':
                record.status.toUpperCase() === OrderStatus.Pending,
              'status-list-item--rejected':
                record.status.toUpperCase() === OrderStatus.Rejected,
            })}
          />
        ),
      },
      {
        title: t('orders:columns.fullName'),
        dataIndex: 'fullName',
        key: 'fullName',
        width: 250,
      },
      {
        title: t('orders:columns.phoneNumber'),
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 150,
      },
      {
        title: t('orders:columns.referenceId'),
        dataIndex: 'merchantBillId',
        key: 'merchantBillId',
        width: 150,
        render: (merchantBillId, record) => (
          <p>{merchantBillId ? merchantBillId : record.referenceId}</p>
        ),
      },
      {
        title: t('orders:columns.createdDate'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 180,
      },
      {
        title: t('orders:columns.paymentTerm'),
        dataIndex: 'paymentTerm',
        key: 'paymentTerm',
        width: 180,
      },
      {
        title: t('orders:columns.paymentMethod'),
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        width: 200,
      },
      {
        title: t('orders:columns.source'),
        dataIndex: 'source',
        key: 'source',
        width: 140,
      },
      {
        title: t('orders:columns.channel'),
        dataIndex: 'channel',
        key: 'channel',
        width: 140,
      },
      {
        title: t('orders:columns.storeName'),
        dataIndex: 'storeName',
        key: 'storeName',
        width: 180,
      },
      {
        title: t('orders:columns.storeId'),
        dataIndex: 'storeId',
        key: 'storeId',
        width: 180,
      },
      {
        dataIndex: 'storeId',
        key: 'referenceId',
        width: 60,
        fixed: 'right' as const,
        align: 'center',
        render: (_, record) => {
          const items: MenuProps['items'] = [
            {
              label: (
                <div
                  onClick={() =>
                    openDetailPage(encodeURIComponent(record.referenceId))
                  }
                >
                  {t('orders:detail')}
                </div>
              ),
              key: 1,
            },
          ];

          return (
            <Dropdown menu={{ items }} trigger={['click']}>
              <div
                className="cursor--pointer"
                onClick={e => e.preventDefault()}
              >
                <MoreIcon />
              </div>
            </Dropdown>
          );
        },
      },
    ];
  }, [orders]);

  const { exportOrder } = useExportOrder(async () => {
    setSelectedKeyRows([]);
  });

  const filterComponent = useMemo(() => {
    const onStatusChanged = (value: string[]) =>
      setFilterCriteria({
        ...filterCriteria,
        filters: {
          ...filterCriteria.filters,
          statuses: value,
        },
      });

    const onStoreChanged = (value: string) =>
      setFilterCriteria({
        ...filterCriteria,
        filters: {
          ...filterCriteria.filters,
          storeId: value,
        },
      });

    const onDatesChanged = (dates: any[]) => {
      setFilterCriteria({
        ...filterCriteria,
        filters: {
          ...filterCriteria.filters,
          startDate: dates[0] || null,
          endDate: dates[1] || null,
        },
      });
    };

    return (
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <div className="d-flex flex-col gap--28">
            <DateRange
              label={t('orders:filter.time')}
              value={[
                filterCriteria.filters.startDate,
                filterCriteria.filters.endDate,
              ]}
              onChange={onDatesChanged}
              name="date"
              placeholder={[t('startDate'), t('endDate')]}
            />
            <Select
              name="statuses"
              label={t('orders:filter.status')}
              placeholder={t('select')}
              value={filterCriteria.filters.statuses}
              options={statuses}
              onValueChanged={onStatusChanged}
              loading={isStatusLoading}
              mode={SelectMode.Multiple}
              maxTagCount="responsive"
              maxTagTextLength={25}
              showDefault
            />
            <Select
              name="storeId"
              label={t('orders:filter.store')}
              placeholder={t('select')}
              value={filterCriteria.filters.storeId}
              options={stores}
              loading={isStoreLoading}
              onValueChanged={onStoreChanged}
              showDefault
            />
          </div>
        )}
      </Formik>
    );
  }, [filterCriteria, t, stores, statuses]);

  const handleMenuClick: MenuProps['onClick'] = e => {
    if (e.key === '3') {
      setOpenDropdown(false);
    }
  };

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpenDropdown(nextOpen);
    }
  };

  const onBtnClicked = (event: SyntheticEvent) => {
    event.preventDefault();
    navigate(routeStrings.createOrder);
  };

  const onFilterCleared = () => {
    setQueryString({});
    setFilterCriteria({
      filters: {},
      paging: {
        page: DEFAULT_INITIAL_PAGE,
        pageSize: DEFAULT_SIZE,
      },
      sorts: [],
    });

    setSearchParams({});
  };

  const onFiltered = () => {
    setFilterCriteria({
      ...filterCriteria,
      paging: {
        ...filterCriteria.paging,
        page: DEFAULT_INITIAL_PAGE,
      },
    });

    const updatedQueryString = {
      searchText: filterCriteria.filters.searchText,
      statuses: filterCriteria.filters.statuses,
      storeId: filterCriteria.filters.storeId,
      startDate: filterCriteria.filters.startDate
        ? dayjs(filterCriteria.filters.startDate).startOf('day').toISOString()
        : null,
      endDate: filterCriteria.filters.endDate
        ? dayjs(filterCriteria.filters.endDate).endOf('day').toISOString()
        : null,
    };

    setQueryString(updatedQueryString);

    setSearchParams({
      searchText: filterCriteria.filters.searchText || '',
      startDate: filterCriteria.filters.startDate
        ? dayjs(filterCriteria.filters.startDate).startOf('day').toISOString()
        : '',
      endDate: filterCriteria.filters.endDate
        ? dayjs(filterCriteria.filters.endDate).endOf('day').toISOString()
        : '',
      statuses: filterCriteria.filters.statuses?.join(',') || '',
      storeId: filterCriteria.filters.storeId || '',
      page: DEFAULT_INITIAL_PAGE.toString(),
      pageSize: filterCriteria.paging.pageSize.toString(),
    });
  };

  const onExportOrder = () => {
    exportOrder({
      queryStringObj: queryString,
      recordIds: map(selectedKeyRows, 'id') || [],
    });
  };

  const rowSelection: TableRowSelection<OrdersColumnType> = {
    onChange: (_, selectedRows) => {
      const targets = selectedRows.map(selectedRow => ({
        id: selectedRow.key,
      }));
      setSelectedKeyRows(targets);
    },
    // To set selected rows to empty when filter
    selectedRowKeys: selectedKeyRows.map(item => item.id),
    checkStrictly: true,
  };

  const extraComponent = useMemo(() => {
    const handleChangeCheckbox = (e: CheckboxChangeEvent, key: string) => {
      const checked = e.target.checked;
      setSelectedOptions(prevSelectedOptions => {
        if (checked) {
          return [...prevSelectedOptions, key];
        } else {
          return prevSelectedOptions.filter(option => option !== key);
        }
      });
    };

    const items: MenuProps['items'] = hideColumns.map(item => ({
      label: (
        <Checkbox
          onChange={e => handleChangeCheckbox(e, item)}
          key={item}
          checked={selectedOptions.includes(item)}
        >
          {t(`orders:columns.${item}`)}
        </Checkbox>
      ),
      key: item,
    }));

    return (
      <div className="d-flex flex-row justify-center items-center">
        <Button
          type={ButtonType.Text}
          colorType={ButtonColorType.Gray}
          icon={<DownloadIcon />}
          title={t('orders:download')}
          onClickHandled={onExportOrder}
          rootClassName="mr--8"
        />
        <Dropdown
          menu={{ items, onClick: handleMenuClick }}
          onOpenChange={handleOpenChange}
          open={openDropdown}
          className={
            selectedOptions.length > 0 ? 'border-color-primary-600' : ''
          }
        >
          <AntdButton onClick={e => e.preventDefault()}>
            <div
              className={classNames('d-flex flex-row items-center', {
                'text-primary-600': selectedOptions.length > 0,
              })}
            >
              {`${t('orders:showColumns')} ${selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
              <ArrowDownIcon className="ml--4" />
            </div>
          </AntdButton>
        </Dropdown>
      </div>
    );
  }, [selectedKeyRows, selectedOptions, openDropdown, queryString]);

  const newColumns = useMemo(() => {
    return columns.map(column => {
      if (hideColumns.includes(column.key as string)) {
        return {
          ...column,
          hidden: !selectedOptions.includes(column.key as string),
        };
      }

      return column;
    });
  }, [selectedOptions]);

  const showCreateOrderBtn = useMemo(() => {
    return tokenInfo?.permissions.includes(roles.createOrders);
  }, [tokenInfo, roles]);

  return (
    <>
      <BodyContent
        pageTitle={t('orders:title')}
        nextTitle={t('orders:createOrder')}
        onNext={onBtnClicked}
        nextIcon={<PlusIcon />}
        disableNextBtn={!showCreateOrderBtn}
      >
        <Table
          columns={newColumns}
          dataSource={orders}
          searchPlaceholder={t('orders:searchOrder')}
          filterComponent={filterComponent}
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
          onFilterCleared={onFilterCleared}
          onFiltered={onFiltered}
          totalItems={totalOrders | 0}
          loading={isOrdersLoading}
          scroll={{ y: `calc(100vh - 35rem)`, x: 2000 }}
          showTotal={t('orders:totalOrder')}
          rowSelection={rowSelection}
          toolExtra={extraComponent}
          isDirty={
            !!queryString.startDate ||
            !!queryString.endDate ||
            (queryString.statuses && queryString.statuses.length > 0) ||
            !!queryString.storeId
          }
        />
      </BodyContent>
    </>
  );
};

export default Orders;
