import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import routeStrings from 'src/routes/routeStrings';
import { apiStrings, httpClient, setCookie } from 'src/services';
import { ConfirmContractForm, CommonResponse, ErrorCriteria } from 'src/types';
import { useToast } from '../ui';
import { useTranslation } from 'react-i18next';
import { REQUIRES_DATA_SHARING_KEY } from 'src/constants';

const useWriteContracts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toastError } = useToast();

  const { mutate: confirmContract, isLoading: isLoadingWriteContracts } =
    useMutation<CommonResponse, AxiosError<ErrorCriteria>, ConfirmContractForm>(
      {
        mutationFn: async (variables: ConfirmContractForm) => {
          try {
            const response = await httpClient.put(
              apiStrings.authen.contract,
              variables
            );
            return response.data.data;
          } catch (error) {
            throw error;
          }
        },

        onSuccess: async () => {
          setCookie(REQUIRES_DATA_SHARING_KEY, 'false', 10);
          navigate(routeStrings.order);
        },

        onError: async err => {
          toastError({
            message: err.response?.data?.message || t('error.systemError'),
          });
        },
      }
    );

  return {
    isLoadingWriteContracts,
    confirmContract,
  };
};

export default useWriteContracts;
