const apiStrings = {
  authen: {
    signIn: '/api/auth/login',
    resetPassword: '/api/auth/passwords',
    logOut: '/api/auth/logout',
    contract: '/api/contracts',
    token: '/api/tokens',
  },
  order: {
    createOrder: '/api/orders',
    orderDetail: '/api/orders/detail/',
    listOrder: '/api/orders',
    stores: '/api/stores',
    export: '/api/orders/export',
    cancelOrder: '/api/orders/cancel',
    sendSms: '/api/orders/notify/',
    status: '/api/orders/status',
    updateMerchantBill: '/api/orders/detail/',
    paymentInfo: '/api/orders/payment/info/',
    paymentConfirm: '/api/orders/payment/confirm',
  },
  cancelOrder: {
    reason: '/api/orders/detail/reasons',
  },
  address: '/api/address',
  paymentIntegration: {
    integration: 'api/integration',
    sendUrl: '/api/integration',
  },
  file: {
    uploadFile: '/api/orders/proof',
    submitFile: '/api/orders/proof/confirm',
    viewFile: '/api/orders/proof',
  },
};

export default apiStrings;
