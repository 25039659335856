import { Input, QRCode } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { useCountDown } from 'src/hooks';
import { diffInSeconds } from 'src/utils/helper';
import { PaymentCountDownProps } from './type';

const PaymentCountDown = ({ order, isLoading }: PaymentCountDownProps) => {
  const { t } = useTranslation();
  const numberCountDown = diffInSeconds(
    dayjs().toString(),
    order?.sessionPayment?.expiredDate || dayjs().toString()
  );
  const { days, hours, minutes, seconds } = useCountDown(numberCountDown);

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  if (numberCountDown <= 0) {
    return <></>;
  }

  return (
    <div className="d-flex flex-row justify-start items-start gap--24 background-gray-100 p--24">
      <QRCode
        value={order?.sessionPayment?.qrData || ''}
        status={isLoading ? 'loading' : 'active'}
        icon="/logo-short.svg"
        className="cursor-zoom-in"
      />
      <div className="d-flex flex-col justify-between items-start flex-auto">
        <div className="d-flex flex-col w--100 gap-y--12 mb--72">
          <p className="label label--sm">
            {t('orders:orderPayment.urlPayment')}
          </p>
          <div className="d-flex flex-row justify-between items-center gap--8">
            <Input
              className="flex-auto"
              value={order?.sessionPayment?.paymentUrl}
            />
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('paymentIntegration:copy')}
              onClickHandled={() =>
                copy(order?.sessionPayment?.paymentUrl || '')
              }
            />
          </div>
        </div>
        <div className="d-flex items-center gap--4 label label--md text-dust-red-600">
          <span className="order-details__countdown">{days}</span>
          <span>{' : '}</span>
          <span className="order-details__countdown">{hours}</span>
          <span>{' : '}</span>
          <span className="order-details__countdown">{minutes}</span>
          <span>{' : '}</span>
          <span className="order-details__countdown">{seconds}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentCountDown;
