import { LoadingOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Spin as AntSpin } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from 'src/components/Layout/Header';
import Sider from 'src/components/Layout/Sider';
import { Modal } from 'src/components/Modal';
import { ReduxState } from 'src/redux/store';

const { Content } = AntdLayout;

const UserLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, modal, textLoading } = useSelector(
    (state: ReduxState) => state.ui
  );

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/order');
    }
  }, [navigate, location]);

  return (
    <AntSpin
      spinning={loading}
      size="large"
      style={{ maxHeight: '100vh', position: 'fixed' }}
      indicator={
        <div className="d-flex flex-col justify-center items-center">
          <LoadingOutlined style={{ fontSize: 80 }} spin />
          <span className="label label--sm text-gray-900 mt--24 w-200">
            {textLoading}
          </span>
        </div>
      }
    >
      <AntdLayout
        style={{
          height: '100vh',
          overflowY: 'auto',
          overscrollBehavior: 'none',
        }}
      >
        <Sider collapsed={false} />

        <AntdLayout>
          <Header />
          <Content>
            <Outlet />
          </Content>
        </AntdLayout>
      </AntdLayout>

      <Modal
        show={modal.show}
        type={modal.type}
        title={modal.title}
        content={modal.content}
        closeIcon={modal.closeIcon}
        cancelLabel={modal.cancelLabel}
        actionLabel={modal.actionLabel}
        actionHandler={modal.actionHandler}
      />

      <ToastContainer autoClose={2000} theme="colored" />
    </AntSpin>
  );
};

export default UserLayout;
