import Button from 'src/components/Button';
import { ReactComponent as ArrowRotateLeftIcon } from 'src/assets/images/icons/arrow-rotate-left.svg';
import withIcon from 'src/hocs/withIcon';
import { COLOR_GRAY_900 } from 'src/constants';
import { ButtonColorType, ButtonType } from 'src/components/Button/type';

const CustomArrowRotateLeftIcon = withIcon(ArrowRotateLeftIcon, {
  width: 16,
  height: 16,
  color: COLOR_GRAY_900,
});

type ReloadProps = {
  onClick: () => void;
};

const Reload = ({ onClick }: ReloadProps) => (
  <Button
    type={ButtonType.Default}
    colorType={ButtonColorType.Gray}
    icon={<CustomArrowRotateLeftIcon />}
    onClickHandled={onClick}
  />
);

export default Reload;
