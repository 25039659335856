import {
  COLOR_GRAY_100,
  COLOR_GRAY_900,
  COLOR_PRIMARY_600,
  DATE_HOUR_MIN_FORMAT_WITH_DASH,
  LIMITED_UPLOAD_PROOF,
  roles,
} from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { ReactComponent as Empty } from 'src/assets/images/icons/empty.svg';
import { ReactComponent as Upload } from 'src/assets/images/icons/upload.svg';
import { ReactComponent as Download } from 'src/assets/images/icons/download-blue.svg';
import { ReactComponent as File } from 'src/assets/images/icons/file.svg';
import { ReactComponent as Link } from 'src/assets/images/icons/link.svg';
import { Dropdown, MenuProps, Button as AntdButton, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import ProofModal from './ProofModal';
import { ProofOfDeliveryProps } from './type';
import { useViewFile } from 'src/hooks';
import { ProofType, TransactionProofs } from 'src/types';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { camelCase } from 'lodash';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/store';

const EmptyIcon = withIcon(Empty, {
  width: 185,
  height: 115,
  color: COLOR_GRAY_100,
});
const UploadIcon = withIcon(Upload, {
  width: 20,
  height: 16,
  color: COLOR_GRAY_900,
});
const DownloadIcon = withIcon(Download, {
  width: 24,
  height: 24,
  color: COLOR_PRIMARY_600,
});
const FileIcon = withIcon(File, {
  width: 20,
  height: 20,
});
const LinkIcon = withIcon(Link, {
  width: 20,
  height: 20,
});

const ProofOfDelivery = ({ order, submitProof }: ProofOfDeliveryProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState<ProofType | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { viewFile } = useViewFile();
  const openModal = (option: ProofType) => {
    setType(option);
    setOpen(true);
  };
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const isShowUploadProof = (order?.proofs?.length || 0) < LIMITED_UPLOAD_PROOF;

  const showCreateProof = useMemo(() => {
    return (
      isShowUploadProof && tokenInfo?.permissions.includes(roles.createProofs)
    );
  }, [tokenInfo, roles, isShowUploadProof]);

  const listType = [
    { name: t('orders:proofOfDelivery.invoice'), key: ProofType.Invoice },
    { name: t('orders:proofOfDelivery.others'), key: ProofType.Others },
    { name: t('orders:proofOfDelivery.orderTrackingUrl'), key: ProofType.Url },
  ];

  const items: MenuProps['items'] = listType.map(item => ({
    label: <div onClick={() => openModal(item.key)}>{item.name}</div>,
    key: item.key,
  }));

  const isEmpty = order?.proofs.length === 0;

  const handleDownload = (data: TransactionProofs) => {
    viewFile({ fileId: data.fileId, orderId: order?.fundiinOrderId || '' });
  };

  const columns = [
    {
      title: t('orders:proof.type'),
      dataIndex: 'type',
      key: 'name',
      width: 150,
      render: (_: any, record: TransactionProofs) => (
        <p className="text-weight-400">
          {t(`orders:proofOfDelivery.${camelCase(record.type)}`)}
        </p>
      ),
    },
    {
      title: t('orders:proof.fileName'),
      dataIndex: 'fileName',
      key: 'fileName',
      render: (_: any, record: TransactionProofs) => (
        <>
          {record.url ? (
            <div className="d-flex flex-row items-center gap-x--12">
              <div>
                <LinkIcon />
              </div>
              <NavLink to={record.url || ''} target="_blank">
                {record.url}
              </NavLink>
            </div>
          ) : (
            <div className="d-flex flex-row items-center gap-x--12">
              <div>
                <FileIcon />
              </div>
              <p className="text-weight-400 text-primary-600">
                {`${record.fileName}`}
              </p>
            </div>
          )}
        </>
      ),
    },
    {
      title: t('orders:proof.time'),
      dataIndex: 'createdDate',
      key: 'time',
      width: 180,
      render: (_: any, record: TransactionProofs) => (
        <p className="text-weight-400">
          {dayjs(record?.createdDate).format(DATE_HOUR_MIN_FORMAT_WITH_DASH)}
        </p>
      ),
    },
    {
      title: t('orders:proof.createdBy'),
      dataIndex: 'createBy',
      key: 'createBy',
      width: 200,
    },
    {
      title: '',
      width: 20,
      render: (_: any, record: TransactionProofs) => (
        <>
          {!record.url && (
            <div
              className="cursor-pointer"
              onClick={() => handleDownload(record)}
            >
              <DownloadIcon />
            </div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 br--8 mt--24 mb--24">
        <div className="d-flex flex-row justify-between py--16 px--24">
          <p className="label label--lg">{t('orders:proofOfDelivery.title')}</p>
          {showCreateProof && (
            <Dropdown menu={{ items }} trigger={['click']}>
              <AntdButton onClick={e => e.preventDefault()}>
                <div className="d-flex flex-row items-center">
                  <UploadIcon className="mr--4" />
                  {t('orders:proofOfDelivery.uploadProof')}
                </div>
              </AntdButton>
            </Dropdown>
          )}
        </div>
        {isEmpty && <hr />}
        <div className="d-flex flex-col items-center">
          {isEmpty ? (
            <EmptyIcon className="my--24" />
          ) : (
            <>
              <Table
                dataSource={order?.proofs}
                columns={columns}
                pagination={false}
                rowKey={item => item.fileId}
                className="w--100"
              />
            </>
          )}
        </div>
      </div>
      {open && (
        <ProofModal
          open={open}
          closeModal={() => setOpen(false)}
          type={type || ProofType.Others}
          fundiinOrderId={order?.fundiinOrderId}
          submitProof={submitProof}
        />
      )}
    </>
  );
};

export default ProofOfDelivery;
