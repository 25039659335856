import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { apiStrings, httpClient } from 'src/services';
import useToast from '../ui/useToast';
import { ErrorCriteria, SmsForm } from 'src/types';
import { hideLoading } from 'src/redux/slices/uiSlice';
import { useDispatch } from 'react-redux';

const useSendSms = () => {
  const { t } = useTranslation();
  const { toastError } = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: sendSms, isLoading } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    SmsForm
  >({
    mutationFn: async (variables: SmsForm) => {
      try {
        const response = await httpClient.put(
          apiStrings.order.sendSms + variables.id
        );
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async (_, variables) => {
      queryClient.refetchQueries({
        queryKey: ['order-detail', variables.id],
        type: 'active',
      });
      dispatch(hideLoading());
    },

    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    sendSms,
    isSmsSending: isLoading,
  };
};

export default useSendSms;
