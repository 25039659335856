import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Order } from 'src/assets/images/icons/order-black.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/images/icons/arrow-left.svg';
import { COLOR_GRAY_700, COLOR_GRAY_900, roles } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { memo, useMemo, useState } from 'react';
import {
  CancelOrderForm,
  OrderDetail,
  OrderStatus,
  ProductOrder,
} from 'src/types';
import classNames from 'classnames';
import Tag from 'src/components/Tag';
import { ReduxState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { camelCase } from 'lodash';
import CancelOrder from './CancelOrder';

const ArrowLeftIcon = withIcon(ArrowLeft, {
  width: 30,
  height: 30,
  color: COLOR_GRAY_900,
});

const OrderIcon = withIcon(Order, {
  width: 15,
  height: 15,
  color: COLOR_GRAY_700,
});

interface HeaderProps {
  products?: ProductOrder[];
  isPayment?: boolean;
  id?: string;
  status: string;
  backToCreateOrder?: () => void;
  discountValue?: number;
  cancelOrder?: (value: CancelOrderForm) => void;
  isLoading: boolean;
  merchantBillId?: string;
  reason?: string;
  order?: OrderDetail;
}

const Header = ({
  products,
  isPayment = false,
  id,
  backToCreateOrder,
  status,
  discountValue = 0,
  cancelOrder,
  isLoading,
  merchantBillId,
  reason = '',
  order,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { id: idParams } = useParams();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const amount = useMemo(() => {
    return (
      products?.reduce((prev, curr) => {
        return prev + curr.price * curr.quantity;
      }, 0) || 0
    );
  }, [products]);

  const total = useMemo(() => {
    return amount - discountValue;
  }, [amount, discountValue]);

  const handleBack = () => {
    if (isPayment) {
      backToCreateOrder?.();
      return;
    }
    navigate(-1);
  };

  const hideButton = useMemo(() => {
    return (
      status?.toUpperCase() === OrderStatus.Expired ||
      status?.toUpperCase() === OrderStatus.Cancelled ||
      status?.toUpperCase() === OrderStatus.Rejected ||
      status?.toUpperCase() === OrderStatus.Pending ||
      (status?.toUpperCase() === OrderStatus.Success && !!reason)
    );
  }, [status, reason]);

  const showCancelButton = useMemo(() => {
    return (
      !(!isPayment && hideButton) &&
      !isLoading &&
      tokenInfo?.permissions.includes(roles.cancelOrders)
    );
  }, [isPayment, tokenInfo, hideButton, roles, isLoading]);

  const isSuccess = useMemo(() => {
    return status?.toUpperCase() === OrderStatus.Success;
  }, [status]);

  return (
    <header className="body-header-two">
      <Breadcrumb
        items={[
          {
            title: (
              <div className="d-flex flex-row justìy-start items-center">
                <OrderIcon className="mr--4" />
                <Link to={'/order'}>{t('common:menuString.order')}</Link>
              </div>
            ),
          },
          {
            title: (
              <p>
                {t('orders:orderDetail.title', {
                  id: merchantBillId || id || idParams,
                })}
              </p>
            ),
          },
        ]}
      />
      <div className="d-flex justify-between items-center w--100 mt--6">
        <div className="d-flex flex-row items-center justìy-start">
          <ArrowLeftIcon
            className="mr--12 cursor--pointer"
            onClick={handleBack}
          />
          <h3 className="heading heading--lg">
            {t('orders:orderDetail.title', {
              id: merchantBillId || id || idParams,
            })}
          </h3>
          <Tag
            label={t(`orders:orderStatus.${camelCase(status)}`)}
            customClassName={classNames('label label--xs ml--16', {
              'status-list-item--success':
                status?.toUpperCase() === OrderStatus.Success,
              'status-list-item--waiting':
                status?.toUpperCase() === OrderStatus.Waiting,
              'status-list-item--expired':
                status?.toUpperCase() === OrderStatus.Expired,
              'status-list-item--cancelled':
                status?.toUpperCase() === OrderStatus.Cancelled,
              'status-list-item--pending':
                status?.toUpperCase() === OrderStatus.Pending,
              'status-list-item--rejected':
                status?.toUpperCase() === OrderStatus.Rejected,
            })}
          />
        </div>
        <div className="d-flex justify-end items-center gap-x--12">
          {showCancelButton && (
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Red}
              title={t('orders:orderDetail.cancelOrder')}
              onClickHandled={() => setOpenCancelModal(true)}
            />
          )}
        </div>
      </div>
      {openCancelModal && (
        <CancelOrder
          open={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          isSuccess={isSuccess}
          total={order?.amount.value || total}
          cancelOrder={cancelOrder}
        />
      )}
    </header>
  );
};

export default memo(Header);
