import { memo, useRef, useState } from 'react';
import { CancelOrderProps } from './type';
import { CustomModal } from '../Modal';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { Formik, FormikProps } from 'formik';
import Select from '../Select';
import { TextArea } from '../Input';
import { CancelOrderForm } from 'src/types';
import { useCancelReasonQuery } from 'src/hooks';
import { numberFormatter } from 'src/utils/helper';
import { OTHER_REASON_ID } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const CancelOrder = ({
  open,
  cancelOrder,
  setOpenCancelModal,
  isSuccess,
  total,
}: CancelOrderProps) => {
  const { t } = useTranslation();
  const { id: idParams } = useParams();
  const formRef = useRef<FormikProps<CancelOrderForm>>(null);
  const [reason, setReason] = useState<string>('');

  const { cancelReasons, isCancelReasonsLoading } = useCancelReasonQuery();

  const handleCancelOrder = () => {
    formRef.current?.handleSubmit();
  };

  const submitFormCancel = (values: CancelOrderForm) => {
    setOpenCancelModal(false);
    cancelOrder?.({ ...values, reason: reason });
  };

  return (
    <CustomModal
      open={open}
      title={t('orders:orderDetail.cancelOrderTitle', { id: idParams })}
      footer={
        <div className="d-flex flex-row">
          <Button
            type={ButtonType.Primary}
            colorType={ButtonColorType.Red}
            title={t('orders:orderDetail.cancelOrder')}
            size="middle"
            className="label--md"
            onClickHandled={handleCancelOrder}
            danger
            disabled={!reason}
          />
        </div>
      }
      width={600}
      className="custom-modal"
      closeIcon
      onCancel={() => setOpenCancelModal(false)}
      destroyOnClose
    >
      <Formik
        innerRef={formRef}
        initialValues={{ reasonId: '', reason: '', other: '' }}
        onSubmit={submitFormCancel}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              {isSuccess && (
                <>
                  <div className="d-flex flex-col items-start">
                    <p className="text-gray-700 paragraph paragraph--sm">
                      {t('orders:orderDetail.refundTheAmount')}
                    </p>
                    <p>
                      {numberFormatter(total) + ' ' + t('common:currency.vnd')}
                    </p>
                  </div>
                  <hr className="my--12" />
                </>
              )}

              <div className="d-flex flex-col gap--16">
                <Select
                  name="reasonCancel"
                  label={t('orders:orderDetail.reason')}
                  placeholder={t('orders:orderDetail.selectReason')}
                  value={values.reasonId}
                  options={cancelReasons}
                  onValueChanged={(value: string) => {
                    setFieldValue('reasonId', value);
                    const text = cancelReasons.find(
                      e => e.value === value
                    )?.label;
                    setReason(text || '');
                  }}
                  loading={isCancelReasonsLoading}
                  showSearch
                  required
                />
                {values.reasonId === OTHER_REASON_ID.toString() && (
                  <TextArea
                    name={'other'}
                    placeholder={t('orders:orderDetail.otherPlaceHolder')}
                    value={values.other}
                    onValueChanged={(value: string) =>
                      setFieldValue('other', value)
                    }
                  />
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default memo(CancelOrder);
