import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Line } from 'src/assets/images/icons/time-line.svg';
import { ReactComponent as LineFirst } from 'src/assets/images/icons/time-line-first.svg';
import { DATE_FORMAT_UK, HOUR_MINUTE } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { TimeLineProps } from './type';

interface Time {
  date?: string;
  time?: string;
  description: string;
}

const TimeLineIcon = withIcon(Line, {
  width: 30,
  height: 68,
});

const TimeLineFirstIcon = withIcon(LineFirst, {
  width: 30,
  height: 68,
});

const TimeLine = ({ order }: TimeLineProps) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<Time[]>([]);

  useEffect(() => {
    const history: Time[] = [];

    if (!!order?.refundInfo?.refundDate) {
      history.push({
        date: order?.refundInfo?.refundDate.local().format(DATE_FORMAT_UK),
        time: order?.refundInfo?.refundDate.local().format(HOUR_MINUTE),
        description: t('orders:orderDetail.timeline.refund'),
      });
    }

    if (!!order?.refundInfo?.paymentDate) {
      history.push({
        date: order?.refundInfo?.paymentDate.local().format(DATE_FORMAT_UK),
        time: order?.refundInfo?.paymentDate.local().format(HOUR_MINUTE),
        description: t('orders:orderDetail.timeline.success'),
      });
    }

    setItems(history);
  }, [order]);

  return (
    <div className="d-flex flex-col justify-start items-start">
      {items.map((item, index, list) => (
        <div className="d-flex flex-row justify-start items-start" key={index}>
          <div className="d-flex flex-col justify-start items-end">
            <p className="paragraph paragraph--sm">{item.date}</p>
            <p className="paragraph paragraph--sm">{item.time}</p>
          </div>
          {index === list.length - 1 ? (
            <TimeLineFirstIcon className="mx--12" />
          ) : (
            <TimeLineIcon className="mx--12" />
          )}
          <p className="paragraph paragraph--sm">{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default TimeLine;
