import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { apiStrings, httpClient } from 'src/services';

import { ISelectItem } from 'src/types';

const useStatusQuery = (defaultOption?: ISelectItem) => {
  const { data, isLoading: isStatusLoading } = useQuery({
    queryKey: ['status'],
    queryFn: async () => {
      try {
        const response = await httpClient.get(apiStrings.order.status);
        return response.data ? response.data.data.orderInfo : [];
      } catch (error) {
        throw error;
      }
    },
    select: statuses => {
      const transformedStatuses: ISelectItem[] = sortBy(
        statuses.map((store: any) => ({
          value: store.status,
          label: store.name,
        })),
        'label'
      );

      if (defaultOption) {
        return [defaultOption, ...transformedStatuses];
      }

      return transformedStatuses;
    },
    staleTime: 0,
  });

  return {
    statuses: data || [],
    isStatusLoading,
  };
};

export default useStatusQuery;
