import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';
import { useToast } from 'src/hooks';
import { apiStrings, httpClient } from 'src/services';

import {
  DeliveryEnum,
  ErrorCriteria,
  OrderDetail,
  CreateOrderResponse,
  OrderStatus,
} from 'src/types';

dayjs.extend(utc);

const useOrderDetail = (id?: string, isPaymentPage?: boolean) => {
  const { toastError } = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data,
    isLoading,
    isSuccess,
    refetch: refetchOrderDetail,
  } = useQuery<any, AxiosError<ErrorCriteria>>({
    queryKey: ['order-detail', id, isPaymentPage],
    queryFn: async () => {
      try {
        dispatch(showLoading());
        const response = await httpClient.get(
          apiStrings.order.orderDetail + id
        );
        dispatch(hideLoading());
        return response.data.data;
      } catch (error) {
        toastError({
          message: t('error.systemError'),
        });
        dispatch(hideLoading());
      }
    },
    select: res => {
      if (isPaymentPage) {
        return {
          referenceId: res?.referenceId || '',
          paymentUrl: res?.sessionPayment?.paymentUrl || '',
          qrData: res?.sessionPayment?.qrData || '',
          expireTime: res.sessionPayment?.expiredDate
            ? dayjs.utc(res.sessionPayment?.expiredDate)
            : '',
          sendSms: res?.sendSms || false,
          totalAmount: res?.amount.value || 0,
          resultStatus: res?.status as OrderStatus,
          merchantBillId: res?.merchantBillId,
        } as CreateOrderResponse;
      }
      return {
        referenceId: res?.referenceId || '',
        merchantBillId: res.merchantBillId,
        fundiinOrderId: res.fundiinOrderId,
        createdDate: dayjs.utc(res?.createdDate || ''),
        updatedDate: dayjs.utc(res?.updatedDate || ''),
        expiredDate: dayjs.utc(res?.expiredDate || ''),
        paymentDate: dayjs.utc(res?.paymentDate || ''),
        sessionPayment: res.sessionPayment,
        status: res.status,
        amount: res.amount,
        customerInfo: {
          phoneNumber: res.customerInfo.phoneNumber,
          fullName: res.customerInfo.fullName,
          address: {
            province: {
              name: res?.shippingInfo?.city || '',
            },
            district: {
              name: res?.shippingInfo?.district || '',
            },
            ward: {
              name: res?.shippingInfo?.ward || '',
            },
            street: res?.shippingInfo?.street,
          },
          delivery: !!res.shippingInfo
            ? DeliveryEnum.Delivery
            : DeliveryEnum.PickUpAtStore,
        },
        storeInfo: {
          ...res.storeInfo,
          address: {
            province: {
              id: res?.storeInfo?.provinceId || '',
              name: res?.storeInfo?.provinceName || '',
            },
            district: {
              id: res?.storeInfo?.districtId || '',
              name: res?.storeInfo?.districtName || '',
            },
            ward: {
              id: res?.storeInfo?.wardId || '',
              name: res?.storeInfo?.wardName || '',
            },
            street: res?.storeInfo?.address,
          },
        },
        shippingInfo: res?.shippingInfo || '',
        sellerInfo: res.sellerInfo,
        items: res.items,
        proofs:
          res?.proofs !== undefined && res?.proofs.length > 0
            ? res?.proofs
            : [],
        refundInfo: res.refundInfo
          ? {
              paymentDate: res.refundInfo?.paymentDate
                ? dayjs.utc(res.refundInfo?.paymentDate)
                : null,
              refundDate: res.refundInfo?.refundDate
                ? dayjs.utc(res.refundInfo?.refundDate)
                : null,
            }
          : null,
        reason: res.reason,
        inStorePayment: res.inStorePayment,
      } as OrderDetail;
    },
    cacheTime: 0,
  });

  return { order: data, isLoading, refetchOrderDetail, isSuccess };
};

export default useOrderDetail;
