import { Breadcrumb } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Order } from 'src/assets/images/icons/order-gray.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { CustomModal } from 'src/components/Modal';
import { COLOR_GRAY_900 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { useCreateOrder } from 'src/hooks';
import { numberFormatter } from 'src/utils/helper';

import { HeaderProps } from 'src/components/CreateOrder/type';

const OrderIcon = withIcon(Order, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});

const Header = ({
  products,
  customer,
  staff,
  store,
  discountValue = 0,
  createOrder,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { open, handleOpenModal, handleCloseModal } = useCreateOrder();
  const navigate = useNavigate();

  const disabledCreate = useMemo(() => {
    return products.length === 0 || !customer?.phoneNumber || !store?.id;
  }, [customer, products, staff, store]);

  const amount = useMemo(() => {
    return products.reduce((prev, curr) => {
      return prev + curr.price * curr.quantity;
    }, 0);
  }, [products]);

  const total = useMemo(() => {
    return amount - discountValue;
  }, [amount, discountValue]);

  const cancelOrder = () => {
    navigate(-1);
  };

  const handleCreateOrder = () => {
    handleCloseModal();
    createOrder();
  };

  return (
    <>
      <div className="d-flex justify-start flex-col background-gray-100">
        <Breadcrumb
          className="px--24 pt--6 cursor--pointer my--12"
          items={[
            {
              title: (
                <div className="d-flex flex-row justìy-start items-center">
                  <OrderIcon className="mr--8" />
                  <Link to={'/order'}>{t('common:menuString.order')}</Link>
                </div>
              ),
            },
            {
              title: <p>{t('orders:createOrderPage.title')}</p>,
            },
          ]}
        />
        <div className="d-flex justify-between items-center px--24 mb--12">
          <h3 className="heading heading--lg">
            {t('orders:createOrderPage.title')}
          </h3>
          <div className="d-flex justify-end items-center">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('orders:createOrderPage.cancel')}
              rootClassName="mr--12"
              onClickHandled={cancelOrder}
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Gray}
              title={t('orders:createOrderPage.create')}
              onClickHandled={handleOpenModal}
              disabled={disabledCreate}
            />
          </div>
        </div>
      </div>
      <CustomModal
        open={open}
        title={t('orders:createOrderPage.title')}
        footer={
          <div className="d-flex flex-row">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('orders:createOrderPage.cancel')}
              size="large"
              rootClassName="mr--12"
              onClickHandled={handleCloseModal}
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.agree')}
              size="large"
              className="label--md"
              onClickHandled={handleCreateOrder}
              disabled={disabledCreate}
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={handleCloseModal}
      >
        <p className="paragraph paragraph----md">
          {t('orders:createOrderPage.confirmCreateOrder', {
            total: numberFormatter(+total.toFixed(0)),
          })}
        </p>
      </CustomModal>
    </>
  );
};

export default Header;
