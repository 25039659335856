import { Modal as AntdModal } from 'antd';

import { ReactComponent as CloseIcon } from 'src/assets/images/icons/xmark.svg';
import { COLOR_GRAY_600 } from 'src/constants';

import { ICustomModalProps } from './type';

const ConfirmationModal = ({
  open,
  title,
  children,
  footer,
  width,
  closeIcon = true,
  onCancel,
  className = '',
  maskClosable,
  destroyOnClose = false,
}: ICustomModalProps) => {
  return (
    <AntdModal
      open={open}
      title={
        <div className="confirm-modal__header">
          <h3 className="heading heading--xs">{title}</h3>

          {closeIcon && onCancel && (
            <>
              <CloseIcon
                width={24}
                height={24}
                fill={COLOR_GRAY_600}
                className="cursor--pointer"
                onClick={onCancel}
              />
            </>
          )}
        </div>
      }
      closeIcon={false}
      className={className}
      footer={
        !!footer ? <div className="custom-modal__footer">{footer}</div> : null
      }
      width={width}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </AntdModal>
  );
};

export default ConfirmationModal;
