import { LoadingOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Spin as AntSpin } from 'antd';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from 'src/components/Layout/Header';
import { ReduxState } from 'src/redux/store';

const { Content } = AntdLayout;

export interface TCLayoutProps extends PropsWithChildren {}

const TCLayout = ({ children }: TCLayoutProps) => {
  const { loading, textLoading } = useSelector((state: ReduxState) => state.ui);
  return (
    <AntSpin
      spinning={loading}
      size="large"
      style={{ maxHeight: '100vh', position: 'fixed' }}
      indicator={
        <div className="d-flex flex-col justify-center items-center">
          <LoadingOutlined style={{ fontSize: 80 }} spin />
          <span className="label label--sm text-gray-900 mt--24 w-200">
            {textLoading}
          </span>
        </div>
      }
    >
      <AntdLayout
        style={{
          height: '100vh',
          overflowY: 'auto',
          overscrollBehavior: 'none',
        }}
      >
        <AntdLayout>
          <Header />
          <Content>{children}</Content>
        </AntdLayout>
        <ToastContainer autoClose={2000} theme="colored" />
      </AntdLayout>
    </AntSpin>
  );
};

export default TCLayout;
