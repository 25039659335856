import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Banner,
  Customer,
  Header,
  Payment,
  Product,
  Store,
} from 'src/components/CreateOrder';
import { roles } from 'src/constants';
import { useCreateOrder } from 'src/hooks';
import { ReduxState } from 'src/redux/store';
import { routeStrings } from 'src/routes';

import {
  CreateOrderResponse,
  CreateOrderState,
  Customer as CustomerType,
  DeliveryEnum,
  Store as StoreType,
  StoreChannel,
} from 'src/types';

const initialState: CreateOrderState = {
  staff: '',
  store: {
    id: '',
    name: '',
    channel: StoreChannel.Online,
    website: '',
    address: {
      street: '',
      province: {
        id: '',
        name: '',
      },
      district: {
        id: '',
        name: '',
      },
      ward: {
        id: '',
        name: '',
      },
    },
    status: '',
  } as StoreType,
  customer: {
    phoneNumber: '',
    fullName: '',
    delivery: DeliveryEnum.PickUpAtStore,
    address: {
      street: '',
      province: {
        id: '',
        name: '',
      },
      district: {
        id: '',
        name: '',
      },
      ward: {
        id: '',
        name: '',
      },
    },
  } as CustomerType,
  products: [],
  discountValue: 0,
  total: 0,
};

const CreateOrder = () => {
  const { createOrder } = useCreateOrder();
  const navigate = useNavigate();
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const onSubmitOrder = (values: CreateOrderState) => {
    const amount = values.products.reduce((prev, curr) => {
      return prev + curr.price * curr.quantity;
    }, 0);

    const payload = {
      referenceId: values.referenceId,
      items: values.products.map(item => ({
        ...item,
        totalAmount: item.price * item.quantity,
      })),
      storeId: values.store.id,
      customer: {
        phoneNumber: values.customer.phoneNumber,
        lastName: values.customer.fullName,
      },
      amount: {
        value: amount - values.discountValue,
        discount: values.discountValue,
        currency: 'VND',
      },
    } as any;

    if (values.staff) {
      payload.seller = {
        phoneNumber: values.staff,
      };
    }

    if (values.customer.delivery === DeliveryEnum.Delivery) {
      payload.shipping = {
        street: values.customer.address?.street || '',
        city: values.customer.address?.province.name || '',
        district: values.customer.address?.district.name || '',
        ward: values.customer.address?.ward.name || '',
      };
    }

    createOrder(payload, {
      onSuccess: (data: CreateOrderResponse) => {
        navigate(
          routeStrings.orderPayment.replace(
            ':id',
            encodeURIComponent(data.referenceId)
          )
        );
      },
    });
  };

  return (
    <Formik initialValues={initialState} onSubmit={onSubmitOrder}>
      {({ values, setFieldValue, handleSubmit }) => (
        <div className="body">
          <Header
            products={values.products}
            customer={values.customer}
            store={values.store}
            staff={values.staff}
            discountValue={values.discountValue}
            referenceId={values.referenceId}
            createOrder={handleSubmit}
          />
          <div className="body__content">
            <Row gutter={24}>
              <Col flex={7} className="col-product">
                <Product
                  products={values.products}
                  setFieldValue={setFieldValue}
                  referenceId={values.referenceId}
                />
                <Payment
                  products={values.products}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col flex={3} className="col-info">
                <Customer
                  customer={values.customer}
                  setFieldValue={setFieldValue}
                />
                <Store
                  staff={values.staff}
                  store={values.store}
                  setFieldValue={setFieldValue}
                />
                {tokenInfo?.permissions.includes(roles.writeCommission) && (
                  <Banner />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateOrder;
