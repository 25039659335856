import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { ChangePasswordForm } from 'src/types/account';
import Button, { ButtonColorType, ButtonType } from '../Button';
import {
  COLOR_CALENDULA_GOLD_600,
  initialFormChangePassword,
} from 'src/constants';
import {
  validateConfirmPassword,
  validatePassword,
} from 'src/utils/validators/common';
import PasswordInput from '../Input/PasswordInput';
import { ReactComponent as Light } from 'src/assets/images/icons/light.svg';
import withIcon from 'src/hocs/withIcon';
import useResetPassword from 'src/hooks/auth/useResetPassword';
import { LoadingOutlined } from '@ant-design/icons';

const LightIcon = withIcon(Light, {
  width: 18,
  height: 18,
  color: COLOR_CALENDULA_GOLD_600,
});

const FormResetPassword = () => {
  const { t } = useTranslation();
  const { isLoadingResetPassword, resetPassword } = useResetPassword();

  const handleSubmit = (values: ChangePasswordForm) => {
    resetPassword(values);
  };

  return (
    <>
      <div className="signin__container">
        <h3 className="heading heading--lg mb--8">
          {t('changePassword:title')}
        </h3>
        <div className="d-flex justify-center items-center mb--28">
          <div className="mr--4">
            <LightIcon />
          </div>
          <p className="paragraph paragraph--sm text-gray-700">
            {t('changePassword:description')}
          </p>
        </div>

        <Formik
          initialValues={initialFormChangePassword}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isValid }) => (
            <Form>
              <PasswordInput
                name="password"
                value={values.password}
                label={t('changePassword:newPassword')}
                placeholder={t('changePassword:newPasswordPlaceHolder')}
                required
                validate={(value: string) =>
                  validatePassword(t, 'password', value)
                }
                onChange={e => setFieldValue('password', e.target.value)}
              />
              <PasswordInput
                name="confirmPassword"
                value={values.confirmPassword}
                label={t('changePassword:confirmPassword')}
                placeholder={t('changePassword:confirmPasswordPlaceHolder')}
                required
                validate={(value: string) =>
                  validateConfirmPassword(
                    t,
                    'confirmPassword',
                    values.password,
                    value
                  )
                }
                onChange={e => setFieldValue('confirmPassword', e.target.value)}
              />
              <Button
                type={ButtonType.Primary}
                colorType={ButtonColorType.Blue}
                title={t('changePassword:changePassword')}
                block
                size="large"
                isSubmit
                className="label--md"
                disabled={!isValid || values.password === ''}
                subIcon={isLoadingResetPassword && <LoadingOutlined />}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FormResetPassword;
