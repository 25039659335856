import { Checkbox } from 'antd';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import useWriteContracts from 'src/hooks/auth/useWriteContracts';

const TC = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [userHasReviewed, setUserHasReviewed] = useState(false);

  const { confirmContract } = useWriteContracts();

  const scrollTracker = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 150) {
        setUserHasReviewed(true);
      } else {
        setUserHasReviewed(false);
        setChecked(false);
      }
    }
  };

  const handleConfirm = () => {
    confirmContract({ isSharingData: true });
  };

  return (
    <div className="d-flex w--100 items-center justify-center mt--40">
      <div className="background-gray-100 container">
        <div className="d-flex flex-col justify-start items-center py--24 px--48">
          <h6 className="heading heading--lg mt--24 text-primary-600">
            {t('tc:title')}
          </h6>
          <h6 className="heading heading--lg mt--8 text-primary-600">
            {t('tc:title2')}
          </h6>
        </div>
        <div
          ref={divRef}
          onScroll={() => scrollTracker()}
          className="overflow-y-auto px--48 whitespace-pre-line text-gray-800"
          style={{ height: '500px' }}
        >
          <b>{t('tc:p1.d1')}</b>
          <br />
          <br />
          {t('tc:p1.d2')} <b>Fundiin</b>
          {t('tc:p1.d2.2')}
          <br />
          <br />
          {t('tc:p1.d3')}
          <br />
          <br />
          {t('tc:p1.d4')}
          <br />
          <br />
          {t('tc:p1.d5')}
          <br />
          <br />
          <br />
          <b>{t('tc:p2.d1')}</b>
          <br />
          <br />
          <div className="pl--24">
            <div className="d-flex gap-x--12">
              <p>2.1.</p>{' '}
              <p>
                {t('tc:p2.d2')}
                <a href="https://fundiin.vn/">FUNDIIN.VN</a>
                {t('tc:p2.d2.2')}
              </p>
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>2.2.</p> {t('tc:p2.d3')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>2.3.</p> {t('tc:p2.d4')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>2.4.</p> {t('tc:p2.d5')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>2.5.</p> {t('tc:p2.d6')}
            </div>
            <br />
          </div>
          <b> {t('tc:p3.d1')}</b>
          <br />
          <br />
          <div className="pl--24">
            <div className="d-flex gap-x--12">
              <p>3.1.</p> {t('tc:p3.d2')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>3.2.</p> {t('tc:p3.d3')}
            </div>
            <br />
            <div className="pl--24">
              <div className="d-flex gap-x--24">
                <p>(i)</p> {t('tc:p3.p3.d1')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>(ii)</p> {t('tc:p3.p3.d2')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>(iii)</p> {t('tc:p3.p3.d3')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>(iv)</p> {t('tc:p3.p3.d4')}
              </div>
              <br />
            </div>
            <div className="d-flex gap-x--12">
              <p>3.3.</p> {t('tc:p3.d4')}
            </div>
            <br />
            <div className="pl--24">
              <div className="d-flex gap-x--24">
                <p>(i)</p> {t('tc:p3.p4.d1')}
              </div>
              <br />
              <div className="pl--24">
                <div className="d-flex gap-x--24">
                  <p>-</p> {t('tc:p3.p4.p1.d1')}
                </div>
                <br />
              </div>
              <div className="d-flex gap-x--24">
                <p>(ii)</p> {t('tc:p3.p4.d2')}
              </div>
              <br />
              <div className="pl--24">
                <div className="d-flex gap-x--24">
                  <p>-</p> {t('tc:p3.p4.p2.d1')}
                </div>
                <br />
                <div className="d-flex gap-x--24">
                  <p>-</p> {t('tc:p3.p4.p2.d2')}
                </div>
                <br />
              </div>
              <div className="d-flex gap-x--24">
                <p>(iii)</p> {t('tc:p3.p4.d3')}
              </div>
              <br />
              <div className="pl--24">
                <div className="d-flex gap-x--24">
                  <p>-</p> {t('tc:p3.p4.p3.d1')}
                </div>
                <br />
              </div>
            </div>
            <div className="d-flex gap-x--12">
              <p>3.4.</p> {t('tc:p3.p5.d1')}
            </div>
            <br />
            <div className="pl--24">
              <div className="d-flex gap-x--12">
                <p>3.4.1.</p> {t('tc:p3.p5.p1.d1')}
              </div>
              <br />
              <div className="d-flex gap-x--12">
                <p>3.4.2.</p> {t('tc:p3.p5.p1.d2')}
              </div>
              <br />
            </div>
            <div className="d-flex gap-x--12">
              <p>3.5.</p> {t('tc:p3.p6.d1')}
            </div>

            <br />
            <div className="pl--24">
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d1')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d2')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d3')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d4')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d5')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d6')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d7')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d8')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d9')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d10')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d11')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d12')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d13')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d14')}
              </div>
              <br />
              <div className="d-flex gap-x--24">
                <p>-</p> {t('tc:p3.p6.p1.d15')}
              </div>
              <br />
            </div>
            <br />
          </div>
          <div className="pl--24">
            <div className="d-flex gap-x--12">
              <p>3.6.</p> {t('tc:p3.d5')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>3.7.</p> {t('tc:p3.d6')}
            </div>
            <br />
          </div>
          <b>{t('tc:p4.d1')}</b>
          <br />
          <br />
          <div className="pl--24">
            <div className="pl--24">{t('tc:p4.d2')}</div>
          </div>
          <br />
          <br />
          <b>{t('tc:p5.d1')}</b>
          <br />
          <br />
          <div className="pl--24">
            <div className="d-flex gap-x--12">
              <p>5.1.</p> {t('tc:p5.p1.d1')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>5.2.</p> {t('tc:p5.p1.d2')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>5.3.</p> {t('tc:p5.p1.d3')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>5.4.</p> {t('tc:p5.p1.d4')}
            </div>
            <br />
            <div className="d-flex gap-x--12">
              <p>5.5.</p> {t('tc:p5.p1.d5')}
            </div>
            <br />
          </div>
          <b>{t('tc:p6.d1')}</b>
          <br />
          <br />
          {t('tc:p6.d2')} <br />
          <br />
        </div>
        <div className="d-flex flex-row justify-between items-center py--24 px--48">
          <Checkbox
            checked={checked}
            onChange={e => setChecked(e.target.checked)}
            disabled={!userHasReviewed}
          >
            {t('tc:checkbox')}
          </Checkbox>
          <Button
            type={ButtonType.Primary}
            colorType={ButtonColorType.Blue}
            title={t('tc:confirm')}
            disabled={!checked}
            onClickHandled={handleConfirm}
          />
        </div>
      </div>
    </div>
  );
};

export default TC;
