import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { apiStrings, httpClient } from 'src/services';
import useToast from '../ui/useToast';
import { ErrorCriteria, PaymentConfirmForm } from 'src/types';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';

const useGetPaymentInfoConfirm = () => {
  const { toastError } = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    mutate: confirm,
    isSuccess,
    isLoading,
  } = useMutation<any, AxiosError<ErrorCriteria>, PaymentConfirmForm>({
    mutationFn: async (variables: PaymentConfirmForm) => {
      try {
        dispatch(showLoading());
        const response = await httpClient.post(
          apiStrings.order.paymentConfirm,
          variables
        );
        return {
          variables,
          data: response.data.data.referenceId,
        };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: res => {
      queryClient.refetchQueries({
        queryKey: [
          'order-detail',
          encodeURIComponent(res.variables.referenceId),
        ],
        type: 'active',
      });
      dispatch(hideLoading());
    },
    onError: err => {
      dispatch(hideLoading());
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    confirm,
    isSuccess,
    isLoading,
  };
};

export default useGetPaymentInfoConfirm;
