import type { MenuProps } from 'antd';
import { ConfigProvider, Layout as AntdLayout, Menu as AntdMenu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ReactComponent as LogoIcon } from 'src/assets/images/icons/logo.svg';
import { ReactComponent as ConfigBlack } from 'src/assets/images/icons/config-black.svg';
import { ReactComponent as OrderBlack } from 'src/assets/images/icons/order-black.svg';
import {
  COLOR_GRAY_900,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_600,
  MENU_STRINGS,
} from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import routeStrings from 'src/routes/routeStrings';
import { snackCaseToCamelCase } from 'src/utils/helper';

import { MenuItem, SiderProps } from 'src/components/Layout/Sider/Sider.d';

const { Sider: AntdSider, Header: AntdHeader } = AntdLayout;

const ConfigBlackIcon = withIcon(ConfigBlack, {
  width: 20,
  height: 20,
});

const OrderBlackIcon = withIcon(OrderBlack, {
  width: 20,
  height: 20,
});

const MENU_ITEMS: MenuItem[] = [
  {
    key: MENU_STRINGS.ORDER,
    path: routeStrings.order,
    subPath: [
      routeStrings.createOrder,
      routeStrings.orderDetail,
      routeStrings.orderPayment,
    ],
    icon: <OrderBlackIcon />,
  },
  {
    key: MENU_STRINGS.PAYMENT_INTEGRATION,
    path: routeStrings.paymentIntegration,
    icon: <ConfigBlackIcon />,
  },
];

const Sider = ({ collapsed }: SiderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string>('');

  useEffect(() => {
    if (!!location.pathname) {
      const target = MENU_ITEMS.find((item: MenuItem) => {
        const targetSubPath = item.subPath?.find(subPath => {
          const locationPathname = location.pathname.split('/').slice(1);
          const subPathname = subPath?.split('/').slice(1);

          return subPathname?.every(pathname => {
            if (pathname.startsWith(':')) return true;
            return locationPathname.includes(pathname);
          });
        });

        return item.path === location.pathname || !!targetSubPath;
      });
      setSelectedItem(target?.key || '');
    }
  }, [location.pathname]);

  const onItemSelected: MenuProps['onClick'] = ({ key }) => {
    setSelectedItem(key);

    navigate(key);
  };

  const items: any = useMemo(() => {
    return MENU_ITEMS.map(item => {
      return {
        key: item.key,
        icon: item.icon,
        label: t(`menuString.${snackCaseToCamelCase(item.key)}`),
      };
    }).filter(item => !!item);
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemSelectedBg: COLOR_PRIMARY_300,
            colorText: COLOR_GRAY_900,
            itemSelectedColor: COLOR_PRIMARY_600,
          },
        },
      }}
    >
      <AntdSider
        trigger={null}
        className="sider"
        collapsed={collapsed}
        collapsible
        collapsedWidth={56}
        theme="light"
      >
        <AntdHeader className="sider__logo">
          <LogoIcon />
        </AntdHeader>

        <AntdMenu
          mode="inline"
          onClick={onItemSelected}
          selectedKeys={[selectedItem]}
          items={items}
        />
      </AntdSider>
    </ConfigProvider>
  );
};

export default Sider;
