import { RadioChangeEvent } from 'antd';
import { ReactNode } from 'react';

export enum RadioOptionType {
  Default = 'default',
  Button = 'button',
}

export enum RadioButtonStyle {
  Outline = 'outline',
  Solid = 'solid',
}

export interface IRadioOption {
  label: string | ReactNode;
  value: string;
  disable?: boolean;
}

export interface IRadioProps {
  label?: string | ReactNode;
  options: IRadioOption[];
  value: string;
  onValueChanged: (event: RadioChangeEvent) => void;
  optionType?: RadioOptionType;
  buttonStyle?: RadioButtonStyle;
  rootClassName?: string;
  className?: string;
  vertical?: boolean;
}
