const routeStrings = {
  signIn: '/sign-in',
  resetPassword: '/reset-password',
  order: '/order',
  orderPayment: '/order-payment/:id',
  createOrder: '/order/create',
  orderDetail: '/order/:id',
  paymentIntegration: '/payment-integration',
  tc: '/tc',
};

export default routeStrings;
