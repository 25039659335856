import { createSlice } from '@reduxjs/toolkit';
import { Auth, TokenInfo } from 'src/types/account';

interface AuthState extends Auth {
  isAuthenticated: boolean;
  tokenInfo?: TokenInfo;
}

const initialState: AuthState = {
  isAuthenticated: false,
  requiresDataSharing: false,
  temporaryPassword: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state, action: { payload: Auth }) => {
      state.isAuthenticated = true;
      state.jwtResponse = action.payload.jwtResponse;
      state.temporaryPassword = action.payload.temporaryPassword;
      state.requiresDataSharing = action.payload.requiresDataSharing;
    },
    setAuthenticated: state => {
      state.isAuthenticated = true;
    },
    logOut: state => {
      state.isAuthenticated = false;
      state.jwtResponse = undefined;
      state.temporaryPassword = false;
      state.requiresDataSharing = false;
    },
    changedPassword: state => {
      state.temporaryPassword = false;
    },
    setTokenInfo: (state, action: { payload: TokenInfo }) => {
      state.tokenInfo = action.payload;
    },
  },
});

export const {
  logIn,
  setAuthenticated,
  logOut,
  changedPassword,
  setTokenInfo,
} = authSlice.actions;
export default authSlice.reducer;
