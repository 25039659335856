import { ReactNode, SyntheticEvent } from 'react';

export enum ButtonColorType {
  Gray = 'gray',
  Blue = 'blue',
  Red = 'red',
  White = 'white',
}

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Text = 'text',
  Link = 'link',
}

export interface IButtonProps {
  type: ButtonType;
  colorType: ButtonColorType;
  size?: 'small' | 'middle' | 'large';
  title?: string | ReactNode;
  className?: string;
  onClickHandled?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  icon?: ReactNode;
  subIcon?: ReactNode;
  isSubmit?: boolean;
  danger?: boolean;
  block?: boolean;
  rootClassName?: string;
}
