import { DatePicker as AntdDatePicker } from 'antd';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

import { DATE_FORMAT } from 'src/constants';

import { IRangePickerProps } from 'src/components/DateTimePicker/index.d';

const { RangePicker: AntdRangePicker } = AntdDatePicker;

const DateRange = ({
  label,
  placeholder,
  value,
  onChange,
  disabledDate,
  showTime = false,
  allowClear = false,
  format = DATE_FORMAT,
}: IRangePickerProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  return (
    <div className="d-flex flex-col">
      {label && <label className="label label--md mb--8">{label}</label>}

      <AntdRangePicker
        placeholder={placeholder}
        showTime={showTime}
        value={value}
        onCalendarChange={values => {
          setStartDate(values?.[0] || null);
          setEndDate(values?.[1] || null);
        }}
        onChange={onChange}
        format={[format, format]}
        allowClear={allowClear}
        disabledDate={current => {
          if (disabledDate) {
            return disabledDate(current, startDate, endDate);
          }
          return false;
        }}
        getPopupContainer={node => node}
      />
    </div>
  );
};
export default DateRange;
