import { Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowUpBlack } from 'src/assets/images/icons/arrow-up-black.svg';
import { ReactComponent as ArrowUpBlue } from 'src/assets/images/icons/arrow-up-blue.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { PaymentIntegrationItemProps } from 'src/components/PaymentIntegration/type';
import { useSendUrl } from 'src/hooks';
import withIcon from 'src/hocs/withIcon';

import { Configs, EnvPaymentIntegration } from 'src/types';
import { Input } from '../Input';
import { validateUrl } from '../../utils/validators/common';
import { Form, Formik } from 'formik';

const ArrowUpBlackIcon = withIcon(ArrowUpBlack, {
  width: 16,
  height: 16,
});
const ArrowUpBlueIcon = withIcon(ArrowUpBlue, {
  width: 16,
  height: 16,
});

const PaymentIntegrationItem = ({ data, env }: PaymentIntegrationItemProps) => {
  const { t } = useTranslation(['common', 'paymentIntegration']);
  const { sendUrl, isLoadingSendUrl } = useSendUrl();

  const saveNotification = (values: any) => {
    sendUrl({
      ipnUrl: values.ipnUrl,
      env,
    } as unknown as Configs);
  };

  const goToPageVersion2 = () => {
    window.open((process.env.REACT_APP_DOC_URL || '').toString(), '_blank');
  };

  const goToPageGateway = () => {
    window.open((process.env.REACT_APP_GATEWAY_URL || '').toString(), '_blank');
  };

  const copy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <div className="d-flex flex-col gap--24">
        <p className="text-gray-700">{t('paymentIntegration:description')}</p>
        <div className="d-flex flex-col justify-start background-gray-100 br--8">
          <div className="d-flex flex-row justify-between py--16 px--24">
            <p className="label label--lg">
              {t('paymentIntegration:information', {
                env:
                  env === EnvPaymentIntegration.Sandbox
                    ? 'Sandbox'
                    : env.toLowerCase(),
              })}
            </p>
          </div>
          <hr />
          <div className="d-flex flex-col justify-start gap--24 p--24 w--50">
            <div className="d-flex flex-col">
              <label className="label label--md mb--8">
                {t('paymentIntegration:clientID')}
              </label>
              <div className="d-flex flex-row gap--8">
                <AntdInput type="" value={data?.clientId || ''} />
                <Button
                  type={ButtonType.Default}
                  colorType={ButtonColorType.Gray}
                  title={t('paymentIntegration:copy')}
                  onClickHandled={() => copy(data?.clientId || '')}
                />
              </div>
            </div>
            <div className="d-flex flex-col">
              <label className="label label--md mb--8">
                {t('paymentIntegration:merchantID')}
              </label>
              <div className="d-flex flex-row gap--8">
                <AntdInput type="" value={data?.merchantId || ''} />
                <Button
                  type={ButtonType.Default}
                  colorType={ButtonColorType.Gray}
                  title={t('paymentIntegration:copy')}
                  onClickHandled={() => copy(data?.merchantId || '')}
                />
              </div>
            </div>
            <div className="d-flex flex-col">
              <label className="label label--md mb--8">
                {t('paymentIntegration:secretKey')}
              </label>
              <div className="d-flex flex-row gap--8">
                <AntdInput.Password
                  type="password"
                  value={data?.secretKey || ''}
                />
                <Button
                  type={ButtonType.Default}
                  colorType={ButtonColorType.Gray}
                  title={t('paymentIntegration:copy')}
                  onClickHandled={() => copy(data?.secretKey || '')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-col justify-start background-gray-100 br--8">
          <div className="d-flex flex-row justify-between py--16 px--24">
            <p className="label label--lg">
              {t('paymentIntegration:webMobile')}
            </p>
          </div>
          <hr />
          <div className="p--24 w--50">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('paymentIntegration:documentation')}
              onClickHandled={goToPageVersion2}
              subIcon={<ArrowUpBlackIcon />}
            />
            <div className="label label--lg mt--24">
              {t('paymentIntegration:apiEndPoint')}
            </div>
            <div className="d-flex flex-row">
              <Button
                type={ButtonType.Link}
                colorType={ButtonColorType.Blue}
                title={(process.env.REACT_APP_GATEWAY_URL || '').toString()}
                onClickHandled={goToPageGateway}
                subIcon={<ArrowUpBlueIcon />}
                className="-ml--16"
              />
              <Button
                type={ButtonType.Default}
                colorType={ButtonColorType.Gray}
                title={t('paymentIntegration:copy')}
                onClickHandled={() =>
                  copy(process.env.REACT_APP_GATEWAY_URL || '')
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-col justify-start background-gray-100 br--8">
          <div className="d-flex flex-row justify-between py--16 px--24">
            <p className="label label--lg">{t('paymentIntegration:config')}</p>
          </div>
          <hr />
          <div className="p--24 w--50">
            <div className="d-flex flex-col">
              <label className="label label--md mb--8">
                {t('paymentIntegration:notification')}
              </label>
              <Formik
                initialValues={{ ipnUrl: data?.configs?.ipnUrl || '' }}
                onSubmit={saveNotification}
              >
                {({ values, setFieldValue, isValid }) => (
                  <Form>
                    <div className="d-flex flex-row gap--8">
                      <Input
                        type="text"
                        value={values.ipnUrl}
                        placeholder={t(
                          'paymentIntegration:notificationPlaceHolder'
                        )}
                        onChange={e => setFieldValue('ipnUrl', e.target.value)}
                        validate={(value: string) =>
                          validateUrl(t, 'ipnUrl', value)
                        }
                        name="ipnUrl"
                        rootClassName="flex-auto"
                      />
                      <Button
                        type={ButtonType.Primary}
                        colorType={ButtonColorType.Blue}
                        title={t('paymentIntegration:save')}
                        isSubmit
                        disabled={isLoadingSendUrl || !isValid}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentIntegrationItem;
