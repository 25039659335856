import { Outlet, RouteObject, useRoutes } from 'react-router-dom';

import ResetPassword from 'src/pages/ResetPassword';
import SignIn from 'src/pages/SignIn';
import routeStrings from 'src/routes/routeStrings';
import ProtectedRoute from './ProtectedRoute';
import Orders from 'src/pages/Orders';
import UserLayout from 'src/components/Layout/UserLayout';
import PaymentIntegration from 'src/pages/PaymentIntegration';
import CreateOrder from 'src/pages/CreateOrder';
import OrderDetail from 'src/pages/OrderDetail';
import TCLayout from 'src/components/Layout/TCLayout';
import TC from 'src/pages/T&C';
import PermissionDenied from 'src/pages/PermissionDenied';
import RoleRoute from './RoleRoute';
import { roles } from 'src/constants';
import OrderPayment from 'src/pages/OrderPayment';

const routes: RouteObject[] = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <UserLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: routeStrings.order,
            element: (
              <RoleRoute role={roles.readOrders}>
                <Orders />
              </RoleRoute>
            ),
          },
          {
            path: routeStrings.createOrder,
            element: (
              <RoleRoute role={roles.createOrders}>
                <CreateOrder />
              </RoleRoute>
            ),
          },
          {
            path: routeStrings.orderPayment,
            element: (
              <RoleRoute role={roles.createOrders}>
                <OrderPayment />
              </RoleRoute>
            ),
          },
          {
            path: routeStrings.orderDetail,
            element: (
              <RoleRoute role={roles.readOrders}>
                <OrderDetail />
              </RoleRoute>
            ),
          },
          {
            path: routeStrings.paymentIntegration,
            element: (
              <RoleRoute role={roles.writePaymentProfiles}>
                <PaymentIntegration />
              </RoleRoute>
            ),
          },
        ],
      },
      {
        path: routeStrings.tc,
        element: (
          <ProtectedRoute>
            <RoleRoute role={roles.writeContracts}>
              <TCLayout>
                <TC />
              </TCLayout>
            </RoleRoute>
          </ProtectedRoute>
        ),
      },

      { path: routeStrings.signIn, element: <SignIn /> },
      {
        path: routeStrings.resetPassword,
        element: (
          <ProtectedRoute>
            <ResetPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <PermissionDenied />,
      },
    ],
  },
];

const Routes = () => {
  const element = useRoutes(routes);
  return <>{element}</>;
};

export default Routes;
