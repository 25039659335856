import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiStrings, httpClient, setCookie } from 'src/services';
import { ChangePasswordForm } from 'src/types/account';
import { CommonResponse, ErrorCriteria } from 'src/types';
import { TEMPORARY_PASSWORD_KEY } from 'src/constants';
import { useDispatch } from 'react-redux';
import { changedPassword } from 'src/redux/slices/authSlice';
import { useToast } from '../ui';
import { useTranslation } from 'react-i18next';

const useResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { toastError } = useToast();

  const {
    mutate: resetPassword,
    isSuccess,
    isLoading: isLoadingResetPassword,
  } = useMutation<
    CommonResponse,
    AxiosError<ErrorCriteria>,
    ChangePasswordForm
  >({
    mutationFn: async (variables: ChangePasswordForm) => {
      try {
        const response = await httpClient.put(apiStrings.authen.resetPassword, {
          password: variables.password,
        });
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async () => {
      dispatch(changedPassword());
      setCookie(TEMPORARY_PASSWORD_KEY, 'false', 10);
    },

    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    isLoadingResetPassword,
    resetPassword,
    isSuccess,
  };
};

export default useResetPassword;
