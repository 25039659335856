import { useTranslation } from 'react-i18next';
import { Input } from '../Input';
import { Formik, Form } from 'formik';
import useSignIn from 'src/hooks/auth/useSignIn';
import { AccountForm } from 'src/types/account';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { ReactComponent as ArrowRight } from 'src/assets/images/icons/arrow-right.svg';
import withIcon from 'src/hocs/withIcon';
import { COLOR_GRAY_100, initialAccount } from 'src/constants';
import { LoadingOutlined } from '@ant-design/icons';
import { validateRequired } from 'src/utils/validators/common';
import PasswordInput from '../Input/PasswordInput';

const ArrowRightIcon = withIcon(ArrowRight, {
  width: 18,
  height: 18,
  color: COLOR_GRAY_100,
});

const FormSignIn = () => {
  const { t } = useTranslation();
  const { isLoadingSignIn, signIn } = useSignIn();

  const onSubmit = (values: AccountForm) => {
    signIn(values);
  };

  return (
    <div className="signin__container">
      <h3 className="heading heading--xxl mb--48">{t('signIn:title')}</h3>
      <Formik initialValues={initialAccount} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Input
              name="username"
              value={values.username}
              type="text"
              label={t('signIn:username')}
              placeholder={t('signIn:usernamePlaceHolder')}
              required
              validate={(value: string) =>
                validateRequired(t, 'username', value)
              }
              onChange={e => setFieldValue('username', e.target.value)}
            />
            <PasswordInput
              name="password"
              value={values.password}
              label={t('signIn:password')}
              placeholder={t('signIn:passwordPlaceHolder')}
              required
              validate={(value: string) =>
                validateRequired(t, 'password', value)
              }
              onChange={e => setFieldValue('password', e.target.value)}
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('signIn:signIn')}
              subIcon={
                isLoadingSignIn ? <LoadingOutlined /> : <ArrowRightIcon />
              }
              block
              size="large"
              isSubmit
              className="label--md"
              disabled={isLoadingSignIn}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormSignIn;
