import { ProductOrder as ProductOrderType } from 'src/types';
import { ReactComponent as Remove } from 'src/assets/images/icons/remove.svg';
import { ReactComponent as Edit } from 'src/assets/images/icons/edit-blue.svg';
import { ReactComponent as Plus } from 'src/assets/images/icons/plus-black.svg';
import { ReactComponent as Minus } from 'src/assets/images/icons/minus-black.svg';
import withIcon from 'src/hocs/withIcon';
import {
  COLOR_DUST_RED_600,
  COLOR_GRAY_900,
  COLOR_PRIMARY_600,
} from 'src/constants';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { InputNumber } from 'antd';
import { numberFormatter } from 'src/utils/helper';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps } from 'formik';
import { FormEvent } from 'react';

const RemoveIcon = withIcon(Remove, {
  width: 24,
  height: 24,
  color: COLOR_DUST_RED_600,
});
const EditIcon = withIcon(Edit, {
  width: 24,
  height: 24,
  color: COLOR_PRIMARY_600,
});
const PlusIcon = withIcon(Plus, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});
const MinusIcon = withIcon(Minus, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_900,
});

interface ProductItemProps {
  product: ProductOrderType;
  onEdit: () => void;
  isDetail?: boolean;
  arrayHelpers: FieldArrayRenderProps;
  indexItem: number;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

const ProductItem = ({
  product,
  onEdit,
  isDetail = false,
  arrayHelpers,
  indexItem,
  handleSubmit,
}: ProductItemProps) => {
  const { t } = useTranslation();

  const handleReduce = () => {
    arrayHelpers.replace(indexItem, {
      ...product,
      quantity: product.quantity - 1,
    });
    handleSubmit();
  };
  const handleIncrease = () => {
    arrayHelpers.replace(indexItem, {
      ...product,
      quantity: product.quantity + 1,
    });
    handleSubmit();
  };
  const handleChangeQuantity = (value: number | null) => {
    arrayHelpers.replace(indexItem, { ...product, quantity: value || 1 });
    handleSubmit();
  };

  const handleRemove = () => {
    arrayHelpers.remove(indexItem);
    handleSubmit();
  };

  return (
    <tr className="table-body-item">
      <td className="text-left col-name">
        <span>{product.productName}</span>
      </td>
      <td className="text-right col-price">
        {isDetail ? (
          <span> {numberFormatter(product.price)} </span>
        ) : (
          <InputNumber
            value={product.price}
            suffix={'₫'}
            onChange={value => {
              arrayHelpers.replace(indexItem, {
                ...product,
                price: value || 0,
              });
              handleSubmit();
            }}
            min={0}
            formatter={numberFormatter}
            className="w--100"
          />
        )}
      </td>
      <td className="text-right col-quantity">
        {isDetail ? (
          <span> {numberFormatter(product.quantity)} </span>
        ) : (
          <div className="d-flex justify-end gap--4">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              icon={<MinusIcon />}
              rootClassName="quantity-button"
              disabled={product.quantity <= 1}
              onClickHandled={handleReduce}
            />
            <InputNumber
              value={product.quantity}
              onChange={handleChangeQuantity}
              min={0}
              formatter={numberFormatter}
              className="quantity-input"
            />
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              icon={<PlusIcon />}
              rootClassName="quantity-button"
              onClickHandled={handleIncrease}
            />
          </div>
        )}
      </td>
      <td className="text-right col-amount">
        {numberFormatter(+(product.quantity * product.price).toFixed(0)) +
          ' ' +
          t('common:currency.vnd')}
      </td>
      {!isDetail && (
        <td className="col-action">
          <div className="d-flex items-center justify-center">
            <Button
              type={ButtonType.Text}
              colorType={ButtonColorType.White}
              icon={<EditIcon />}
              onClickHandled={onEdit}
              rootClassName="p--4"
            />
            <Button
              type={ButtonType.Text}
              colorType={ButtonColorType.White}
              icon={<RemoveIcon />}
              onClickHandled={handleRemove}
              rootClassName="p--4"
            />
          </div>
        </td>
      )}
    </tr>
  );
};

export default ProductItem;
