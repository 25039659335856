import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { logIn, setTokenInfo } from 'src/redux/slices/authSlice';
import routeStrings from 'src/routes/routeStrings';
import { apiStrings, httpClientPublic } from 'src/services';
import { AccountForm, Auth, TokenInfo } from 'src/types/account';
import { useDispatch } from 'react-redux';
import useAuth from './useAuth';
import { useToast } from '../ui';
import { useTranslation } from 'react-i18next';
import { CommonResponse, ErrorCriteria } from 'src/types';
import { roles } from 'src/constants';
import { countDaysBetween } from 'src/utils/helper';
import dayjs from 'dayjs';

const useSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toastError } = useToast();
  const { t } = useTranslation('common');

  const { saveCookieToken, saveTokenInfo } = useAuth();

  const {
    mutate: signIn,
    isSuccess,
    isLoading: isLoadingSignIn,
  } = useMutation<Auth, AxiosError<ErrorCriteria>, AccountForm>({
    mutationFn: async (variables: AccountForm) => {
      try {
        const response = await httpClientPublic.post(
          apiStrings.authen.signIn,
          variables
        );
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async (data: Auth) => {
      if (!data.jwtResponse) return;
      const days = countDaysBetween(data.jwtResponse?.expiredIn, dayjs());
      saveCookieToken(data, days);
      dispatch(logIn(data));
      const response = await httpClientPublic.get<CommonResponse<TokenInfo>>(
        apiStrings.authen.token,
        {
          headers: {
            Authorization: `${data?.jwtResponse?.tokenType} ${data?.jwtResponse?.accessToken}`,
          },
        }
      );

      const tokenInfo = response.data.data;

      if (!tokenInfo) return;
      saveTokenInfo(tokenInfo, days);
      dispatch(setTokenInfo(tokenInfo));

      if (window.oscWidget && window.oscWidget.user) {
        window.oscWidget.user.setName(tokenInfo.username);
        window.oscWidget.user.setPhone(tokenInfo.storeId);
        window.oscWidget.user.setEmail(tokenInfo.username);
        window.oscWidget.user.setProperties({
          plan: 'Estate',
          status: 'Active',
        });
      }

      if (data.temporaryPassword) {
        navigate(routeStrings.resetPassword);
      } else if (
        tokenInfo.permissions.includes(roles.writeContracts) &&
        data.requiresDataSharing
      ) {
        navigate(routeStrings.tc);
      } else {
        navigate(routeStrings.order);
      }
    },

    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    isLoadingSignIn,
    signIn,
    isSuccess,
  };
};

export default useSignIn;
