import { useQuery } from '@tanstack/react-query';

import { apiStrings, httpClient } from 'src/services';

import { ISelectItem } from 'src/types';

const useCancelReasonQuery = (defaultOption?: ISelectItem) => {
  const { data, isLoading: isCancelReasonsLoading } = useQuery({
    queryKey: ['cancelReasons'],
    queryFn: async () => {
      try {
        const response = await httpClient.get(apiStrings.cancelOrder.reason);
        return response.data ? response.data.data.reasons : [];
      } catch (error) {
        throw error;
      }
    },
    select: cancelReasons => {
      const transformedCancelReasons: ISelectItem[] = cancelReasons.map(
        (store: any) => ({
          value: store.id.toString(),
          label: store.name,
        })
      );

      if (defaultOption) {
        return [defaultOption, ...transformedCancelReasons];
      }

      return transformedCancelReasons;
    },
    staleTime: 0,
  });

  return {
    cancelReasons: data || [],
    isCancelReasonsLoading,
  };
};

export default useCancelReasonQuery;
