import { Radio as AntdRadio } from 'antd';
import classNames from 'classnames';

import {
  IRadioProps,
  RadioButtonStyle,
  RadioOptionType,
} from 'src/components/RadioGroup/RadioGroup.d';

const { Group: AntdGroup } = AntdRadio;

const RadioGroup = ({
  label,
  options,
  value,
  onValueChanged,
  optionType = RadioOptionType.Default,
  buttonStyle = RadioButtonStyle.Solid,
  rootClassName = '',
  className = '',
  vertical = false,
}: IRadioProps) => {
  return (
    <div
      className={classNames({
        [rootClassName]: !!rootClassName,
      })}
    >
      <label className="label label--md d-flex mb--8">{label}</label>

      <AntdGroup
        className={classNames({
          radio: optionType === RadioOptionType.Default,
          [className]: !!className,
        })}
        value={value}
        onChange={onValueChanged}
        optionType={optionType}
        buttonStyle={buttonStyle}
      >
        <div
          className={classNames({
            'd-flex': true,
            'flex-col gap-y--12': vertical,
            'flex-row gap-x--12': !vertical,
          })}
        >
          {options.map(option => (
            <AntdRadio
              value={option.value}
              key={option.value}
              className="mb--4"
            >
              <span className="paragraph paragraph--sm">{option.label}</span>
            </AntdRadio>
          ))}
        </div>
      </AntdGroup>
    </div>
  );
};

export default RadioGroup;
