import React from 'react';

interface IIconHOCProps {
  width?: number;
  height?: number;
  color?: string;
}

const withIcon = (
  Component: React.ComponentType<any>,
  props?: IIconHOCProps
) => {
  const ComponentWithIcon = (hocProps: any) => {
    return (
      <Component
        {...hocProps}
        fill={props?.color || 'currentColor'}
        width={props?.width || 24}
        height={props?.height || 24}
      />
    );
  };

  return ComponentWithIcon;
};
export default withIcon;
