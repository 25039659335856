import { useSelector } from 'react-redux';
import FormChangePassword from 'src/components/FormResetPassword';
import ResetPasswordSuccess from 'src/components/FormResetPassword/ResetPasswordSuccess';
import PublishLayout from 'src/components/Layout/PublishLayout';
import { ReduxState } from 'src/redux/store';

const ResetPassword = () => {
  const { temporaryPassword } = useSelector((state: ReduxState) => state.auth);

  return (
    <PublishLayout>
      {temporaryPassword ? <FormChangePassword /> : <ResetPasswordSuccess />}
    </PublishLayout>
  );
};

export default ResetPassword;
