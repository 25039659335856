import { toast } from 'react-toastify';

import { ReactComponent as XMarkIcon } from 'src/assets/images/icons/circle-xmark-solid.svg';
import { ReactComponent as VMarkIcon } from 'src/assets/images/icons/circle-check-solid.svg';
import withIcon from 'src/hocs/withIcon';

export interface IToastProps {
  message: string;
  id?: string;
}

export enum ToastId {
  Success = 'SUCCESS',
  BadRequest = 'BAD_REQUEST',
  Failed = 'FAIL',
  Unauthorized = 'UNAUTHORIZED',
  TimeOut = 'TIME_OUT',
  Exist = 'EXIST',
  SystemError = 'SYSTEM_ERROR',
  NotFound = 'NOT_FOUND',
  BusinessError = 'BUSINESS_ERROR',
}

const CustomXMarkIcon = withIcon(XMarkIcon, {
  width: 24,
  height: 24,
});

const CustomVMarkIcon = withIcon(VMarkIcon, {
  width: 24,
  height: 24,
});

const useToast = () => {
  const toastSuccess = ({ message, id = '' }: IToastProps) => {
    toast.success(message, {
      toastId: `${ToastId.Success}-${id}`,
      icon: CustomVMarkIcon,
    });
  };

  const toastError = ({ message, id = '' }: IToastProps) => {
    toast.error(message, {
      toastId: `${ToastId.Failed}-${id}`,
      icon: CustomXMarkIcon,
    });
  };

  return {
    toastSuccess,
    toastError,
  };
};

export default useToast;
