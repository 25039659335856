import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';
import { apiStrings, httpClient } from 'src/services';

const usePaymentIntegrationQuery = () => {
  const dispatch = useDispatch();

  const { data, isLoading: isPaymentIntegrationLoading } = useQuery({
    queryKey: ['paymentIntegration'],
    queryFn: async () => {
      try {
        dispatch(showLoading());
        const response = await httpClient.get(
          apiStrings.paymentIntegration.integration
        );

        dispatch(hideLoading());
        return response.data.data;
      } catch (error) {
        dispatch(hideLoading());
        throw error;
      }
    },
    cacheTime: 0,
  });

  return {
    data,
    isPaymentIntegrationLoading,
  };
};

export default usePaymentIntegrationQuery;
