import {
  ACCESS_TOKEN_KEY,
  TOKEN_TYPE_KEY,
  EXPIRED_DAY_KEY,
  TEMPORARY_PASSWORD_KEY,
  REQUIRES_DATA_SHARING_KEY,
  TOKEN_INFO_KEY,
} from 'src/constants';
import { getCookie, removeCookie, setCookie } from 'src/services';
import { Auth, TokenInfo } from 'src/types';

const useAuth = () => {
  const saveCookieToken = (data: Auth, days: number) => {
    setCookie(ACCESS_TOKEN_KEY, data.jwtResponse?.accessToken || '', days);
    setCookie(
      TOKEN_TYPE_KEY,
      data.jwtResponse?.tokenType.toString() || '',
      days
    );
    setCookie(
      EXPIRED_DAY_KEY,
      data.jwtResponse?.expiredIn.toString() || '',
      days
    );
    setCookie(TEMPORARY_PASSWORD_KEY, data.temporaryPassword.toString(), days);
    setCookie(
      REQUIRES_DATA_SHARING_KEY,
      data.requiresDataSharing.toString(),
      days
    );
  };

  const saveTokenInfo = (data: TokenInfo, days: number) => {
    setCookie(
      TOKEN_INFO_KEY,
      JSON.stringify({
        ...data,
        username: encodeURIComponent(data.username),
      }),
      days
    );
  };

  const removeCookieToken = () => {
    removeCookie(ACCESS_TOKEN_KEY);
    removeCookie(TOKEN_TYPE_KEY);
    removeCookie(EXPIRED_DAY_KEY);
    removeCookie(TEMPORARY_PASSWORD_KEY);
    removeCookie(REQUIRES_DATA_SHARING_KEY);
    removeCookie(TOKEN_INFO_KEY);
  };

  const getCookieToken = (): Auth | null => {
    const accessToken = getCookie(ACCESS_TOKEN_KEY);
    const tokenType = getCookie(TOKEN_TYPE_KEY);
    const expiredIn = getCookie(EXPIRED_DAY_KEY);
    const temporaryPassword = getCookie(TEMPORARY_PASSWORD_KEY);
    const requiresDataSharing = getCookie(REQUIRES_DATA_SHARING_KEY);

    if (
      accessToken !== '' &&
      tokenType !== '' &&
      expiredIn !== '' &&
      temporaryPassword !== '' &&
      requiresDataSharing !== ''
    ) {
      return {
        requiresDataSharing: requiresDataSharing === 'true',
        jwtResponse: {
          accessToken,
          tokenType,
          expiredIn,
        },
        temporaryPassword: temporaryPassword === 'true',
      } as unknown as Auth;
    }
    return null;
  };

  const getTokenInfo = (): TokenInfo | null => {
    try {
      const data = getCookie(TOKEN_INFO_KEY);
      if (data === '') return null;

      const userInfo: TokenInfo = JSON.parse(data);
      return {
        ...userInfo,
        username: decodeURIComponent(userInfo.username),
      };
    } catch (error) {
      throw error;
    }
  };

  return {
    saveCookieToken,
    removeCookieToken,
    getCookieToken,
    saveTokenInfo,
    getTokenInfo,
  };
};
export default useAuth;
