import { Tag as AntdTag } from 'antd';

import { ITagProps } from 'src/components/Tag/Tag.d';

const Tag = ({ label, customClassName }: ITagProps) => {
  return (
    <AntdTag className={customClassName}>
      <span className="label label--xs">{label}</span>
    </AntdTag>
  );
};

export default Tag;
