import Button from 'src/components/Button';

import { IButtonWrapperProps } from 'src/components/BodyContent/ButtonWrapper/ButtonWrapper.d';
import { ButtonColorType, ButtonType } from 'src/components/Button/type';

const ButtonWrapper = ({
  nextTitle,
  nextIcon,
  onNext,
  isNextSubmit,
  backTitle,
  backIcon,
  onBack,
  isBackSubmit,
  disableNextBtn = false,
}: IButtonWrapperProps) => {
  return (
    <div className="body-header__buttons">
      {backTitle && onBack && (
        <Button
          title={backTitle}
          type={ButtonType.Default}
          colorType={ButtonColorType.Gray}
          onClickHandled={onBack}
          icon={backIcon && backIcon}
          isSubmit={isBackSubmit}
        />
      )}
      {nextTitle && onNext && (
        <Button
          title={nextTitle}
          type={ButtonType.Primary}
          colorType={ButtonColorType.Blue}
          onClickHandled={onNext}
          icon={nextIcon && nextIcon}
          isSubmit={isNextSubmit}
          disabled={disableNextBtn}
        />
      )}
    </div>
  );
};

export default ButtonWrapper;
