import { useTranslation } from 'react-i18next';
import { CustomModal } from '../Modal';
import { Form, Formik, FormikProps } from 'formik';
import { ProofModalProps } from './type';
import { Tabs, Upload, UploadFile } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useMemo, useRef, useState } from 'react';
import Input from '../Input/Input';
import Button, { ButtonColorType, ButtonType } from '../Button';
import { ProofType, SubmitFileForm, UploadFileForm } from 'src/types';
import type { GetProp, UploadProps } from 'antd';
import { useToast } from 'src/hooks/ui';
import { useUploadFile, useSubmitFile } from 'src/hooks';
import { httpClientPublic } from 'src/services';
import classNames from 'classnames';
import { validateUrl } from 'src/utils/validators/common';
import { Tab } from 'src/constants';
import { replace } from 'lodash';
import { ReactComponent as GlobeIcon } from 'src/assets/images/icons/globe.svg';
import withIcon from 'src/hocs/withIcon';

const { Dragger } = Upload;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const HttpIcon = withIcon(GlobeIcon, {
  width: 16,
  height: 16,
});

const ProofModal = ({
  open,
  type,
  closeModal,
  fundiinOrderId,
  submitProof,
}: ProofModalProps) => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<SubmitFileForm>>(null);
  const { toastError } = useToast();
  const { uploadFile, isFileUploading } = useUploadFile();
  const { submitFile } = useSubmitFile();
  const [fileUpload, setFileUpload] = useState<UploadFile | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [activeKey, setActiveKey] = useState<string>(Tab.File);

  const description = useMemo(() => {
    switch (type) {
      case ProofType.Invoice:
        return t('orders:upload.uploadDescriptionInvoice');
      case ProofType.Others:
        return t('orders:upload.uploadDescriptionOthers');
      default:
        return '';
    }
  }, [type]);

  const isValid = useMemo(() => {
    return validateUrl(t, 'url', url) === '';
  }, [url]);

  const checkFileType = (file: FileType, allowedTypes: string[]) => {
    return allowedTypes.includes(file.type);
  };

  const beforeUpload = (file: FileType) => {
    const allowedTypesMap: Record<ProofType, string[]> = {
      [ProofType.Invoice]: ['text/xml', 'application/pdf'],
      [ProofType.Others]: ['image/jpeg', 'image/png', 'image/gif'],
      [ProofType.Url]: [],
    };
    const allowedTypes = allowedTypesMap[type as ProofType] || [];

    if (!checkFileType(file, allowedTypes)) {
      toastError({
        message: t('error.wrongFile'),
      });
      return Upload.LIST_IGNORE;
    }

    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      toastError({
        message: t('error.fileBiggerThan4Mb'),
      });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const accept = useMemo(() => {
    switch (type) {
      case ProofType.Invoice:
        return '.xml,.pdf';
      case ProofType.Others:
        return '.jpeg,.png,.gif';
      default:
        return '';
    }
  }, [type]);

  const modalName = useMemo(() => {
    switch (type) {
      case ProofType.Invoice:
        return t('orders:upload.invoice');
      case ProofType.Others:
        return t('orders:upload.others');
      default:
        return t('orders:upload.orderTrackingUrl');
    }
  }, [type]);

  const initialValues = {
    type,
    orderId: fundiinOrderId,
    fileName: '',
    mediaType: '',
    url: '',
  } as unknown as SubmitFileForm;

  const handleUploadProof = () => {
    formRef.current?.handleSubmit();
  };

  const submitFormProof = (values: SubmitFileForm) => {
    const payload = { ...values };
    if (payload.url === '') {
      delete payload.url;
    }
    submitFile(payload, {
      onSuccess: () => {
        closeModal();
        submitProof();
        setFileUpload(null);
      },
    });
  };

  const handleChangeTabs = (key: string) => {
    setActiveKey(key);
    if (key === Tab.File) {
      setUrl('');
      formRef.current?.setFieldValue('url', '');
    } else {
      formRef.current?.setFieldValue('fileName', null);
      formRef.current?.setFieldValue('mediaType', null);
      setFileUpload(null);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    try {
      uploadFile(
        {
          fileName: file.name,
          orderId: fundiinOrderId,
          prefix: fundiinOrderId + '/',
        } as UploadFileForm,
        {
          onSuccess: async data => {
            try {
              const config = {
                params: {
                  isUploadFile: true,
                },
              };
              const response = await httpClientPublic.put(
                data.url,
                file,
                config
              );
              if (response.status === 200) {
                onSuccess(response.data);
              } else {
                toastError({
                  message: t('error.systemError'),
                });
              }
            } catch (error) {
              onError(error);
            }
          },
        }
      );
    } catch (error) {
      onError(error);
    }
  };

  const disabledBtn = useMemo(() => {
    if (!!fileUpload) {
      return false;
    }
    if (!!url && isValid) {
      return false;
    }
    return true;
  }, [fileUpload, url, isValid]);

  return (
    <>
      <CustomModal
        open={open}
        title={modalName}
        footer={
          <div className="d-flex flex-row">
            <Button
              type={ButtonType.Default}
              colorType={ButtonColorType.Gray}
              title={t('orders:upload.cancel')}
              size="middle"
              className="label--md"
              onClickHandled={() => {
                closeModal();
                setFileUpload(null);
              }}
              rootClassName="mr--12"
            />
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:upload.confirm')}
              size="middle"
              className="label--md"
              onClickHandled={handleUploadProof}
              disabled={disabledBtn || isFileUploading}
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={() => {
          closeModal();
          setFileUpload(null);
        }}
        destroyOnClose
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitFormProof}
          enableReinitialize
          innerRef={formRef}
        >
          {({ setFieldValue, values }) => {
            const tabs = [
              {
                label: t('orders:upload.file'),
                children: (
                  <div className="p--24">
                    <Dragger
                      customRequest={customRequest}
                      beforeUpload={beforeUpload}
                      accept={accept}
                      multiple={false}
                      maxCount={1}
                      onChange={info => {
                        const extension = info.file.name?.split('.')?.pop();
                        setFieldValue(
                          'fileName',
                          replace(info.file.name || '', `.${extension}`, '')
                        );
                        setFieldValue('mediaType', extension);
                        if (info.fileList.length > 0) {
                          setFileUpload(info.file);
                        } else {
                          setFileUpload(null);
                        }
                      }}
                      className={classNames({
                        'hidden-upload': !!fileUpload,
                      })}
                      showUploadList={!!fileUpload}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        {t('orders:upload.uploadTitle')}
                      </p>
                      <p className="ant-upload-hint">{description}</p>
                    </Dragger>
                  </div>
                ),
                key: Tab.File,
              },
              {
                label: t('orders:upload.link'),
                children: (
                  <div className="pl--24 pr--24">
                    <Input
                      addonBefore={<HttpIcon />}
                      type={'string'}
                      name={'url'}
                      label={t('orders:upload.urlLabel2')}
                      placeholder={t('orders:upload.urlPlaceholder')}
                      value={values.url}
                      validate={(value: string) => validateUrl(t, 'url', value)}
                      onChange={e => {
                        setFieldValue('url', e.target.value);
                        setUrl(e.target.value);
                      }}
                    />
                  </div>
                ),
                key: Tab.Url,
              },
            ];
            return (
              <Form>
                {type === ProofType.Url ? (
                  <>
                    <Input
                      addonBefore={<HttpIcon />}
                      type={'string'}
                      name={'url'}
                      label={t('orders:upload.urlLabel')}
                      placeholder={t('orders:upload.urlPlaceholder')}
                      value={values.url || ''}
                      validate={(value: string) => validateUrl(t, 'url', value)}
                      onChange={e => {
                        setFieldValue('url', e.target.value);
                        setUrl(e.target.value);
                        setFieldValue('type', ProofType.Url);
                      }}
                    />
                  </>
                ) : (
                  <Tabs
                    className="tabs-full-width -mt--32 -mx--24"
                    tabBarStyle={{ width: '100%' }}
                    defaultActiveKey={Tab.File}
                    activeKey={activeKey}
                    onChange={handleChangeTabs}
                    items={tabs.map(tab => {
                      return {
                        key: tab.key,
                        label: tab.label,
                        children: tab.children,
                      };
                    })}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
};

export default ProofModal;
