// COLOR IN THE SYSTEM
export const COLOR_PRIMARY_100: string = '#edebfd';
export const COLOR_PRIMARY_300: string = '#E6F7FF';
export const COLOR_PRIMARY_500: string = '#188FFF';
export const COLOR_PRIMARY_600: string = '#208FFF';

export const COLOR_GRAY_100: string = '#fff';
export const COLOR_GRAY_200: string = '#f8f7fa';
export const COLOR_GRAY_300: string = '#F3F4F5';
export const COLOR_GRAY_400: string = '#EBEEF0';
export const COLOR_GRAY_500: string = '#D5D7D9';
export const COLOR_GRAY_600: string = '#BBBDBF';
export const COLOR_GRAY_700: string = '#898B8C';
export const COLOR_GRAY_800: string = '#575859';
export const COLOR_GRAY_900: string = '#252526';
export const COLOR_GRAY_1000: string = '#19191a';

export const COLOR_DUST_RED_100: string = '#fff1f0';
export const COLOR_DUST_RED_200: string = '#ffccc7';
export const COLOR_DUST_RED_300: string = '#ffa39e';
export const COLOR_DUST_RED_400: string = '#ff7875';
export const COLOR_DUST_RED_500: string = '#ff4d4f';
export const COLOR_DUST_RED_600: string = '#f5222d';
export const COLOR_DUST_RED_700: string = '#cf1322';
export const COLOR_DUST_RED_800: string = '#a8071a';
export const COLOR_DUST_RED_900: string = '#820014';
export const COLOR_DUST_RED_1000: string = '#5c0011';

export const COLOR_POLAR_GREEN_100: string = '#f6ffed';
export const COLOR_POLAR_GREEN_500: string = '#b7eb8f';
export const COLOR_POLAR_GREEN_600: string = '#52c41a';

export const COLOR_CALENDULA_GOLD_100 = '#FFFBE6';
export const COLOR_CALENDULA_GOLD_200 = '#FFF1B8';
export const COLOR_CALENDULA_GOLD_600 = '#FAAD14';
