import { createSlice } from '@reduxjs/toolkit';
import { IModal } from '../../components/Modal/type';
import { ModalType } from 'src/types';

interface IUiState {
  loading: boolean;
  textLoading: string;
  modal: IModal;
}

const initialState: IUiState = {
  loading: false,
  textLoading: '',
  modal: {
    show: false,
    type: ModalType.Error,
    title: '',
    content: '',
    closeIcon: false,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showLoading: state => {
      state.loading = true;
    },
    setTextLoading: (state, action: { payload: string }) => {
      state.textLoading = action.payload;
    },
    hideLoading: state => {
      state.loading = false;
    },
    showModal: (state, action: { payload: IModal }) => {
      state.modal = {
        ...action.payload,
        show: true,
      };
    },
    hideModal: state => {
      state.modal = initialState.modal;
    },
  },
});

export const {
  showLoading,
  hideLoading,
  setTextLoading,
  showModal,
  hideModal,
} = uiSlice.actions;
export default uiSlice.reducer;
