import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiStrings, httpClient } from 'src/services';
import { CommonResponse, ErrorCriteria } from 'src/types';
import { useNavigate } from 'react-router-dom';
import routeStrings from 'src/routes/routeStrings';
import { useDispatch } from 'react-redux';
import { logOut as logOutAction } from 'src/redux/slices/authSlice';
import useAuth from './useAuth';
import { useToast } from '../ui';
import { useTranslation } from 'react-i18next';

const useLogout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removeCookieToken } = useAuth();
  const { toastError } = useToast();

  const {
    mutate: logOut,
    isSuccess,
    isLoading: isLoadingLogout,
  } = useMutation<CommonResponse, AxiosError<ErrorCriteria>>({
    mutationFn: async () => {
      try {
        const response = await httpClient.post(apiStrings.authen.logOut, {});
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async () => {
      dispatch(logOutAction());
      removeCookieToken();
      navigate(routeStrings.signIn);
    },

    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    isLoadingLogout,
    logOut,
    isSuccess,
  };
};

export default useLogout;
