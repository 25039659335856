import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect } from 'react';

import ValidatedError from 'src/components/ValidatedError';

import { ISelectProps } from 'src/components/Select/Select.d';
import { ISelectItem } from 'src/types';

const Select = ({
  open,
  name,
  placeholder,
  label,
  value,
  options,
  onClick,
  onValueChanged,
  onDropdownVisibleChange,
  validate,
  mode,
  maxTagCount,
  maxTagTextLength,
  dropdownRender,
  showSearch = false,
  allowClear = false,
  loading = false,
  required = false,
  className = '',
  disabled = false,
  rootClassName = '',
  resetValueOnUnmount = false,
  hideValidate = false,
  showDefault = false,
}: ISelectProps) => {
  const [field, meta, helper] = useField({
    name,
    validate,
  });

  useEffect(() => {
    const cleanupField = async () => {
      helper.setError('');
      await helper.setTouched(false);

      if (resetValueOnUnmount) {
        await helper.setValue(mode === 'multiple' ? [] : '');
      }
    };

    return () => {
      cleanupField();
    };
  }, []);

  const filterOption = (input: string, option?: ISelectItem) => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  const onBlur = () => helper.setTouched(true);

  return (
    <div
      className={classNames({
        'd-flex flex-col': true,
        [rootClassName]: !!rootClassName,
      })}
    >
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <AntdSelect
        {...field}
        open={open}
        onClick={onClick}
        options={options}
        placeholder={placeholder}
        maxTagCount={maxTagCount}
        maxTagTextLength={maxTagTextLength}
        dropdownRender={dropdownRender}
        onChange={onValueChanged}
        loading={loading}
        status={meta.error && meta.touched ? 'error' : ''}
        className={classNames({
          [className]: !!className,
        })}
        allowClear={allowClear}
        mode={mode}
        showSearch={showSearch}
        filterOption={showSearch ? filterOption : false}
        onDropdownVisibleChange={onDropdownVisibleChange}
        getPopupContainer={node => node}
        disabled={disabled}
        onBlur={onBlur}
        value={showDefault && value === '' ? value : value ? value : undefined}
      />

      {validate && !hideValidate && (
        <ValidatedError error={meta.error} touched={meta.touched} />
      )}
    </div>
  );
};

export default Select;
