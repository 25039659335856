import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';
import { apiStrings, httpClient } from 'src/services';
import { ErrorCriteria } from 'src/types';
import { buildQueryString, downloadFile } from 'src/utils/helper';
import { useToast } from '../ui';

const useExportOrder = (callback: () => void) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { toastError } = useToast();

  const { mutate: exportOrder } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    any
  >({
    mutationFn: async (variables: {
      queryStringObj: any;
      recordIds: Key[];
    }) => {
      dispatch(showLoading());

      const queryString = buildQueryString(variables.queryStringObj);

      try {
        const response = await httpClient.post(
          apiStrings.order.export + '?' + queryString,
          {
            referenceIds: variables.recordIds,
          }
        );

        return response.data.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async response => {
      await downloadFile(response.fileUrl || '', 'google');
      dispatch(hideLoading());
      callback();
    },
    onError: async err => {
      dispatch(hideLoading());
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return { exportOrder };
};

export default useExportOrder;
