import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { apiStrings, httpClient } from 'src/services';
import { useToast } from '../ui';
import { ErrorCriteria } from 'src/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';

const useCancelOrder = () => {
  const { toastError } = useToast();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { mutate: cancelOrder, isSuccess } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    any
  >({
    mutationFn: async variables => {
      dispatch(showLoading());
      try {
        const response = await httpClient.post(
          apiStrings.order.cancelOrder,
          variables
        );
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: () => {
      dispatch(hideLoading());
    },
    onError: async err => {
      dispatch(hideLoading());
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    cancelOrder,
    isSuccess,
  };
};

export default useCancelOrder;
