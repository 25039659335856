import { FieldArray, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  ProductOrder as ProductOrderType,
  ProductList as ProductListType,
} from 'src/types';
import ProductItem from './ProductItem';
import { FormEvent } from 'react';

interface ProductListProps {
  values: ProductListType;
  onSelectItem: (data: ProductOrderType, index: number) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<ProductListType>>;
  isDetail?: boolean;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

const ProductList = ({
  values,
  onSelectItem,
  isDetail = false,
  handleSubmit,
}: ProductListProps) => {
  const { t } = useTranslation();

  const handleEdit = (data: ProductOrderType, index: number) => {
    onSelectItem(data, index);
  };

  return (
    <>
      <FieldArray
        name="products"
        render={arrayHelpers => (
          <>
            {values.products && values.products.length > 0 && (
              <div className="table-wrap">
                <div className="table-header">
                  <table>
                    <thead>
                      <tr>
                        <th className="table-header-cell text-left col-name">
                          {t('orders:createOrderPage.productName')}
                        </th>
                        <th className="table-header-cell text-right col-price">
                          {t('orders:createOrderPage.unitPrice')}
                        </th>
                        <th className="table-header-cell text-right col-quantity">
                          {t('orders:createOrderPage.quantity')}
                        </th>
                        <th className="table-header-cell text-right col-amount">
                          {t('orders:createOrderPage.amount')}
                        </th>
                        {!isDetail && (
                          <th className="table-header-cell col-action"> </th>
                        )}
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="table-body">
                  <table>
                    <tbody className="table-body">
                      {values.products.map((product, index) => (
                        <ProductItem
                          product={product}
                          key={index}
                          arrayHelpers={arrayHelpers}
                          indexItem={index}
                          onEdit={() => handleEdit(product, index)}
                          isDetail={isDetail}
                          handleSubmit={handleSubmit}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default ProductList;
