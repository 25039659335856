import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { apiStrings, httpClient } from 'src/services';

import { ISelectItem } from 'src/types';

interface StoreSelectItem extends ISelectItem {
  name?: string;
  channel?: string;
  website?: string;
  address?: string;
  provinceId?: string;
  provinceName?: string;
  districtId?: string;
  districtName?: string;
  wardId?: string;
  wardName?: string;
  status?: string;
}

interface StoresQueryProps {
  showId?: boolean;
  defaultOption?: StoreSelectItem;
}

const useStoresQuery = ({ showId, defaultOption }: StoresQueryProps) => {
  const { data, isLoading: isStoreLoading } = useQuery({
    queryKey: ['stores'],
    queryFn: async () => {
      try {
        const response = await httpClient.get(apiStrings.order.stores);
        return response.data ? response.data.data.storeInfo : [];
      } catch (error) {
        throw error;
      }
    },
    select: stores => {
      const transformedStores: StoreSelectItem[] = sortBy(
        stores.map((store: any) => ({
          value: store.id.toString(),
          label: showId ? `[${store.id}] - ${store.name}` : store.name,
          name: store.name,
          channel: store.channel,
          website: store.website,
          address: store.address,
          provinceId: store.provinceId,
          provinceName: store.provinceName,
          districtId: store.districtId,
          districtName: store.districtName,
          wardId: store.wardId,
          wardName: store.wardName,
          status: store.status,
        })),
        'label'
      );

      if (defaultOption) {
        return [defaultOption, ...transformedStores];
      }

      return transformedStores;
    },
    staleTime: 0,
    cacheTime: 0,
  });

  return {
    stores: data || [],
    isStoreLoading,
  };
};

export default useStoresQuery;
