export interface CommonResponse<ResponseType = NonNullable<unknown>> {
  status: string;
  message: string;
  data?: ResponseType;
}

export enum ModalType {
  Error = 'ERROR',
  Information = 'INFORMATION',
  confirmation = 'CONFIRMATION',
  warning = 'WARNING',
}

export interface ISelectItem {
  value: string;
  label: string;
}

export interface FilterCriteria<T> {
  filters: {
    searchText?: string;
  } & T;
  paging: {
    page: number;
    pageSize: number;
  };
  sorts?: {
    descending: boolean;
    field: string;
  }[];
}

export interface ErrorCriteria {
  message: string;
  status: string;
}

export interface Location {
  id: string;
  name: string;
}

export interface Address {
  street: string;
  province: Location;
  district: Location;
  ward: Location;
}

export enum LocationType {
  Province = 'PROVINCE',
  District = 'DISTRICT',
  Ward = 'WARD',
}
