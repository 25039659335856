import { Address, OrderStatus, StoreChannel } from 'src/types';

export enum DeliveryEnum {
  PickUpAtStore = 'PICK_UP_AT_STORE',
  Delivery = 'DELIVERY',
}

export enum DiscountEnum {
  Percent = 'PERCENT',
  Value = 'VALUE',
}

export interface Store {
  id: string;
  name: string;
  channel: StoreChannel;
  website: string;
  address: Address;
  status: string;
}

export interface Shipping {
  street: string;
  ward: string;
  district: string;
  city: string;
}

export interface CustomerForm {
  phoneNumber: string;
  fullName: string;
}

export interface Customer extends CustomerForm {
  delivery?: string;
  address?: Address;
}

export interface Seller {
  phoneNumber: string;
}

export interface ProductOrder {
  productName: string;
  price: number;
  quantity: number;
  totalAmount: number;
}

export interface ProductList {
  products: ProductOrder[];
}

export interface CreateOrderForm {
  storeId: string;
  amount: Amount;
  items: ProductOrder[];
  customer: CustomerForm;
  shipping: Shipping;
  seller: Seller;
}

export interface Amount {
  value: number;
  discount: number;
  currency: string;
}

export interface CreateOrderState {
  staff: string;
  store: Store;
  customer: Customer;
  products: ProductOrder[];
  discountValue: number;
  total: number;
  referenceId?: string;
}

export interface CancelOrderForm {
  reasonId: string;
  reason: string;
  other: string;
}

export interface CreateOrderResponse {
  referenceId: string;
  paymentUrl: string;
  qrData: string;
  expireTime: string;
  sendSms: boolean;
  totalAmount: number;
  resultStatus: OrderStatus;
  merchantBillId?: string;
}
