import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterIcon } from 'src/assets/images/icons/filter.svg';
import { ReactComponent as FilteredIcon } from 'src/assets/images/icons/solid-filter-circle-check.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { COLOR_GRAY_900 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';

import { IFilterProps } from 'src/components/Filter/Filter.d';

const CustomFilterIcon = withIcon(FilterIcon, {
  width: 16,
  height: 16,
  color: COLOR_GRAY_900,
});

const CustomFilteredIcon = withIcon(FilteredIcon, {
  width: 16,
  height: 16,
  color: COLOR_GRAY_900,
});

const Filter = ({
  onFilterCleared,
  onFiltered,
  isDirty = false,
  children,
}: IFilterProps) => {
  const { t } = useTranslation();
  const [isDisplay, setIsDisplay] = useState<boolean>(false);

  const onFilterOpened = (event: SyntheticEvent) => {
    event.preventDefault();
    setIsDisplay(true);
  };

  const onFilterClosed = (event: SyntheticEvent) => {
    event.preventDefault();
    setIsDisplay(false);
  };

  const onItemFiltered = (event: SyntheticEvent) => {
    onFiltered(event);
    onFilterClosed(event);
  };

  const onItemFilterCleared = (event: SyntheticEvent) => {
    onFilterCleared(event);
    onFilterClosed(event);
  };

  return (
    <>
      <Button
        type={ButtonType.Default}
        colorType={ButtonColorType.Gray}
        icon={isDirty ? <CustomFilteredIcon /> : <CustomFilterIcon />}
        onClickHandled={onFilterOpened}
        className={classNames({
          dirty: isDirty,
        })}
      />

      <div
        className={classNames('overlay', {
          'overlay--active': isDisplay,
        })}
        onClick={onFilterClosed}
      />

      <div
        className={classNames('filter-panel', {
          'filter-panel--active': isDisplay,
        })}
      >
        <header className="filter-panel__header">
          <h1 className="heading heading--sm">{t(`common:filter`)}</h1>
        </header>

        <section className="filter-panel__content">{children}</section>

        <footer className="filter-panel-footer">
          <div
            className="label label--md filter-panel-footer__clear"
            onClick={onItemFilterCleared}
          >
            {t('clearAll')}
          </div>
          <Button
            type={ButtonType.Primary}
            colorType={ButtonColorType.Blue}
            title={t('apply')}
            onClickHandled={onItemFiltered}
          />
        </footer>
      </div>
    </>
  );
};

export default Filter;
