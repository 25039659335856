import { Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Edit } from 'src/assets/images/icons/edit.svg';
import { ReactComponent as Phone } from 'src/assets/images/icons/phone.svg';
import { ReactComponent as Delivery } from 'src/assets/images/icons/delivery.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import CustomerForm from 'src/components/CreateOrder/Customer/CustomerForm';
import { CustomModal } from 'src/components/Modal';
import { COLOR_GRAY_900 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';

import { CustomerProps } from 'src/components/CreateOrder/type';
import { Customer as CustomerType, DeliveryEnum } from 'src/types';

const EditIcon = withIcon(Edit, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const PhoneIcon = withIcon(Phone, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const DeliveryIcon = withIcon(Delivery, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const Customer = ({
  customer,
  isDetail = false,
  setFieldValue,
}: CustomerProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<CustomerType>({
    delivery: DeliveryEnum.PickUpAtStore,
    fullName: '',
    phoneNumber: '',
    cityId: '',
    districtId: '',
    wardId: '',
    address: '',
  } as unknown as CustomerType);
  const formRef = useRef<FormikProps<CustomerType>>(null);

  const onSubmitCustomer = (values: CustomerType) => {
    setFieldValue?.('customer', values);
    setInitValues(values);
    setOpen(false);
  };

  useEffect(() => {
    if (!customer) {
      return;
    }

    setInitValues(customer);
  }, [customer]);

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 br--8">
        <div className="d-flex flex-row justify-between py--16 px--24">
          <p className="label label--lg">
            {t('orders:createOrderPage.informationCustomer')}
          </p>
        </div>
        <hr />
        <div className="d-flex flex-col items-center py--16 px--24">
          {!initValues.phoneNumber ? (
            <>
              <div className="d-flex items-start flex-row justify-between w--100">
                <p className="paragraph paragraph--sm text-gray-700">
                  {t('orders:createOrderPage.noInformation')}
                </p>
                {!isDetail && (
                  <Button
                    type={ButtonType.Text}
                    colorType={ButtonColorType.Blue}
                    title={t('orders:createOrderPage.addInformation')}
                    onClickHandled={() => setOpen(true)}
                    rootClassName="-mt--4"
                  ></Button>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="d-flex items-start flex-row justify-between w--100">
                <p className="label label--sm text-gray-900 mb--8">
                  {initValues?.fullName}
                </p>
                {!isDetail && (
                  <Button
                    type={ButtonType.Text}
                    colorType={ButtonColorType.Gray}
                    icon={<EditIcon />}
                    onClickHandled={() => setOpen(true)}
                    rootClassName="-mt--8 quantity-button"
                  ></Button>
                )}
              </div>
              <div className="w--100">
                <div className="d-flex items-center justify-start mb--8">
                  <PhoneIcon className="mr--8" />
                  <p className="paragraph paragraph--sm text-gray-800">
                    {initValues?.phoneNumber}
                  </p>
                </div>
                {initValues.delivery === DeliveryEnum.Delivery ? (
                  <div className="d-flex items-center justify-start">
                    <DeliveryIcon className="mr--8" />
                    <p className="paragraph paragraph--sm text-gray-800 text-ellipsis">
                      {`${initValues?.address?.street ?? ''}, ${initValues?.address?.ward?.name ?? ''}, ${initValues?.address?.district?.name ?? ''}, ${initValues?.address?.province?.name ?? ''}`}
                    </p>
                  </div>
                ) : (
                  <div className="d-flex items-center justify-start">
                    <DeliveryIcon className="mr--8" />
                    <p className="paragraph paragraph--sm text-gray-800">
                      {t('orders:createOrderPage.pickUpInStore')}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <CustomModal
        open={open}
        title={t('orders:createOrderPage.informationCustomer')}
        footer={
          <div className="d-flex justify-end">
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.add')}
              size="large"
              onClickHandled={() => formRef.current?.handleSubmit()}
              className="label--md"
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={() => setOpen(false)}
        destroyOnClose
      >
        <Formik
          initialValues={initValues || ({} as CustomerType)}
          onSubmit={onSubmitCustomer}
          enableReinitialize
          innerRef={formRef}
        >
          {({
            values,
            setFieldValue: setFieldValueCustomer,
            setFieldTouched,
          }) => (
            <CustomerForm
              values={values}
              setFieldValue={setFieldValueCustomer}
              setFieldTouched={setFieldTouched}
            />
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Customer;
