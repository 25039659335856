import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks';
import { apiStrings, httpClient } from 'src/services';
import { ErrorCriteria } from 'src/types';

const useGetPaymentInfo = (id?: string) => {
  const { toastError } = useToast();
  const { t } = useTranslation();

  const { data, isLoading, isSuccess } = useQuery<
    any,
    AxiosError<ErrorCriteria>
  >({
    queryKey: ['order-payment', id],
    queryFn: async () => {
      try {
        const response = await httpClient.get(
          apiStrings.order.paymentInfo + id
        );
        return response.data.data;
      } catch (error) {
        toastError({
          message: t('error.systemError'),
        });
      }
    },
  });

  return { data, isLoading, isSuccess };
};

export default useGetPaymentInfo;
