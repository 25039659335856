import { AccountForm, ChangePasswordForm } from 'src/types/account';

export const initialAccount: AccountForm = {
  username: '',
  password: '',
};

export const initialFormChangePassword: ChangePasswordForm = {
  password: '',
  confirmPassword: '',
};

export const roles = {
  writeContracts: 'write_contracts',
  writeImei: 'write_imei',
  writePaymentProfiles: 'write_paymentProfiles',
  readPaymentProfiles: 'read_paymentProfiles',
  writeOrders: 'write_orders',
  readOrders: 'read_orders',
  cancelOrders: 'cancel_orders',
  createOrders: 'create_orders',
  readContracts: 'read_contracts',
  createProofs: 'create_proofs',
  writeCommission: 'write_commission',
};
