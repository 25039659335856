import { Input as AntdInput, Tooltip as AntdTooltip } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect } from 'react';

import { ReactComponent as CircleQuestion } from 'src/assets/images/icons/circle-question.svg';
import ValidatedError from 'src/components/ValidatedError';
import { COLOR_GRAY_700 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';

import { TextAreaProps } from 'src/components/Input';

const { TextArea: AntdTextArea } = AntdInput;

const CustomCircleQuestion = withIcon(CircleQuestion, {
  color: COLOR_GRAY_700,
  height: 14,
  width: 14,
});

const TextArea = ({
  name,
  label,
  placeholder,
  validate,
  maxLength,
  value,
  popoverContent,
  onValueChanged,
  required = false,
  className = '',
  showCount = false,
  resetValueOnUnmount = false,
}: TextAreaProps) => {
  const [field, meta, helper] = useField({
    name,
    value,
    validate,
  });

  useEffect(() => {
    const cleanupField = async () => {
      helper.setError('');
      await helper.setTouched(false);

      if (resetValueOnUnmount) {
        await helper.setValue('');
      }
    };

    return () => {
      cleanupField();
    };
  }, []);

  return (
    <div className="d-flex flex-col">
      <div className="textarea__label">
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>

        {popoverContent && (
          <AntdTooltip
            title={popoverContent}
            placement="topLeft"
            arrow={{ pointAtCenter: true }}
          >
            <CustomCircleQuestion className="mb--8 textarea__icon" />
          </AntdTooltip>
        )}
      </div>

      <AntdTextArea
        {...field}
        className={classNames({
          [className]: !!className,
        })}
        status={meta.touched && meta.error ? 'error' : ''}
        placeholder={placeholder}
        maxLength={maxLength}
        showCount={showCount}
        onChange={e =>
          onValueChanged
            ? onValueChanged(e.target.value)
            : helper.setValue(e.target.value)
        }
      />

      {validate && <ValidatedError touched={meta.touched} error={meta.error} />}
    </div>
  );
};

export default TextArea;
