import { ConfigProvider } from 'antd';
import { Routes } from 'src/routes';
import { QueryClientProvider } from 'src/services';

import 'src/i18n/config';
import 'react-toastify/dist/ReactToastify.css';
import 'src/styles/main.scss';

const App = () => {
  return (
    <QueryClientProvider>
      <ConfigProvider
        theme={{
          token: {},
        }}
      >
        <Routes />
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
