import { InputNumber } from 'antd';
import classNames from 'classnames';
import { reduce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { CustomModal } from 'src/components/Modal';
import { numberFormatter } from 'src/utils/helper';

import { PaymentProps } from 'src/components/CreateOrder/type';
import { DiscountEnum, ProductOrder } from 'src/types';

const Payment = ({
  products,
  setFieldValue,
  isDetail = false,
  discountAmount = 0,
  totalAmount = 0,
}: PaymentProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [input, setInput] = useState<number>();
  const [typeDiscount, setTypeDiscount] = useState<DiscountEnum>(
    DiscountEnum.Percent
  );
  const [discount, setDiscount] = useState<number>();
  const discountPrev = useRef<number>(0);
  const typePrev = useRef<DiscountEnum>(DiscountEnum.Percent);

  const amount = useMemo(() => {
    return reduce(
      products,
      (currentValue: number, product: ProductOrder) => {
        return currentValue + product.quantity * product.price;
      },
      0
    );
  }, [products]);

  const discountValue = useMemo(() => {
    if (!discount) {
      return 0;
    }
    if (typeDiscount === DiscountEnum.Value) {
      return discount;
    } else {
      return (discount * amount) / 100;
    }
  }, [amount, discount]);

  const total = useMemo(() => {
    return amount - discountValue;
  }, [amount, discountValue]);

  const disabledDiscount = useMemo(() => {
    return amount === 0;
  }, [amount]);

  useEffect(() => {
    setFieldValue?.('discountValue', discountValue);
  }, [discountValue, setFieldValue]);

  useEffect(() => {
    setFieldValue?.('total', total);
  }, [total, setFieldValue]);

  const validateDiscount = useMemo(() => {
    if (typeof input !== 'number') {
      return false;
    }

    if (!input) {
      return true;
    }

    if (typeDiscount === DiscountEnum.Percent) {
      return input >= 1;
    }

    return input >= 1000;
  }, [input, typeDiscount]);

  const submitDiscount = () => {
    if (!validateDiscount) return;
    setDiscount(input);
    setOpen(false);
    typePrev.current = typeDiscount;
    discountPrev.current = input || 0;
  };

  const resetInput = () => {
    setInput(0);
  };

  const changeType = (type: DiscountEnum) => {
    setTypeDiscount(type);
    if (typePrev.current === type && discountPrev.current > 0) {
      setInput(discountPrev.current);
    } else {
      resetInput();
    }
  };

  const closeModal = () => {
    setOpen(false);
    resetInput();
  };

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 mt--24 br--8">
        <div className="d-flex flex-row justify-between py--16 px--24">
          <p className="label label--lg">
            {t('orders:createOrderPage.payment')}
          </p>
        </div>
        <hr />
        <div className="d-flex flex-col items-center py--16 px--24">
          <div className="d-flex flex-row justify-between w--100">
            <p className="text-gray-700">
              {t('orders:createOrderPage.provisional')}
            </p>
            <p className="text-gray-800">
              {numberFormatter(+amount.toFixed(0))} {t('common:currency.vnd')}
            </p>
          </div>
          <div className="d-flex flex-row justify-between items-center w--100 mt--24">
            {isDetail ? (
              <p className="text-gray-700">
                {t('orders:createOrderPage.discount')}
              </p>
            ) : (
              <Button
                type={ButtonType.Text}
                colorType={ButtonColorType.Blue}
                title={
                  discountValue === 0
                    ? t('orders:createOrderPage.addDiscount')
                    : t('orders:createOrderPage.editDiscount')
                }
                onClickHandled={() => setOpen(true)}
                rootClassName="-ml--12"
                disabled={disabledDiscount}
              />
            )}

            <p
              className={classNames({
                'text-polar-green-600': discountValue > 0 || discountAmount > 0,
                'text-gray-800': discountValue === 0,
              })}
            >
              {isDetail
                ? numberFormatter(-discountAmount || 0)
                : discountValue > 0
                  ? numberFormatter(-discountValue)
                  : 0}{' '}
              {t('common:currency.vnd')}
            </p>
          </div>
        </div>
        <div className="d-flex flex-row justify-between w--100 background-primary-200 p--24">
          <p>
            {isDetail
              ? t('orders:createOrderPage.totalDetail', {
                  count: products?.length,
                })
              : t('orders:createOrderPage.total', { count: products?.length })}
          </p>
          <p className="heading heading--xs">
            {numberFormatter(isDetail ? totalAmount : +total.toFixed(0))}{' '}
            {t('common:currency.vnd')}
          </p>
        </div>
      </div>
      <CustomModal
        open={open}
        title={t('orders:createOrderPage.addDiscount')}
        footer={
          <Button
            type={ButtonType.Primary}
            colorType={ButtonColorType.Blue}
            title={t('orders:createOrderPage.add')}
            size="large"
            className="label--md"
            onClickHandled={submitDiscount}
            disabled={!validateDiscount}
          />
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={closeModal}
        destroyOnClose
      >
        <div className="d-flex flex-col items-start justify-start w--100 mb--12">
          <label className="label label--md mb--8">
            {t('orders:createOrderPage.discount')}
            <span className="color--error ml--8">*</span>
          </label>
          <div className="d-flex justify-center items-center w--50 mb--24">
            <Button
              type={
                typeDiscount === DiscountEnum.Percent
                  ? ButtonType.Primary
                  : ButtonType.Default
              }
              colorType={ButtonColorType.Blue}
              title={'%'}
              size="large"
              block
              rootClassName="label--md rounded-r-none"
              onClickHandled={() => changeType(DiscountEnum.Percent)}
            />
            <Button
              type={
                typeDiscount !== DiscountEnum.Percent
                  ? ButtonType.Primary
                  : ButtonType.Default
              }
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.discountAmount')}
              size="large"
              block
              rootClassName="label--md rounded-l-none"
              onClickHandled={() => changeType(DiscountEnum.Value)}
            />
          </div>
          <label
            className={classNames({
              'label label--md mb--8': true,
              lowercase: typeDiscount === DiscountEnum.Percent,
            })}
          >
            {typeDiscount === DiscountEnum.Percent
              ? '% ' + t('orders:createOrderPage.discount')
              : t('orders:createOrderPage.discountValue')}
            <span className="color--error ml--8">*</span>
          </label>
          <InputNumber
            name="discount"
            required
            placeholder={
              typeDiscount === DiscountEnum.Percent
                ? t('orders:createOrderPage.discountPlaceHolder')
                : t('orders:createOrderPage.discountValuePlaceHolder')
            }
            value={input}
            min={0}
            onChange={value =>
              setInput(
                typeDiscount === DiscountEnum.Percent
                  ? value || 0
                  : Math.round(value || 0)
              )
            }
            rootClassName="w--100"
            suffix={
              typeDiscount === DiscountEnum.Percent
                ? '%'
                : t('common:currency.vnd')
            }
            max={typeDiscount === DiscountEnum.Percent ? 100 : amount}
            formatter={numberFormatter}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default Payment;
