import { Input as AntdInput } from 'antd';
import { Formik, Form, FormikProps } from 'formik';
import { isNumber } from 'lodash';
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Plus } from 'src/assets/images/icons/plus-blue.svg';
import { ReactComponent as Empty } from 'src/assets/images/icons/empty.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import ProductList from 'src/components/CreateOrder/ProductList';
import { Input, NumberInput, QuantityInput } from 'src/components/Input';
import { CustomModal } from 'src/components/Modal';
import { COLOR_GRAY_100, COLOR_PRIMARY_600, roles } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { numberFormatter } from 'src/utils/helper';
import { validateRequired } from 'src/utils/validators/common';

import { ProductProps } from 'src/components/CreateOrder/type';
import {
  ProductOrder as ProductOrderType,
  ProductList as ProductListType,
} from 'src/types';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/store';
// import { useUpdateMerchantBill } from 'src/hooks';
// import { useParams } from 'react-router-dom';

const PlusIcon = withIcon(Plus, {
  width: 20,
  height: 20,
  color: COLOR_PRIMARY_600,
});

const EmptyIcon = withIcon(Empty, {
  width: 185,
  height: 115,
  color: COLOR_GRAY_100,
});

const initProduct = {
  productName: '',
  price: 0,
  quantity: 1,
} as ProductOrderType;

const Product = ({
  products,
  isDetail = false,
  setFieldValue,
  referenceId,
  // isSuccessOrder = false,
  // updatedMerchantBill,
  reasonCancel,
}: ProductProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductOrderType>(initProduct);
  const [editItem, setEditItem] = useState<number | null>(null);
  const formRef = useRef<FormikProps<ProductOrderType>>(null);
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);
  // const { updateMerchantBill } = useUpdateMerchantBill();
  // const { id } = useParams();
  // const [merchantBillId, setMerchantBillId] = useState<string>(
  //   referenceId || ''
  // );

  const isEmpty = useMemo(() => {
    return products?.length === 0;
  }, [products]);

  const onSubmitProduct = (values: ProductOrderType) => {
    if (isNumber(editItem) && editItem >= 0) {
      setFieldValue?.(`products[${editItem}]`, values);
    } else {
      setFieldValue?.(`products[${products?.length}]`, values);
    }
    setOpen(false);
    setEditItem(null);
    setProduct(initProduct);
  };

  const onSubmitProducts = (values: ProductListType) => {
    setFieldValue?.('products', values.products);
  };

  const updatePartner = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue?.(`referenceId`, e.target.value);
  };

  const handleSelectItem = (data: ProductOrderType, index: number) => {
    setProduct(data);
    setEditItem(index);
    setOpen(true);
  };

  const handleAddNewProduct = () => {
    setProduct(initProduct);
    setOpen(true);
  };

  // const isShowMerchantBill = useMemo(
  //   () => isDetail && referenceId,
  //   [isDetail, referenceId]
  // );

  // const isShowUpdateMerchantBill = useMemo(
  //   () =>
  //     isDetail &&
  //     tokenInfo?.permissions.includes(roles.writeOrders) &&
  //     isSuccessOrder &&
  //     !referenceId,
  //   [isDetail, tokenInfo, isSuccessOrder, referenceId, roles]
  // );

  // const handleUpdateMerchantBill = () => {
  //   updateMerchantBill(
  //     {
  //       referenceId: id || '',
  //       merchantBillId: merchantBillId,
  //     },
  //     {
  //       onSuccess: () => {
  //         updatedMerchantBill?.();
  //       },
  //     }
  //   );
  // };

  const hasReasonCancel = useMemo(() => {
    return reasonCancel;
  }, [reasonCancel]);

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 br--8">
        <div className="d-flex flex-row justify-between py--16 px--24 items-center">
          <p className="label label--lg">
            {t('orders:createOrderPage.informationOrder')}
          </p>
          {!isDetail && (
            <Button
              type={ButtonType.Text}
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.addProduct')}
              icon={<PlusIcon />}
              onClickHandled={handleAddNewProduct}
            />
          )}
        </div>
        {isEmpty && <hr />}
        <div className="d-flex flex-col items-center">
          {isEmpty ? (
            <EmptyIcon className="my--40" />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{ products } as ProductListType}
              onSubmit={onSubmitProducts}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({
                values,
                setFieldValue: setFieldValueProduct,
                handleSubmit,
              }) => (
                <ProductList
                  values={values}
                  onSelectItem={handleSelectItem}
                  setFieldValue={setFieldValueProduct}
                  isDetail={isDetail}
                  handleSubmit={handleSubmit}
                />
              )}
            </Formik>
          )}
        </div>
        {!isDetail && <hr />}
        <div className="d-flex flex-col items-center">
          {!isDetail && (
            <div className="d-flex flex-row justify-between items-center w--100 mt--26 py--16 px--24">
              <p>{t('orders:createOrderPage.partner')}</p>
              <AntdInput
                name="username"
                value={referenceId}
                type="text"
                placeholder={t('orders:createOrderPage.partnerIdInput')}
                required
                onChange={updatePartner}
                className="w--50"
              />
            </div>
          )}
          {/*{isShowMerchantBill && (*/}
          {/*  <div className="d-flex flex-row justify-between items-center w--100 mt--26 py--16 px--24">*/}
          {/*    <p className="text-gray-700">*/}
          {/*      {t('orders:createOrderPage.partner')}:{' '}*/}
          {/*      <span className="text-gray-800">{referenceId}</span>*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{isShowUpdateMerchantBill && (*/}
          {/*  <div className="d-flex flex-row justify-between items-center w--100 mt--26 py--16 px--24">*/}
          {/*    <p>{t('orders:createOrderPage.partner')}</p>*/}
          {/*    {!referenceId && (*/}
          {/*      <div className="d-flex flex-row justify-between items-center gap--8 w--60">*/}
          {/*        <AntdInput*/}
          {/*          name="username"*/}
          {/*          value={merchantBillId}*/}
          {/*          type="text"*/}
          {/*          placeholder={t('orders:createOrderPage.partnerIdInput')}*/}
          {/*          required*/}
          {/*          onChange={value => setMerchantBillId(value.target.value)}*/}
          {/*          size="small"*/}
          {/*        />*/}
          {/*        <Button*/}
          {/*          type={ButtonType.Default}*/}
          {/*          colorType={ButtonColorType.Gray}*/}
          {/*          title={t('orders:createOrderPage.update')}*/}
          {/*          size="small"*/}
          {/*          onClickHandled={() => handleUpdateMerchantBill()}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {hasReasonCancel && (
          <div className="d-flex flex-row justify-between w--100 background-calendula-gold-100 p--24 rounded-br-8 rounded-bl-8">
            <p className="text-gray-700">
              {t('orders:orderDetail.reasonCancel')}:{' '}
              <span className="text-gray-800">{reasonCancel}</span>
            </p>
          </div>
        )}
      </div>
      {open && (
        <CustomModal
          open={open}
          title={t('orders:createOrderPage.addProduct')}
          footer={
            <div className="d-flex justify-end">
              <Button
                type={ButtonType.Primary}
                colorType={ButtonColorType.Blue}
                title={t('orders:createOrderPage.add')}
                size="large"
                className="label--md"
                onClickHandled={() => {
                  formRef.current?.handleSubmit();
                }}
              />
            </div>
          }
          width={600}
          className="custom-modal"
          closeIcon
          onCancel={() => setOpen(false)}
        >
          <Formik
            initialValues={product}
            onSubmit={onSubmitProduct}
            enableReinitialize
            innerRef={formRef}
          >
            {({ values, setFieldValue: setFieldValueModal }) => (
              <Form className="-mb--28">
                <Input
                  type="text"
                  name="productName"
                  label={
                    tokenInfo?.permissions.includes(roles.writeImei)
                      ? t('orders:createOrderPage.imei')
                      : t('orders:createOrderPage.productName')
                  }
                  required
                  placeholder={
                    tokenInfo?.permissions.includes(roles.writeImei)
                      ? t('orders:createOrderPage.imeiPlaceholder')
                      : t('orders:createOrderPage.productNamePlaceHolder')
                  }
                  value={values.productName}
                  onChange={e =>
                    setFieldValueModal('productName', e.target.value)
                  }
                  validate={(value: string) =>
                    validateRequired(t, 'productName', value)
                  }
                  rootClassName="w--100 mr--8"
                />
                <p className="text-gray-700 -mt--8">
                  {t('orders:createOrderPage.productNameNote')}
                </p>
                <NumberInput
                  name="price"
                  label={t('orders:createOrderPage.unitPrice')}
                  required
                  placeholder={t('orders:createOrderPage.unitPricePlaceHolder')}
                  value={values.price}
                  onValueChanged={value => setFieldValueModal('price', value)}
                  rootClassName="w--100 mr--8 mt--24"
                  className="w--100"
                  suffix={t('common:currency.vnd')}
                  validate={(value: number) =>
                    validateRequired(t, 'price', value)
                  }
                  formatter={numberFormatter}
                />
                <QuantityInput
                  name="quantity"
                  label={t('orders:createOrderPage.quantity')}
                  required
                  value={values.quantity}
                  onValueChanged={value =>
                    setFieldValueModal('quantity', value)
                  }
                  placeholder={''}
                  increase={() =>
                    setFieldValueModal('quantity', values.quantity + 1)
                  }
                  reduce={() =>
                    setFieldValueModal('quantity', values.quantity - 1)
                  }
                  validate={(value: number) =>
                    validateRequired(t, 'quantity', value)
                  }
                />
              </Form>
            )}
          </Formik>
        </CustomModal>
      )}
    </>
  );
};

export default Product;
