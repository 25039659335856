import { FieldValidator } from 'formik/dist/types';

import { ISelectItem } from 'src/types';

export enum SelectMode {
  Multiple = 'multiple',
  Tags = 'tags',
}

export interface ISelectProps {
  name: string;
  placeholder: string;
  value: any;
  options: ISelectItem[];
  onValueChanged: (value: any) => void;
  label?: string;
  allowClear?: boolean;
  mode?: SelectMode;
  loading?: boolean;
  validate?: FieldValidator;
  required?: boolean;
  className?: string;
  showSearch?: boolean;
  dropdownRender?: (originalNode: any) => any;
  onDropdownVisibleChange?: (open: boolean) => void;
  onClick?: () => void;
  open?: boolean;
  maxTagCount?: number | 'responsive';
  maxTagTextLength?: number;
  disabled?: boolean;
  rootClassName?: string;
  resetValueOnUnmount?: boolean;
  hideValidate?: boolean;
  showDefault?: boolean;
}
