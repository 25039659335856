import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import { useField } from 'formik';
import { useEffect } from 'react';

import ValidatedError from 'src/components/ValidatedError';

import { InputProps } from 'src/components/Input/type';

const Input = ({
  type,
  name,
  placeholder,
  validate,
  maxLength,
  value = '',
  label = '',
  addonBefore = undefined,
  required = false,
  className = '',
  showCount = false,
  disabled = false,
  suffix,
  onChange = () => {},
  rootClassName = '',
  resetValueOnUnmount = false,
}: InputProps) => {
  const [field, meta, helper] = useField({
    name,
    value,
    validate,
  });

  useEffect(() => {
    const cleanupField = async () => {
      helper.setError('');
      await helper.setTouched(false);

      if (resetValueOnUnmount) {
        await helper.setValue('');
      }
    };

    return () => {
      cleanupField();
    };
  }, []);

  return (
    <div
      className={classNames({
        'd-flex flex-col': true,
        [rootClassName]: !!rootClassName,
      })}
    >
      {label && (
        <label className="label label--md mb--8">
          {label}
          {required && <span className="color--error ml--8">*</span>}
        </label>
      )}

      <AntdInput
        {...field}
        type={type}
        value={value || field.value}
        onChange={onChange}
        className={classNames({
          [className]: !!className,
        })}
        status={meta.touched && meta.error ? 'error' : ''}
        placeholder={placeholder}
        showCount={showCount}
        maxLength={maxLength}
        disabled={disabled}
        suffix={suffix}
        addonBefore={addonBefore}
      />

      {validate && <ValidatedError touched={meta.touched} error={meta.error} />}
    </div>
  );
};

export default Input;
