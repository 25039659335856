import { IValidatedError } from 'src/components/ValidatedError/ValidatedError.d';

const ValidatedError = ({ touched, error }: IValidatedError) => {
  return (
    <div className="d-flex flex-nowrap">
      {touched && error && (
        <span className="paragraph paragraph--xs color--error">{error}</span>
      )}
      <div className="vh--28" />
    </div>
  );
};

export default ValidatedError;
