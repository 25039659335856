import { ReactComponent as LogoIcon } from 'src/assets/images/icons/logo.svg';
import { ReactNode } from 'react';
import { getCurrentYear } from 'src/utils/helper';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';

interface PublishLayoutProps {
  children: ReactNode;
  hasImage?: boolean;
}

const PublishLayout = ({ children, hasImage = false }: PublishLayoutProps) => {
  return (
    <>
      <div
        className={classNames(
          'signin__background',
          hasImage && 'signin__background-image'
        )}
      >
        <div className="d-flex flex-col justify-between items-center h-full">
          <LogoIcon className="flex-none" />
          {children}
          <span className="paragraph paragraph--xs flex-none">
            App Version 2.1.0 © Fundiin {getCurrentYear()}
          </span>
        </div>
      </div>
      <ToastContainer autoClose={2000} theme="colored" />
    </>
  );
};

export default PublishLayout;
