import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useToast } from 'src/hooks/ui';
import { hideLoading, showLoading } from 'src/redux/slices/uiSlice';
import { apiStrings, httpClient } from 'src/services';

import { ErrorCriteria, SendUrlNotification } from 'src/types';

const useSendUrl = () => {
  const { toastError } = useToast();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const {
    mutate: sendUrl,
    isSuccess,
    isLoading: isLoadingSendUrl,
  } = useMutation<any, AxiosError<ErrorCriteria>, SendUrlNotification>({
    mutationFn: async (variables: SendUrlNotification) => {
      dispatch(showLoading());

      try {
        const response = await httpClient.put(
          apiStrings.paymentIntegration.sendUrl,
          variables
        );

        return response.data.data;
      } catch (error) {
        throw error;
      }
    },

    onSuccess: async () => dispatch(hideLoading()),

    onError: async err => {
      dispatch(hideLoading());
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return {
    isLoadingSendUrl,
    sendUrl,
    isSuccess,
  };
};

export default useSendUrl;
