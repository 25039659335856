import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Phone } from 'src/assets/images/icons/phone.svg';
import { ReactComponent as Edit } from 'src/assets/images/icons/edit.svg';
import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { CustomModal } from 'src/components/Modal';
import { COLOR_GRAY_600, COLOR_GRAY_900 } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { validatePhoneNumber } from 'src/utils/validators/common';

import { StaffProps } from 'src/components/CreateOrder/type';
import { Seller } from 'src/types';

const PhoneIcon = withIcon(Phone, {
  width: 20,
  height: 20,
  color: COLOR_GRAY_600,
});

const EditIcon = withIcon(Edit, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const Staff = ({ phone, isDetail = false, setFieldValue }: StaffProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [initValues, setInitValue] = useState({ phoneNumber: phone || '' });
  const formRef = useRef<FormikProps<Seller>>(null);

  const onSubmitStaff = (values: { phoneNumber?: string }) => {
    setInitValue(state => ({
      ...state,
      phoneNumber: values.phoneNumber || '',
    }));
    setFieldValue?.('staff', values.phoneNumber);
    setOpen(false);
  };

  useEffect(() => {
    setInitValue(state => ({
      ...state,
      phoneNumber: phone || '',
    }));
  }, [phone]);

  return (
    <>
      <div
        className={classNames({
          'd-flex flex-row justify-between w--100 mt--24': true,
          'items-start': !!initValues.phoneNumber,
          'items-end': !initValues.phoneNumber,
        })}
      >
        <div className="d-flex justify-start items-start flex-col">
          <p className="label label--xs uppercase text-gray-700 mb--24">
            {t('orders:createOrderPage.informationSalesStaff')}
          </p>
          {!!initValues.phoneNumber ? (
            <div className="d-flex items-center justify-start">
              <PhoneIcon className="mr--8" />
              <p className="paragraph paragraph--sm text-gray-800">
                {initValues.phoneNumber}
              </p>
            </div>
          ) : (
            <>
              <p className="paragraph paragraph--sm text-gray-700">
                {t('orders:createOrderPage.noInformation')}
              </p>
            </>
          )}
        </div>
        {isDetail ? (
          <></>
        ) : !initValues.phoneNumber ? (
          <Button
            type={ButtonType.Text}
            colorType={ButtonColorType.Blue}
            title={t('orders:createOrderPage.addInformation')}
            onClickHandled={() => setOpen(true)}
            rootClassName="-mb--4"
          ></Button>
        ) : (
          <Button
            type={ButtonType.Text}
            colorType={ButtonColorType.Gray}
            icon={<EditIcon />}
            onClickHandled={() => setOpen(true)}
            rootClassName="-mt--12 quantity-button"
          ></Button>
        )}
      </div>
      <CustomModal
        open={open}
        title={t('orders:createOrderPage.informationSalesStaff2')}
        footer={
          <div className="d-flex justify-end">
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.add')}
              size="large"
              onClickHandled={() => {
                formRef.current?.handleSubmit();
              }}
              className="label--md"
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={() => setOpen(false)}
        destroyOnClose
      >
        <Formik
          initialValues={initValues}
          onSubmit={onSubmitStaff}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue: setFieldValueStaff }) => (
            <Form className="-mb--28">
              <Input
                type={'text'}
                label={t('orders:createOrderPage.phone')}
                name="phoneNumber"
                placeholder={t('orders:createOrderPage.phonePlaceHolder')}
                value={values.phoneNumber}
                validate={(value: string) =>
                  validatePhoneNumber(t, 'phoneNumber', value)
                }
                required
                onChange={e =>
                  setFieldValueStaff('phoneNumber', e.target.value)
                }
              />
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Staff;
