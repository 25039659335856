import { Form } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GlobeIcon } from 'src/assets/images/icons/globe.svg';
import Select from 'src/components/Select';
import { Input } from 'src/components/Input';
import { useStoresQuery } from 'src/hooks';
import { useGetLocation } from 'src/hooks/common';
import { validateRequired } from 'src/utils/validators/common';

import { StoreFormProps } from 'src/components/CreateOrder/type';
import { LocationType, StoreChannel } from 'src/types';
import classNames from 'classnames';
import withIcon from 'src/hocs/withIcon';

const HttpIcon = withIcon(GlobeIcon, {
  width: 16,
  height: 16,
});

const StoreForm = ({ values, setFieldValue }: StoreFormProps) => {
  const { t } = useTranslation();

  const { locations: cities, refetch: refetchProvince } = useGetLocation(
    LocationType.Province
  );
  const { locations: districts } = useGetLocation(
    LocationType.District,
    values.address.province.id
  );
  const { locations: wards } = useGetLocation(
    LocationType.Ward,
    values.address.district.id
  );

  useEffect(() => {
    if (cities.length > 0) {
      return;
    }

    refetchProvince();
  }, []);

  const website = useMemo(() => {
    if (values.website) {
      return values.website.split('https://');
    }
    return [];
  }, [values]);

  const { stores } = useStoresQuery({
    showId: true,
  });

  const handleChangeStore = async (value: any) => {
    await setFieldValue('id', value);
    const store = stores.find(e => e.value === value);

    if (!store) {
      return;
    }

    await setFieldValue('name', store?.name || '');
    await setFieldValue('channel', store?.channel || StoreChannel.Online);
    await setFieldValue('website', store?.website || '');
    await setFieldValue('address.street', store?.address || '');
    await setFieldValue('address.province.id', store?.provinceId || '');
    await setFieldValue('address.province.name', store?.provinceName || '');
    await setFieldValue('address.district.id', store?.districtId || '');
    await setFieldValue('address.district.name', store?.districtName || '');
    await setFieldValue('address.ward.id', store?.wardId || '');
    await setFieldValue('address.ward.name', store?.wardName || '');
    await setFieldValue('status', store?.status);
  };

  return (
    <Form
      className={classNames({
        '-mb--16': website.length > 0,
      })}
    >
      <Select
        name="id"
        label={t('orders:createOrderPage.store')}
        placeholder={t('orders:createOrderPage.storePlaceHolder')}
        value={values.id}
        options={stores}
        onValueChanged={handleChangeStore}
        required
        validate={(value: string) => {
          return validateRequired(t, 'id', value);
        }}
      />
      {website.length > 0 && values?.channel === StoreChannel.Online ? (
        <>
          <p className="label label--md mb--12">
            {t('orders:createOrderPage.storesWebsite')}
          </p>
          <div className="d-flex items-center justify-center w--100 mb--12">
            <Input
              name="website"
              placeholder={t('orders:createOrderPage.storesWebsite')}
              value={website[1] ?? website[0]}
              rootClassName="w--100 mr--8"
              disabled={true}
              type={''}
              addonBefore={<HttpIcon />}
            />
          </div>
        </>
      ) : (
        <>
          <p className="label label--md mb--12">
            {t('orders:createOrderPage.storesAddress')}
          </p>
          <div className="d-flex items-center justify-center w--100 mb--12">
            <Select
              name="address.province.id"
              placeholder={t('common:address.city')}
              value={values.address.province.id}
              options={cities}
              onValueChanged={() => {}}
              rootClassName="w--100 mr--8"
              disabled={true}
            />
            <Select
              name="address.district.id"
              placeholder={t('common:address.district')}
              value={values.address.district.id}
              options={districts}
              onValueChanged={() => {}}
              rootClassName="w--100 mr--8"
              disabled={true}
            />
            <Select
              name="address.ward.id"
              placeholder={t('common:address.ward')}
              value={values.address.ward.id}
              options={wards}
              onValueChanged={() => {}}
              rootClassName="w--100"
              disabled={true}
            />
          </div>
          <Input
            type="text"
            name="address.street"
            placeholder={t('orders:createOrderPage.storesAddress')}
            value={values.address.street || ''}
            disabled={true}
          />
        </>
      )}
    </Form>
  );
};

export default StoreForm;
