import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import i18next from 'i18next';

import {
  ACCESS_TOKEN_KEY,
  API_FETCH_TIMEOUT,
  EXPIRED_DAY_KEY,
  REQUIRES_DATA_SHARING_KEY,
  TEMPORARY_PASSWORD_KEY,
  TOKEN_INFO_KEY,
  TOKEN_TYPE_KEY,
} from 'src/constants';
import { getCookie, removeCookie } from 'src/services/cookies';

export const httpClientPublic = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: API_FETCH_TIMEOUT * 1000,
});

httpClientPublic.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config && config.headers) {
      if (config.params?.isUploadFile) {
        config.headers['Content-Type'] = null;
        config.params = null;
      } else {
        config.headers['Content-Type'] = 'application/json';
      }

      config.headers.lang = i18next.language;
    }
    return config;
  }
);

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: API_FETCH_TIMEOUT * 1000,
});

httpClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config && config.headers) {
      config.headers['Content-Type'] = 'application/json';
      config.headers.lang = i18next.language;

      const accessToken = getCookie(ACCESS_TOKEN_KEY);
      const tokenType = getCookie(TOKEN_TYPE_KEY);

      if (tokenType && accessToken) {
        config.headers.Authorization = `${tokenType} ${accessToken}`;
      }
    }
    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      removeCookie(ACCESS_TOKEN_KEY);
      removeCookie(TOKEN_TYPE_KEY);
      removeCookie(EXPIRED_DAY_KEY);
      removeCookie(TEMPORARY_PASSWORD_KEY);
      removeCookie(REQUIRES_DATA_SHARING_KEY);
      removeCookie(TOKEN_INFO_KEY);
      window.location.href = '/sign-in';
      // if (!isRefreshing) {
      // isRefreshing = true;
      // getRefreshToken()
      //   .then(newAccessToken => {
      //     httpClient.defaults.headers.Authorization = `Bearer ${newAccessToken}`;

      //     refreshSubscribers.forEach(callback => callback(newAccessToken));
      //     refreshSubscribers = [];

      //     isRefreshing = false;
      //   })
      //   .catch(() => {
      //     window.location.href = '/sign-in';
      //   });
      // }

      // return new Promise(resolve => {
      //   refreshSubscribers.push((newAccessToken: string) => {
      //     if (error.config) {
      //       error.config.headers.Authorization = `Bearer ${newAccessToken}`;
      //       resolve(axios.request(error.config));
      //     } else {
      //       resolve(Promise.reject(error));
      //     }
      //   });
      // });
    }

    return Promise.reject(error);
  }
);
