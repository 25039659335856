import { useNavigate, useParams } from 'react-router-dom';
import { useOrderDetail } from 'src/hooks';
import {
  HeaderPayment,
  Payment as PaymentDetail,
} from 'src/components/OrderPayment';
import { routeStrings } from 'src/routes';
const OrderDetail = () => {
  const { id } = useParams();
  const { order } = useOrderDetail(encodeURIComponent(id || ''), true);
  const navigate = useNavigate();

  const resetOrder = () => {
    navigate(routeStrings.createOrder);
  };

  if (!order) {
    return <></>;
  }

  return (
    <div className="body">
      <HeaderPayment
        isPayment
        id={order?.referenceId}
        backToCreateOrder={resetOrder}
        status={order?.resultStatus}
        merchantBillId={order?.merchantBillId}
      />
      <div className="body__content">
        <PaymentDetail orderResponse={order} backToCreateOrder={resetOrder} />
      </div>
    </div>
  );
};

export default OrderDetail;
