import { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { roles } from 'src/constants';
import useAuth from 'src/hooks/auth/useAuth';
import { logIn, setTokenInfo } from 'src/redux/slices/authSlice';
import { ReduxState } from 'src/redux/store';
import routeStrings from 'src/routes/routeStrings';

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const {
    isAuthenticated,
    temporaryPassword,
    requiresDataSharing,
    tokenInfo: reduxTokenInfo,
  } = useSelector((state: ReduxState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getCookieToken, getTokenInfo } = useAuth();
  const cookiesAuth = getCookieToken();
  const tokenInfo = getTokenInfo();

  useEffect(() => {
    if (isAuthenticated) {
      if (!!reduxTokenInfo || !tokenInfo) {
        return;
      }

      dispatch(setTokenInfo(tokenInfo));
      return;
    }

    if (cookiesAuth) {
      dispatch(logIn(cookiesAuth));
      if (cookiesAuth.temporaryPassword || temporaryPassword) {
        navigate(routeStrings.resetPassword, {
          replace: true,
        });
      } else if (
        (cookiesAuth.requiresDataSharing || requiresDataSharing) &&
        tokenInfo?.permissions.includes(roles.writeContracts)
      ) {
        navigate(routeStrings.tc, {
          replace: true,
        });
      }
      return;
    }

    navigate(routeStrings.signIn, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tokenInfo]);

  return children;
};

export default ProtectedRoute;
