import { Key } from 'react';
import { Shipping, Seller, CustomerForm, Amount, Store } from './createOrder';
import { Dayjs } from 'dayjs';

export enum OrderStatus {
  Waiting = 'WAITING',
  Rejected = 'RISK_REJECT',
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
}

export enum StoreChannel {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
}

export interface OrdersType {
  fundiinOrderId?: string;
  referenceId: string;
  totalAmount: number;
  status: string;
  paymentMethod?: string;
  channel: StoreChannel;
  createdDate: Date;
  customerInfo: {
    phoneNumber: string;
  };
  storeInfo: StoreInformation;
}

export interface StoreInformation {
  storeId: number;
  storeName: string;
}

export type OrdersColumnType = Omit<OrdersType, 'startDate' | 'endDate'> & {
  key: Key;
  merchantBillId?: string;
  startDate: string;
  endDate: string;
};

export interface FilterOrder {
  startDate?: Dayjs | string | null;
  endDate?: Dayjs | string | null;
  statuses?: string[];
  storeId?: string;
}

export interface SmsForm {
  id?: null;
}
export interface PaymentConfirmForm {
  referenceId: string;
  amount: number;
}

export enum ProofType {
  Invoice = 'INVOICE',
  Others = 'OTHERS',
  Url = 'ORDER_TRACKING_URL',
}

export interface OrderDetail {
  referenceId: string;
  merchantBillId: string;
  fundiinOrderId: string;
  createdDate: Dayjs;
  updatedDate: Dayjs;
  expiredDate: Dayjs;
  paymentDate: Dayjs;
  sessionPayment: SessionPayment;
  status: string;
  amount: Amount;
  customerInfo: CustomerForm;
  storeInfo: Store;
  shippingInfo: Shipping;
  sellerInfo: Seller;
  items: Item[];
  proofs: TransactionProofs[];
  refundInfo?: RefundInfo;
  reason?: string;
  inStorePayment?: boolean;
}

export interface RefundInfo {
  paymentDate: Dayjs;
  refundDate: Dayjs;
}

export interface TransactionProofs {
  fileId: string;
  fileName: string;
  mediaType: string;
  type: ProofType;
  createdDate: Dayjs;
  createBy: string;
  url?: string;
}

export interface SessionPayment {
  paymentUrl: string;
  qrData: string;
  expiredDate: string;
}

export interface Item {
  productId: null;
  productName: string;
  description: null;
  category: null;
  currency: null;
  quantity: number;
  price: number;
  totalAmount: number;
  totalPricePromotion: null;
  totalPriceOther: null;
  imageUrl: null;
}

export interface UploadFileForm {
  fileName: string;
  orderId: string;
}

export interface UploadFileResponse {
  url: string;
  contentType: string;
}

export interface SubmitFileForm {
  type?: string;
  orderId?: string;
  fileName?: string;
  mediaType?: string;
  url?: string;
}
