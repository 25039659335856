// FORMAT DATE AND TIME
export const DATE_FORMAT = 'DD-MMM-YYYY';
export const DATE_FORMAT_SUBMITTING = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'MMMM - YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_SUBMITTING = 'HH:mm:ss';
export const DATE_HOUR_MIN_FORMAT = 'DD-MM-YYYY HH:mm';
export const CUSTOM_DATE_TIME_FORMAT = 'DD MMM YYYY - HH:mm';
export const HOUR_MINUTE = 'HH:mm';
export const DATE_FORMAT_UK = 'DD-MM-YYYY';
export const DATE_HOUR_MIN_FORMAT_WITH_DASH = 'DD-MM-YYYY - HH:mm';

export enum DAY {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}
