import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import enCommon from 'src/i18n/en/common.json';
import enSignIn from 'src/i18n/en/signIn.json';
import enChangePassword from 'src/i18n/en/changePassword.json';
import enOrders from 'src/i18n/en/orders.json';
import enTc from 'src/i18n/en/tc.json';
import enPaymentIntegration from 'src/i18n/en/paymentIntegration.json';

import viCommon from 'src/i18n/vi/common.json';
import viSignIn from 'src/i18n/vi/signIn.json';
import viChangePassword from 'src/i18n/vi/changePassword.json';
import viOrders from 'src/i18n/vi/orders.json';
import viTc from 'src/i18n/vi/tc.json';
import viPaymentIntegration from 'src/i18n/vi/paymentIntegration.json';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'vi',
    fallbackLng: 'vi',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    resources: {
      en: {
        common: enCommon,
        signIn: enSignIn,
        changePassword: enChangePassword,
        orders: enOrders,
        tc: enTc,
        paymentIntegration: enPaymentIntegration,
      },
      vi: {
        common: viCommon,
        signIn: viSignIn,
        changePassword: viChangePassword,
        orders: viOrders,
        tc: viTc,
        paymentIntegration: viPaymentIntegration,
      },
    },
    ns: ['common', 'signIn'],
    defaultNS: 'common',
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br'],
    },
  });
