import { Divider } from 'antd';
// import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Edit } from 'src/assets/images/icons/edit.svg';
import { ReactComponent as Location } from 'src/assets/images/icons/location.svg';
import { ReactComponent as StoreI } from 'src/assets/images/icons/store.svg';

import Button, { ButtonColorType, ButtonType } from 'src/components/Button';
import Staff from 'src/components/CreateOrder/Staff';
import StoreForm from 'src/components/CreateOrder/Store/StoreForm';
import { CustomModal } from 'src/components/Modal';
import { COLOR_GRAY_900, roles } from 'src/constants';
import withIcon from 'src/hocs/withIcon';
import { ReduxState } from 'src/redux/store';

import { StoreProps } from 'src/components/CreateOrder/type';
import { StoreChannel, Store as StoreType } from 'src/types';

const EditIcon = withIcon(Edit, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const LocationIcon = withIcon(Location, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const StoreIcon = withIcon(StoreI, {
  width: 24,
  height: 24,
  color: COLOR_GRAY_900,
});

const Store = ({
  staff,
  store,
  isDetail = false,
  setFieldValue,
}: StoreProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [initValues, setInitValue] = useState<StoreType>(store);
  const formRef = useRef<FormikProps<StoreType>>(null);
  const { tokenInfo } = useSelector((state: ReduxState) => state.auth);

  const onSubmitStore = (values: StoreType) => {
    setFieldValue?.('store', values);
    setInitValue(values);
    setOpen(false);
  };

  useEffect(() => {
    if (!store) {
      return;
    }

    setInitValue(store);
  }, [store]);

  return (
    <>
      <div className="d-flex flex-col justify-start background-gray-100 mt--24 br--8">
        <div className="d-flex flex-row justify-between py--16 px--24">
          <p className="label label--lg">
            {t('orders:createOrderPage.informationStore')}
          </p>
        </div>
        <hr />
        <div className="d-flex flex-col items-center py--16 px--24">
          {!initValues?.id ? (
            <>
              <div className="d-flex justify-start items-start flex-col w--100">
                <p className="label label--xs uppercase text-gray-700 mb--24">
                  {t('orders:createOrderPage.informationStore')}
                </p>
              </div>
              <div className="d-flex items-start flex-row justify-between w--100 mb--16">
                <p className="paragraph paragraph--sm text-gray-700">
                  {t('orders:createOrderPage.noInformation')}
                </p>
                {!isDetail && (
                  <Button
                    type={ButtonType.Text}
                    colorType={ButtonColorType.Blue}
                    title={t('orders:createOrderPage.addInformation')}
                    onClickHandled={() => setOpen(true)}
                    rootClassName="-mt--4"
                  ></Button>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="d-flex items-start flex-row justify-between w--100">
                <p className="label label--xs uppercase text-gray-700 mb--8">
                  {t('orders:createOrderPage.informationStore')}
                </p>
                {!isDetail && (
                  <Button
                    type={ButtonType.Text}
                    colorType={ButtonColorType.Gray}
                    icon={<EditIcon />}
                    onClickHandled={() => setOpen(true)}
                    rootClassName="-mt--8 quantity-button"
                  ></Button>
                )}
              </div>
              <div className="w--100 mb--12">
                <div className="d-flex flex-col justify-start mb--8">
                  <p className="label label--sm text-gray-900 mb--8">
                    {initValues?.name}
                  </p>
                  <p className="paragraph paragraph--sm text-gray-900 mb--8">
                    {initValues?.id}
                  </p>
                  {isDetail && (
                    <div className="d-flex items-center justify-start mb--8">
                      <StoreIcon className="mr--8" />
                      <p className="paragraph paragraph--sm text-gray-800 text-ellipsis w-300">
                        {initValues?.name}
                      </p>
                    </div>
                  )}
                  <div className="d-flex items-center justify-start">
                    <div>
                      <LocationIcon className="mr--8" />
                    </div>
                    {initValues?.website &&
                    initValues?.channel === StoreChannel.Online ? (
                      <p className="paragraph paragraph--sm text-gray-800 text-ellipsis w-300">
                        {initValues?.website}
                      </p>
                    ) : (
                      <p className="paragraph paragraph--sm text-gray-800 text-ellipsis">
                        {`${initValues?.address.street ?? ''}, ${initValues?.address.ward.name ?? ''}, ${initValues?.address.district.name ?? ''}, ${initValues?.address.province.name ?? ''}`}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <Divider />
          {tokenInfo?.permissions.includes(roles.writeCommission) && (
            <Staff
              phone={staff}
              isDetail={isDetail}
              setFieldValue={setFieldValue}
            />
          )}
        </div>
      </div>
      <CustomModal
        open={open}
        title={t('orders:createOrderPage.informationStore')}
        footer={
          <div className="d-flex justify-end">
            <Button
              type={ButtonType.Primary}
              colorType={ButtonColorType.Blue}
              title={t('orders:createOrderPage.add')}
              size="large"
              className="label--md"
              onClickHandled={() => {
                formRef.current?.handleSubmit();
              }}
            />
          </div>
        }
        width={600}
        className="custom-modal"
        closeIcon
        onCancel={() => setOpen(false)}
        destroyOnClose
      >
        <Formik
          initialValues={initValues}
          onSubmit={onSubmitStore}
          enableReinitialize
          innerRef={formRef}
        >
          {({ values, setFieldValue: setFieldValueStore }) => (
            <StoreForm values={values} setFieldValue={setFieldValueStore} />
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default Store;
