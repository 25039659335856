import { useMutation } from '@tanstack/react-query';
import { apiStrings, httpClient } from 'src/services';
import { useToast } from '../ui';
import { AxiosError } from 'axios';
import { ErrorCriteria, UploadFileForm } from 'src/types';
import { useTranslation } from 'react-i18next';

const useUploadFile = () => {
  const { toastError } = useToast();
  const { t } = useTranslation();

  const { mutate: uploadFile, isLoading } = useMutation<
    any,
    AxiosError<ErrorCriteria>,
    UploadFileForm
  >({
    mutationFn: async variables => {
      try {
        const response = await httpClient.post(apiStrings.file.uploadFile, {
          ...variables,
          prefix: `${variables.orderId}/`,
        });
        return response.data.data;
      } catch (error) {
        throw error;
      }
    },
    // onSuccess: async response => {
    //   callback(response);
    // },
    onError: async err => {
      toastError({
        message: err.response?.data?.message || t('error.systemError'),
      });
    },
  });

  return { uploadFile, isFileUploading: isLoading };
};

export default useUploadFile;
