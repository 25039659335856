import { Breadcrumb as AntdBreadcrumb } from 'antd';

import { IBreadcrumbProps } from 'src/components/Breadcrumb/Breadcrumb.d';

const Breadcrumb = ({ items }: IBreadcrumbProps) => {
  if (typeof items === 'string')
    return <h1 className="heading heading--lg">{items}</h1>;

  return <AntdBreadcrumb className="cursor--pointer" items={items} />;
};

export default Breadcrumb;
